import React from 'react';
import './index.less';
import classNames from 'classnames';
import { IAppStartMenu } from '@interfaces/i-app-window';
import { IAppStartMenuRes } from '@interfaces/i-api-response';
import { observer } from 'mobx-react-lite';
import DaqImage from '@components/daq-image';

interface IAppGroupProps {
  onClick?(e: React.MouseEvent, app: IAppStartMenuRes): void;

  group: IAppStartMenu;
  className?: string;
  style?: React.CSSProperties;
}

const AppGroup: React.FC<IAppGroupProps> = ({ group, className = '', ...props }) => {
  const maxCounts = 9;

  return (
    <div
      className={classNames('app-group', className)}
      title={group.appName}
      style={{
        ...props.style,
      }}
      onClick={(e) => {
        props.onClick && props.onClick(e, group);
      }}
    >
      <div className="group-preview">
        {/* 预览组里面的app logo */}
        <ul className="group-preview-list">
          {group.openAppList
            .filter((app, index) => {
              return index < maxCounts;
            })
            .map((app) => (
              <li
                key={app.appUnionId}
                className="app-preview"
              >
                <DaqImage
                  alt={app.appName}
                  src={app.appLogo}
                ></DaqImage>
              </li>
            ))}
        </ul>
      </div>
      <h3 className="app-group-name">{group.appName}</h3>
    </div>
  );
};

export default observer(AppGroup);
