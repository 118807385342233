/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-26 09:12:42
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-26 09:49:57
 * @Description: 描述
 */
import { Button, Form, Input } from 'antd';
import { ISecurityForm, ISecurityProps } from '@interfaces/i-user-center';
import regular from '@config/regular';

type SetEmailProps = 'email' | 'onCancel' | 'onOk' | 'submitting';
// 邮箱
const Email = ({
  email,
  onCancel,
  onOk,
  submitting,
}: Required<Pick<ISecurityProps, SetEmailProps>>) => {
  const [form] = Form.useForm();
  const newEmail = Form.useWatch('email', form);

  const handleFinish = (data: ISecurityForm) => {
    onOk({ email: data.email });
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{ email: email }}
        colon={false}
        requiredMark={false}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          label="设置邮箱"
          name="email"
          validateFirst
          rules={[
            {
              required: true,
              message: '请输入邮箱地址',
            },
            {
              pattern: regular.email.pattern,
              message: regular.email.message,
            },
          ]}
        >
          <Input
            placeholder="请输入邮箱"
            autoComplete="off"
            allowClear
            maxLength={256}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 7,
            span: 17,
          }}
        >
          <Button
            type="primary"
            disabled={!newEmail || newEmail === email}
            loading={submitting}
            htmlType="submit"
          >
            保存
          </Button>
          <Button onClick={() => onCancel('email')}>取消</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Email;
