import React from 'react';
import style from './security.module.less';
import '@styles/dialog.less';
import { IDataItem } from '@interfaces/i-common';
import { ISecurityState, ISecurityForm } from '@interfaces/i-user-center';
import DaqIcon from '@components/daq-icon';
import classNames from 'classnames';
import { Modal, message } from 'antd';
import Avatar from './avatar';
import Password from '@components/password';
import Phone from './phone';
import Email from './email';
import { useImmer } from 'use-immer';
import { useStore } from '@stores';
import api from '@api';
import { IBaseUserInfo } from '@interfaces/i-user-info';
import OperateMessage from '@components/operate-message';
import { useDebounceFn } from 'ahooks';
import { observer } from 'mobx-react-lite';
import DaqSoftTrack from '@utils/daqsoft-track';

const types = ['avatar', 'password', 'phone', 'email'];

// 安全设置
const Security: React.FC = (): JSX.Element => {
  const { userStore } = useStore();
  const [resultInfo, setResultInfo] = useImmer({
    message: '',
    type: 'info',
  });
  const [state, setState] = useImmer<ISecurityState>({
    submitting: false,
    avatar: false,
    password: false,
    phone: false,
    email: false,
  });
  const settings: IDataItem[] = [
    {
      id: 'avatar',
      icon: '&#xe679;',
      label: '设置头像',
    },
    {
      id: 'password',
      icon: '&#xeb97;',
      label: '修改密码',
    },
    // {
    //   id: 'phone',
    //   icon: '&#xe613;',
    //   label: '解绑手机号',
    // },
    {
      id: 'email',
      icon: '&#xeb98;',
      label: '绑定邮箱',
    },
  ];

  // 打开弹出框
  const handleOpen = (id: string) => {
    // 打开弹窗时清除错误信息
    setResultInfo((state) => {
      state.message = '';
      state.type = 'info';
    });
    setState((state) => {
      if (types.includes(id)) {
        state[id] = true;
      }
    });
  };

  // 取消
  const handleCancel = (id: string) => {
    setState((state) => {
      state[id] = false;
    });
    setResultInfo((state) => {
      state.message = '';
      state.type = 'info';
    });
  };

  // 处理子组件内部错误提示
  const handleMessage = (message: string, type = 'error') => {
    setResultInfo((state) => {
      state.message = message;
      state.type = type;
    });
  };
  const successTrack = (secondEventType: string) => {
    DaqSoftTrack.track({
      firstEventType: '用户中心-安全',
      secondEventType,
      status: 1,
    });
  };
  const errorTrack = (secondEventType: string, failReason: string) => {
    DaqSoftTrack.track({
      firstEventType: '用户中心-安全',
      secondEventType,
      failReason,
      status: 0,
    });
  };
  const items = settings.map((setting) => {
    return (
      <li
        aria-hidden={true}
        key={setting.id}
        className={classNames(style.securityItem, setting.id)}
        onClick={() => handleOpen(setting.id)}
      >
        <div className="icon">
          <DaqIcon icon={setting.icon} />
        </div>
        <span className={style.label}>{setting.label}</span>
        {/*<Modal title={setting.label}*/}
        {/*       open={ show(setting.id) }*/}
        {/*       onCancel={handleCancel}>*/}
        {/*  <SetAvatar></SetAvatar>*/}
        {/*</Modal>*/}
      </li>
    );
  });

  // 处理防抖，输入关键字筛选app
  const { run: setAvatar } = useDebounceFn(
    ({ avatar }: ISecurityForm) => {
      if (avatar && !state.submitting) {
        setState((state) => {
          state.submitting = true;
        });
        api.userCenter
          .setAvatar(avatar)
          .then(() => {
            setState((state) => {
              state.avatar = false;
            });
            userStore.setUser({ avatar } as IBaseUserInfo);
            message.success('修改头像成功！');
            successTrack('设置头像');
          })
          .catch((error) => {
            setResultInfo((state) => {
              state.message = error.message;
              state.type = 'error';
            });
            errorTrack('设置头像', String(error));
          })
          .finally(() => {
            setState((state) => {
              state.submitting = false;
            });
          });
      }
    },
    { wait: 300 },
  );
  const { run: setPassword } = useDebounceFn(
    ({ password, oldPassword }: ISecurityForm) => {
      if (password && oldPassword && !state.submitting) {
        setState((state) => {
          state.submitting = true;
        });
        api.userCenter
          .setPassword({
            password,
            oldPassword,
          })
          .then(() => {
            setState((state) => {
              state.password = false;
            });
            message.success('修改密码成功，须重新登录！');
            successTrack('修改密码');
            DaqSoftTrack.track({
              firstEventType: '退出登录',
              secondEventType: '修改密码退出',
              status: 1,
            });
            userStore.logout();
          })
          .catch((error) => {
            setResultInfo((state) => {
              state.message = error.message;
              state.type = 'error';
            });
            errorTrack('修改密码', String(error));
          })
          .finally(() => {
            setState((state) => {
              state.submitting = false;
            });
          });
      }
    },
    { wait: 300 },
  );
  const { run: setPhone } = useDebounceFn(
    ({ phone, code, password }: ISecurityForm) => {
      if (phone && code && !state.submitting) {
        setState((state) => {
          state.submitting = true;
        });
        api.userCenter
          .setPhone({
            phone,
            code,
            password: password || '',
          })
          .then(() => {
            setState((state) => {
              state.phone = false;
            });
            userStore.setUser({ phone } as IBaseUserInfo);
            message.success('修改手机号成功！');
            successTrack('修改手机号');
            userStore.logout();
          })
          .catch((error) => {
            setResultInfo((state) => {
              state.message = error.message;
              state.type = 'error';
            });
            errorTrack('修改手机号', String(error));
          })
          .finally(() => {
            setState((state) => {
              state.submitting = false;
            });
          });
      }
    },
    { wait: 300 },
  );
  const { run: setEmail } = useDebounceFn(
    ({ email }: ISecurityForm) => {
      if (email && !state.submitting) {
        setState((state) => {
          state.submitting = true;
        });
        api.userCenter
          .setEmail(email)
          .then(() => {
            setState((state) => {
              state.email = false;
            });
            userStore.setUser({ email } as IBaseUserInfo);
            message.success('修改邮箱成功！');
            successTrack('修改邮箱');
          })
          .catch((error) => {
            setResultInfo((state) => {
              state.message = error.message;
              state.type = 'error';
            });
            errorTrack('修改邮箱', String(error));
          })
          .finally(() => {
            setState((state) => {
              state.submitting = false;
            });
          });
      }
    },
    { wait: 300 },
  );

  // TODO 优化重构
  return (
    <>
      <ul className={style.security}>{items}</ul>
      <Modal
        title="设置头像"
        destroyOnClose
        maskClosable={false}
        closable={false}
        className="os-dialog mini"
        open={state.avatar}
        footer={null}
        onCancel={() => handleCancel('avatar')}
      >
        {resultInfo.message ? (
          <OperateMessage
            message={resultInfo.message}
            type={resultInfo.type}
          />
        ) : null}
        <Avatar
          avatar={userStore.avatar}
          submitting={state.submitting}
          onCancel={handleCancel}
          onOk={setAvatar}
        ></Avatar>
      </Modal>
      <Modal
        title="修改密码"
        destroyOnClose
        closable={false}
        maskClosable={false}
        className="os-dialog data-form"
        open={state.password}
        footer={null}
        onCancel={() => handleCancel('password')}
      >
        {resultInfo.message ? (
          <OperateMessage
            message={resultInfo.message}
            type={resultInfo.type}
          />
        ) : null}
        <Password
          submitting={state.submitting}
          onCancel={handleCancel}
          onOk={setPassword}
        ></Password>
      </Modal>
      <Modal
        title="绑定手机号"
        destroyOnClose
        maskClosable={false}
        closable={false}
        className="os-dialog data-form"
        footer={null}
        open={state.phone}
        onCancel={() => handleCancel('phone')}
      >
        {resultInfo.message ? (
          <OperateMessage
            message={resultInfo.message}
            type={resultInfo.type}
          />
        ) : null}
        <Phone
          submitting={state.submitting}
          userInfo={userStore}
          onMessage={handleMessage}
          onCancel={handleCancel}
          onOk={setPhone}
        ></Phone>
      </Modal>
      <Modal
        title="绑定邮箱"
        destroyOnClose
        maskClosable={false}
        closable={false}
        className="os-dialog data-form"
        footer={null}
        open={state.email}
        onCancel={() => handleCancel('email')}
      >
        {resultInfo.message ? (
          <OperateMessage
            message={resultInfo.message}
            type={resultInfo.type}
          />
        ) : null}
        <Email
          submitting={state.submitting}
          email={userStore.email}
          onCancel={handleCancel}
          onOk={setEmail}
        ></Email>
      </Modal>
    </>
  );
};

export default observer(Security);
