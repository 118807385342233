import style from './index.module.less';
import Overview from '@views/weather/components/overview';
import Forecast24h from '@views/weather/components/forecast-24h';
import Weather7days from '@views/weather/components/weather-7days';
import AirQuality from '@views/weather/components/air-quality';
import Rainfall2h from '@views/weather/components/rainfall-2h';
import WeatherWarning from '@views/weather/components/weather-warning';
import React from 'react';
import { IWeatherDetailInfo } from '@interfaces/i-weather';
import { Skeleton } from 'antd';

interface IProps {
  loading: boolean;
  weatherDetail: IWeatherDetailInfo;
  openLocationConfig: () => void;
}

const WeatherPanel: React.FC<IProps> = ({ loading, openLocationConfig, weatherDetail }) => {
  return (
    <div className={style.weatherPanel}>
      <Skeleton
        active
        round
        paragraph={{
          rows: 6,
        }}
        loading={loading}
      >
        <Overview
          openLocationConfig={openLocationConfig}
          {...weatherDetail.overview}
        ></Overview>
      </Skeleton>
      <Skeleton
        active
        round
        paragraph={{
          rows: 6,
        }}
        loading={loading}
      >
        <Forecast24h infos={weatherDetail.forecast24h}></Forecast24h>
      </Skeleton>
      <Skeleton
        active
        round
        paragraph={{
          rows: 6,
        }}
        loading={loading}
      >
        <Weather7days infos={weatherDetail.weather7days}></Weather7days>
      </Skeleton>
      <Skeleton
        active
        round
        paragraph={{
          rows: 6,
        }}
        loading={loading}
      >
        <AirQuality air={weatherDetail.airQuality}></AirQuality>
      </Skeleton>
      <Skeleton
        active
        round
        paragraph={{
          rows: 6,
        }}
        loading={loading}
      >
        <Rainfall2h {...weatherDetail.rainfall2h}></Rainfall2h>
      </Skeleton>
      <Skeleton
        active
        round
        paragraph={{
          rows: 6,
        }}
        loading={loading}
      >
        {weatherDetail.warnings.length ? (
          weatherDetail.warnings.map((warning) => {
            return (
              <WeatherWarning
                key={warning.warnId}
                {...warning}
              ></WeatherWarning>
            );
          })
        ) : (
          <WeatherWarning noData={true}></WeatherWarning>
        )}
      </Skeleton>
    </div>
  );
};

export default WeatherPanel;
