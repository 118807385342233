/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-19 09:48:07
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-20 19:08:17
 * @Description: 描述
 */
import style from './info-group.module.less';
import icon from '@assets/images/update-pwd.png';
import phoneIcon from '@assets/images/bind-phone.png';
import classNames from 'classnames';

const InfoGroup = ({ className, phone = false }: { className: string; phone?: boolean }) => {
  return (
    <div className={classNames(style.tipInfo, className)}>
      <div className={style.icon}>
        <img
          alt="更新密码"
          src={phone ? phoneIcon : icon}
        />
      </div>
      <div className={style.text}>
        <p>
          {phone
            ? '您的平台账号未绑定新手机号，为便于您账号管理，请绑定手机号！'
            : '为确保您的账号信息安全，账号首次登录，请设置新密码！'}
        </p>
        <p className={style.subText}>
          {phone ? '若有疑问请联系客服400-028-0225' : '密码格式：8到20位字母和数字，不含特殊符号'}
        </p>
      </div>
    </div>
  );
};

export default InfoGroup;
