import style from './index.module.less';
import classNames from 'classnames';
import { InfoCircleOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

const Index = ({ message = '', type = 'info' }: { message: string; type?: string }) => {
  // TODO 处理type显示不同icon1
  const getIcon = (type: string) => {
    switch (type) {
      case 'info':
        return <InfoCircleOutlined />;
      case 'error':
        return <CloseCircleOutlined />;
      case 'success':
        return <CheckCircleOutlined />;
    }
  };
  return (
    <div
      className={classNames(style.errorMessage, style[type])}
      title={message}
    >
      <span className={style.messageIcon}>{getIcon(type)}</span>
      <span>{message}</span>
    </div>
  );
};

export default Index;
