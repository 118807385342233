import { useState } from 'react';
import { Button } from 'antd';
import { ISecurityProps } from '@interfaces/i-user-center';
import DaqImgUpload from '@components/daq-img-upload';

type SetAvatarProps = 'avatar' | 'onCancel' | 'onOk' | 'submitting';

// 设置头像组件
const Avatar = ({
  avatar = '',
  onCancel,
  onOk,
  submitting,
}: Required<Pick<ISecurityProps, SetAvatarProps>>) => {
  const [imageUrl, setImageUrl] = useState<string>(avatar);

  return (
    <>
      <DaqImgUpload
        description="头像"
        crop={true}
        initialValues={imageUrl}
        accept={['image/jpeg', 'image/png']}
        sizeLimit={1024 * 1024}
        onChange={(url) => {
          setImageUrl(url);
        }}
      />
      <Button
        type="primary"
        disabled={!imageUrl || avatar === imageUrl}
        loading={submitting}
        style={{ marginBottom: '20px' }}
        block
        onClick={() => {
          onOk({ avatar: imageUrl });
        }}
      >
        保存
      </Button>
      <Button
        block
        onClick={() => onCancel('avatar')}
      >
        取消
      </Button>
    </>
  );
};

export default Avatar;
