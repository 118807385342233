/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-10 10:30:45
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-11-08 11:18:12
 * @Description: 应用程序窗口-工具栏-操作按钮
 */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { WindowSize } from '@interfaces/enum';
import DaqIcon from '@components/daq-icon';

interface IToolAction {
  windowSize: WindowSize;
  onMaximize: () => void;
  onMinimize: () => void;
  onRestore: () => void;
  onClose: () => void;
}

const ToolAction: React.FC<IToolAction> = ({
  // windowSize,
  // onMaximize,
  // onRestore,
  onMinimize,
  onClose,
}): JSX.Element => {
  return (
    <>
      <div
        aria-hidden
        className="public-style minimize"
        data-track-info={JSON.stringify({
          firstEventType: '应用窗口菜单栏',
          secondEventType: '最小化',
          status: 1,
        })}
        onClick={onMinimize}
      >
        <DaqIcon icon="&#xe60e;" />
      </div>
      {/* <div
        aria-hidden
        className="public-style"
        onClick={() => {
          // 最大化时点击，则还原；还原时点击，则最大化
          windowSize === WindowSize.maximize ? onRestore() : onMaximize();
        }}
      >
        {windowSize === WindowSize.maximize ? (
          <DaqIcon icon="&#xe611;" />
        ) : (
          <DaqIcon icon="&#xe6b4;" />
        )}
      </div> */}
      <div
        aria-hidden
        className="public-style close"
        data-track-info={JSON.stringify({
          firstEventType: '应用窗口菜单栏',
          secondEventType: '关闭',
          status: 1,
        })}
        onClick={onClose}
      >
        <DaqIcon icon="&#xe68f;" />
      </div>
    </>
  );
};

export default observer(ToolAction);
