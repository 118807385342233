/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-08-01 11:22:17
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-12 14:07:10
 * @Description: 描述
 */

import { IOpenListRes } from '@interfaces/i-api-response';
import React, { useState } from 'react';
import classNames from 'classnames';
import './modal-box.less';
import ModifyModal from './modfiy';
import RecordModal from './record';
import ModifyCloud from './modfiy-cloud';

interface IProps {
  type: string;
  detail: IOpenListRes | null;
  onBack?: (id: string | undefined) => void;
}
const AddModal: React.FC<IProps> = ({ onBack, detail, type }): JSX.Element => {
  const [active, setActive] = useState('modify');
  const onChange = (value: string) => {
    setActive(value);
  };
  return (
    <>
      <div className="add-modal-header">
        <div
          className={classNames('add-modal-header-btn', `${active === 'modify' ? 'active' : ''}`)}
          onClick={() => {
            onChange('modify');
          }}
        >
          管理
        </div>
        <div
          className={classNames('add-modal-header-btn', `${active === 'record' ? 'active' : ''}`)}
          onClick={() => {
            onChange('record');
          }}
        >
          记录
        </div>
      </div>
      {type === 'add' ? (
        <ModifyModal
          detail={detail}
          onBack={onBack}
          className={classNames(`${active === 'modify' ? '' : 'add-modal-hidden'}`)}
        />
      ) : (
        <ModifyCloud
          detail={detail}
          onBack={onBack}
          className={classNames(`${active === 'modify' ? '' : 'add-modal-hidden'}`)}
        />
      )}

      <RecordModal
        type={type}
        appId={(type === 'add' ? detail?.id : detail?.relationId) ?? ''}
        className={classNames(`${active === 'record' ? '' : 'add-modal-hidden'}`)}
      />
    </>
  );
};
export default AddModal;
