import DaqImgUpload from '@components/daq-img-upload';
import { modifyAppApi } from '@api/api-appManagement';
import { IAppManageModifyParams } from '@interfaces/i-api-request';
import { useMemoizedFn, useMount } from 'ahooks';
import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { useStore } from '@stores';
import { IOpenListRes } from '@interfaces/i-api-response';
import regular from '@config/regular';
import DaqSoftTrack from '@utils/daqsoft-track';

interface IProps {
  detail: IOpenListRes | null;
  onClick?: () => void;
  onBack?: (id: string | undefined) => void;
  className?: string;
}

const ModifyCloud: React.FC<IProps> = ({ className, onBack, detail }) => {
  const { systemStore, appWindowStore } = useStore();
  const [isModify, setIsModify] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = useMemoizedFn((values: IAppManageModifyParams) => {
    if (isLoading) {
      return;
    }
    if (detail?.serviceType === 2 && values.loginUrl.indexOf('daqos') !== -1) {
      message.error('地址无效，请重新添加！');
      return;
    }
    modifyExternal(values);
  });

  const onReset = async (id = '') => {
    await onBack?.(id);
    await form.resetFields();
  };

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };

  // 修改
  const modifyExternal = async (params: IAppManageModifyParams) => {
    setIsLoading(true);
    try {
      const modifyParam = {
        ...detail,
        ...params,
      } as IAppManageModifyParams;
      const { data } = await modifyAppApi(modifyParam);
      // 清空选择的多开app
      systemStore.setMultipleApp();
      systemStore.changeStartMenuApp(data);
      appWindowStore.changeDockAndTab(data);
      message.success('修改成功');
      DaqSoftTrack.track({
        firstEventType: '应用管理',
        secondEventType: '修改',
        appId: detail?.appId,
        relationId: detail?.relationId,
        status: 1,
      });
      await onReset(detail?.relationId);
    } catch (error) {
      console.error(error);
      DaqSoftTrack.track({
        firstEventType: '应用管理',
        secondEventType: '修改',
        appId: detail?.appId,
        relationId: detail?.relationId,
        failReason: String(error),
        status: 0,
      });
    }
    await setIsLoading(false);
  };

  useMount(() => {
    if (detail === null) {
      return;
    }
    setIsModify(false);
    form.setFieldsValue({ ...detail });
  });

  return (
    <>
      <div className={`add-modal-body ${className}`}>
        <Form
          {...layout}
          form={form}
          colon={false}
        >
          <Form.Item
            name="appName"
            label="应用名称"
          >
            <span className="ant-form-text text-long">{detail?.appName}</span>
          </Form.Item>
          <Form.Item
            name="displayName"
            label="应用别称"
            rules={[{ required: isModify }]}
          >
            {isModify ? (
              <Input
                maxLength={30}
                allowClear
                showCount
              />
            ) : (
              <span className="ant-form-text">{detail?.displayName}</span>
            )}
          </Form.Item>
          <Form.Item
            name="appLogo"
            label="应用LOGO"
          >
            {isModify ? (
              <>
                <DaqImgUpload
                  isRemove
                  description="应用LOGO"
                  heightLimit={88}
                  widthLimit={88}
                  initialValues={detail?.appLogo || detail?.defaultLogo}
                  className="ant-upload-common ant-upload-logo"
                  onChange={(value) => {
                    form.setFieldsValue({
                      appLogo: value,
                    });
                  }}
                />
                <span className="ant-form-tip">88*88/jpg/png/jpeg，限1MB内</span>
              </>
            ) : (
              <img
                className="ant-form-img"
                src={detail?.appLogo || detail?.defaultLogo}
                alt="logo"
              />
            )}
          </Form.Item>
          {detail?.serviceType === 2 && (
            <Form.Item
              name="loginUrl"
              label="访问地址"
              rules={[
                { required: isModify },
                {
                  pattern: regular.url.pattern,
                  message: regular.url.message,
                },
              ]}
            >
              {isModify ? (
                <Input
                  maxLength={255}
                  allowClear
                  showCount
                />
              ) : (
                <span className="ant-form-text text-long">{detail?.loginUrl || '暂无'}</span>
              )}
            </Form.Item>
          )}
        </Form>
      </div>
      <div className={`add-modal-footer ${className}`}>
        {isModify ? (
          <Form
            form={form}
            name="form2"
            onFinish={onFinish}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              保存
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => {
                onReset();
              }}
            >
              取消
            </Button>
          </Form>
        ) : (
          <>
            <Button
              type="primary"
              disabled={isLoading}
              onClick={() => {
                setIsModify(true);
              }}
            >
              修改
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => {
                onReset();
              }}
            >
              取消
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default ModifyCloud;
