export default {
  phone: {
    pattern: /^1[3-9]\d{9}$/,
    message: '请输入11位正确的手机号',
  },
  email: {
    pattern:
      /^[a-zA-Z\d_-](\.?[a-zA-Z\d_-])*@[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?(?:\.[a-zA-Z\d](?:[a-zA-Z\d-]{0,61}[a-zA-Z\d])?)*$/,
    message: '请输入正确的邮箱地址',
  },
  password: {
    pattern: /^(?!\d+$)(?![a-zA-Z]+$)[\dA-Za-z]{8,20}$/,
    message: '请输入8-20个由数字和字母组合的字符',
  },
  messageAuthCode: {
    pattern: /^\d{6}$/,
    message: '请输入6位数验证码',
  },
  commonName: {
    pattern: /^[A-Za-z\d\u4e00-\u9fa5]+$/,
    message: '文字不含特殊符号',
  },
  url: {
    pattern: /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i,
    message: '请输入正确的网址',
  },
};
