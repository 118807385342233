/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-08 13:43:18
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-07 13:48:13
 * @Description: 自定义单选组合 组件
 */
import CheckButton from '@components/check-button';
import { useMemoizedFn } from 'ahooks';
import React, { useState } from 'react';
import './index.less';
import { TAny } from '@interfaces/i-common';

interface IProps {
  defaultValue?: string[];
  options: Array<TAny>;
  label?: string;
  value?: string;
  disabled?: boolean;
  onClick?: (value: Array<TAny>) => void;
}
const CheckGroup: React.FC<IProps> = ({
  options,
  // defaultValue,
  label = 'label',
  value = 'value',
  disabled = false,
  onClick,
}): JSX.Element => {
  // const [state, setState] = useState<string[]>([]);
  // const changeValue = useMemoizedFn((value: string) => {
  //   const index = state.indexOf(value);
  //   const list = [...state];
  //   if (index === -1) {
  //     list.push(value);
  //   } else {
  //     list.splice(index, 1);
  //   }
  //   setState(list);
  //   onClick?.(list);
  // });
  // useEffect(() => {
  //   if (defaultValue === undefined) {
  //     return;
  //   }
  //   setState(defaultValue);
  // }, [defaultValue]);
  const [state, setState] = useState<Array<TAny>>(options);
  const changeValue = useMemoizedFn((id: string) => {
    const list = [...state];
    list.map((item) => {
      if (item?.[value] === id) {
        item.selected = !item.selected;
      }
    });
    setState(list);
    onClick?.(list);
  });
  return (
    <div className="check-group">
      {state.map((item) => {
        return (
          <CheckButton
            value={item?.[value]}
            key={item?.[value]}
            label={item?.[label]}
            active={item?.selected}
            disabled={disabled}
            onClick={(id) => {
              if (disabled) {
                return;
              }
              changeValue(id);
            }}
          />
        );
      })}
    </div>
  );
};

export default CheckGroup;
