import { Form, Input } from 'antd';
import { IProjectInfo, IInitUser, ILocalApp } from '@interfaces/i-system-init';
import { FormInstance } from 'antd/lib/form';
import React, { useState } from 'react';
import { TAny } from '@interfaces/i-common';
import api from '@api';
import regular from '@config/regular';

interface ProjectProps {
  onFinish(project: IProjectInfo, user: IInitUser, apps: ILocalApp[]): void;

  onMessage(msg: string, type?: string): void;
}

const Project = (props: ProjectProps, ref: React.Ref<FormInstance<TAny>>) => {
  const [projectForm] = Form.useForm();
  const [apps, setApps] = useState<ILocalApp[]>([]);
  const [user, setUser] = useState<IInitUser>({
    phone: '',
    customerName: '',
    unionCode: '',
    username: '',
  });

  const handleFinish = (params: IProjectInfo) => {
    if (params.projectName && params.projectNo && params.phone && params.userName) {
      props.onFinish(params, user, apps);
    }
  };

  const getProjectInfo = async (projectNo: string) => {
    if (projectNo && /^[0-9]{10,20}/.test(projectNo)) {
      try {
        const result = await api.systemInit.getProjectInfo(projectNo);
        if (result.data) {
          if (result.data.valid) {
            props.onMessage('', '');
            projectForm.setFieldsValue({
              projectName: result.data.projectName,
            });
            setUser({
              phone: result.data.phone,
              username: result.data.username,
              customerName: result.data.customerName,
              unionCode: result.data.unionCode,
            });
            setApps(result.data.appConfigVos || []);
          } else {
            props.onMessage('项目编号已部署，请核对项目信息', 'error');
            projectForm.setFieldsValue({
              phone: '',
              projectName: '',
              userName: '',
            });
          }
        } else {
          props.onMessage('项目编号查无信息，请重新输入', 'error');
          projectForm.setFieldsValue({
            phone: '',
            projectName: '',
            userName: '',
          });
        }

        return;
      } catch (e) {
        props.onMessage('获取用户信息失败', 'error');
        projectForm.setFieldsValue({
          phone: '',
          projectName: '',
          userName: '',
        });
        return;
      }
    }

    projectForm.setFieldsValue({
      phone: '',
      projectName: '',
      userName: '',
    });
  };

  return (
    <Form
      ref={ref}
      className="config-config"
      key="projectForm"
      form={projectForm}
      initialValues={{
        // 项目编号
        projectNo: '',
        // 项目名称
        projectName: '',
        // 实施人员
        userName: '',
        // 联系方式
        phone: '',
      }}
      colon={false}
      requiredMark={false}
      onFinish={handleFinish}
      autoComplete="off"
    >
      <Form.Item
        label="项目编号"
        name="projectNo"
        validateFirst
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          {
            required: true,
            message: '请输入项目编号',
          },
          {
            message: '项目编号只能是数字，且长度在 10 到 20 之间',
            validator(rule, value) {
              if (/^[0-9]{10,20}$/.test(value)) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error('项目编号不合法'));
              }
            },
          },
        ]}
      >
        <Input
          autoComplete="off"
          allowClear
          showCount
          maxLength={20}
          placeholder="请输入项目编号查询"
          onChange={(e) => {
            const { value: inputValue } = e.target;
            getProjectInfo(inputValue);
          }}
        />
      </Form.Item>
      <Form.Item
        label="项目名称"
        name="projectName"
        rules={[
          {
            required: true,
            message: '请输入项目名称',
          },
        ]}
      >
        <Input
          autoComplete="off"
          disabled
          placeholder="请输入项目名称"
        />
      </Form.Item>
      <Form.Item
        label="实施人员"
        name="userName"
        validateFirst
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          {
            required: true,
            message: '请输入实施人员名称',
          },
          {
            message: '实施人员名称长度在20个字符以内',
            max: 20,
          },
        ]}
      >
        <Input
          autoComplete="off"
          maxLength={20}
          placeholder="请输入实施人员"
        />
      </Form.Item>
      <Form.Item
        label="联系方式"
        name="phone"
        validateFirst
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          {
            required: true,
            message: '请输入联系方式',
          },
          {
            pattern: regular.phone.pattern,
            message: regular.phone.message,
          },
        ]}
      >
        <Input
          autoComplete="off"
          maxLength={11}
          placeholder="请输入联系方式"
        />
      </Form.Item>
    </Form>
  );
};

export default React.forwardRef(Project);
