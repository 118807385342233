/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2023-02-10 13:53:04
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-28 10:42:11
 * @Description: 描述
 */
import style from './index.module.less';
import DaqIcon from '@components/daq-icon';
import { message, Skeleton } from 'antd';
import React, { useState } from 'react';
import { useImmer } from 'use-immer';
import api from '@api';
import { useDebounceFn, useMount } from 'ahooks';
import { useStore } from '@stores';
import { IAppTaskbar } from '@interfaces/i-app-window';
import AppInfoStore from '@stores/app-window/app-store';
import DaqSoftTrack from '@utils/daqsoft-track';

interface IProps {
  closeSidebar: () => void;
  // 返回天气展示
  backWeather: () => void;
}

const initState = {
  scenicVos: [
    {
      id: '',
      scenicName: '',
      latitude: '',
      longitude: '',
    },
  ],
  regionVo: {
    id: '',
    region: '',
    regionName: '',
    scort: '',
  },
  defaultType: 0,
};
const LocationConfig: React.FC<IProps> = ({ closeSidebar, backWeather }) => {
  const { userStore, systemStore, appWindowStore } = useStore();
  // 是否在切换
  const [changing, setChanging] = useState(false);
  const [loading, setLoading] = useState(true);

  const [state, setState] = useImmer(initState);

  const userType = userStore.permissions.type;

  // 天气应用数据
  const weatherApp = systemStore.weatherApp;

  const successTrack = (
    secondEventType: string,
    thirdEventType: string,
    appId: string,
    relationId: string,
  ) => {
    DaqSoftTrack.track({
      firstEventType: '侧边栏',
      thirdEventType,
      secondEventType,
      appId,
      relationId,
      status: 1,
    });
  };

  // 获取服务设置
  const { run: getConfig } = useDebounceFn(
    async () => {
      try {
        setLoading(true);
        const { data } = await api.weather.getWeatherConfig({ weatherKey: userStore.weatherKey });
        setState(() => data);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    },
    {
      wait: 100,
    },
  );
  // 切换天气地区
  const changeWeather = async (type: number) => {
    if (changing) {
      return;
    }
    setChanging(true);
    try {
      const { data } = await api.weather.changeWeather({
        type,
        weatherKey: userStore.weatherKey,
        name:
          type === 1
            ? `${state.scenicVos?.[0]?.longitude},${state.scenicVos?.[0]?.latitude}`
            : state.regionVo?.region,
      });
      setState((_) => {
        _.defaultType = type;
      });
      let messageContent = '';
      if (data?.status === 3) {
        if (userType === 0) {
          messageContent = '天气服务待充值，请充值后查询';
          openWeatherApp();
        } else {
          messageContent = '服务待充值，请联系管理员';
        }
        closeSidebar();
      } else if (data?.status === 5) {
        messageContent = '天气服务设置已变更';
      } else if (
        data?.status === 1 &&
        state.regionVo?.regionName &&
        state.scenicVos?.[0]?.scenicName
      ) {
        messageContent = `${type === 1 ? '景区' : '地区'}天气服务设置成功!`;
      }
      if (data?.status === 1 || data?.status === 5) {
        backWeather();
      }
      message.info({ key: 'location', content: messageContent });
    } catch (e) {
      console.log(e);
    }
    setChanging(false);
  };

  // 跳转天气应用服务设置
  const openWeatherApp = (url = '') => {
    // 不存在天气应用则忽略
    if (!weatherApp) {
      return;
    }

    if (weatherApp.appUnionId && appWindowStore.tabExist(weatherApp.appUnionId) !== -1) {
      // 已打开过的显示
      appWindowStore.showOpenedApp(weatherApp.appUnionId);
    } else {
      appWindowStore.openApp(new AppInfoStore({ ...weatherApp } as IAppTaskbar));
      successTrack(
        '天气服务',
        '跳转天气应用',
        weatherApp?.appId || '',
        weatherApp?.relationId || '',
      );
    }
    // 前端控制三方应用跳转url地址： 天气服务服务设置
    userStore.setWeatherUrl(url);
    // 关闭侧边栏
    closeSidebar();
  };

  useMount(() => {
    getConfig();
  });

  return (
    <div className={style.locationConfig}>
      <div className={style.header}>
        <div
          className={style.backButton}
          onClick={backWeather}
        >
          <DaqIcon
            icon="&#xe660;"
            size="12px"
          ></DaqIcon>
        </div>
        <div
          className={style.title}
          onClick={backWeather}
        >
          切换天气查询
        </div>
      </div>
      <Skeleton
        active
        round
        title={false}
        paragraph={{
          rows: 6,
        }}
        loading={loading}
      >
        <div className={style.locationInfo}>
          <ul className={style.locationList}>
            {state.regionVo.regionName && (
              <li
                className={`${state.defaultType === 2 ? style.locationListActive : ''}`}
                onClick={() => {
                  changeWeather(2);
                }}
              >
                <span className={style.middot}></span>
                {state.regionVo.regionName}
              </li>
            )}
            {state.scenicVos.length > 0 && (
              <li
                className={`${state.defaultType === 1 ? style.locationListActive : ''}`}
                onClick={() => {
                  changeWeather(1);
                }}
              >
                <span className={style.middot}></span>
                {state.scenicVos?.[0].scenicName}
              </li>
            )}
          </ul>
          {userStore.permissions.type === 0 && (
            <div
              className={style.edit}
              onClick={() => {
                openWeatherApp('service-setting');
              }}
            >
              编辑
            </div>
          )}
        </div>
      </Skeleton>
    </div>
  );
};

export default LocationConfig;
