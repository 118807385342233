import style from './index.module.less';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { GaugeChart } from 'echarts/charts';

import {
  // GridSimpleComponent,
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  // LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import WeatherBox from '@views/weather/components/weather-box';
import { IAirQuality } from '@interfaces/i-weather';
import classNames from 'classnames';
import DaqEmpty from '@components/daq-empty';
import { useMount, useUpdateEffect } from 'ahooks';
import React, { useState } from 'react';
import { EChartsInstance, EChartsOption } from 'echarts-for-react';

const AirQuality = ({ air }: { air: IAirQuality }) => {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    GaugeChart,
    CanvasRenderer,
  ]);

  const colors = ['#a3a3a3', '#66c916', '#f7cb2c', '#f77c12', '#f7000e', '#8b004c', '#7e0023'];
  // 按展示顺序来PM2.5、PM10、O3、CO、SO2、No2，勿乱动
  const maxValue = [500, 600, 1200, 150, 2620, 3840];
  // 下标
  const subs = ['', '', 3, '', 2, 2];

  const echartRef = React.useRef<EChartsInstance>(null);
  const [options, setOptions] = useState<EChartsOption>({});

  useMount(() => {
    const echartInstance = echartRef?.current?.getEchartsInstance();
    if (echartInstance) {
      echartInstance.clear();
    }

    setOptions(getOptions());
  });

  useUpdateEffect(() => {
    const echartInstance = echartRef?.current?.getEchartsInstance();
    if (echartInstance) {
      echartInstance.clear();
    }

    setOptions(getOptions());
  }, [air]);

  const getOptions = () => {
    return {
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          center: ['50%', 125],
          radius: 100,
          min: 0,
          max: 500,
          splitNumber: 10,
          itemStyle: {
            color: colors[air.aqi.level],
          },
          progress: {
            show: true,
            roundCap: true,
            width: 11,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
              width: 11,
              color: [[1, 'rgba(255, 255, 255, .1)']],
            },
          },
          axisTick: {
            splitNumber: 1,
            distance: 6,
            length: 12,
            lineStyle: {
              width: 1,
              color: 'rgba(255, 255, 255, .2)',
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            distance: -45,
            color: 'rgba(255, 255, 255, .5)',
            fontSize: 12,
          },
          title: {
            show: false,
          },
          detail: {
            width: '60%',
            lineHeight: 32,
            offsetCenter: [0, -20],
            valueAnimation: true,
            formatter: function (value: number) {
              return `{value|${value.toFixed(0)}}\n{unit|${air.aqi.text}${
                air.aqi.level > 2 ? '污染' : ''
              }}`;
            },
            rich: {
              value: {
                fontSize: 30,
                fontWeight: 'bolder',
                color: '#fff',
              },
              unit: {
                fontSize: 14,
                color: '#fff',
                padding: [0, 0, 0, 0],
              },
            },
          },
          data: [
            {
              value: air.aqi.value,
            },
          ],
        },
      ],
    };
  };

  return (
    <WeatherBox
      title="空气质量"
      info={air.description || ''}
    >
      {air.aqi.value === '' ? (
        <DaqEmpty></DaqEmpty>
      ) : (
        <>
          <ReactEChartsCore
            ref={echartRef}
            echarts={echarts}
            style={{
              width: '290px',
              height: '135px',
              marginTop: '15px',
            }}
            option={options}
          ></ReactEChartsCore>
          <ul className={style.list}>
            {air.infos.map((item, index) => {
              return (
                <li
                  className={style.listItem}
                  key={index}
                >
                  <p className={style.name}>
                    {subs[index] ? item.name.slice(0, -1) : item.name}
                    <span style={{ fontSize: '10px' }}>{subs[index]}</span>
                  </p>
                  <div className={style.infoText}>
                    <span>{item.value}</span>
                    <span className={classNames(style.state, `aqi-color-level${item.level}`)}>
                      {item.text}
                    </span>
                  </div>
                  <div className={style.barWrap}>
                    <div
                      className={classNames(style.bar, `aqi-background-color-level${item.level}`)}
                      style={{
                        width: `${(Number(item.value) / maxValue[index]) * 100}%`,
                      }}
                    ></div>
                  </div>
                </li>
              );
            })}
          </ul>
        </>
      )}
    </WeatherBox>
  );
};

export default AirQuality;
