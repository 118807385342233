/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-07 10:33:40
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-12 10:44:16
 * @Description: 描述
 */
import {
  IAppManageOpenListParams,
  IAppManageModifyParams,
  IAppManageRecordListParams,
} from '@interfaces/i-api-request';
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

// 公共变量
const config = 'admin/appManagement';

// 获取可用app
export const getOpenListApi = (params: Partial<IAppManageOpenListParams>): AxiosPromise => {
  return axios({
    url: `${config}/openList`,
    method: 'get',
    params,
  });
};

// 修改
export const modifyAppApi = (params: Partial<IAppManageModifyParams>): AxiosPromise => {
  return axios({
    url: `${config}/modify`,
    method: 'post',
    data: params,
  });
};

// 记录
export const recordListApi = (params: Partial<IAppManageRecordListParams>): AxiosPromise => {
  return axios({
    url: `${config}/recordList`,
    method: 'get',
    params,
  });
};
