/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-14 11:51:46
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-06-15 18:38:24
 * @Description: 描述
 */
import React from 'react';
import './index.less';
import { AlignType } from '@interfaces/i-universal-setting';

export interface MenuProps {
  position: AlignType;
  showMouse: boolean;
  appName: string;
}

const MouseMenu: React.FC<MenuProps> = ({ position, showMouse, appName }) => {
  const styles = { display: 'none' };
  return (
    <div
      id="mouse-menu"
      className={position}
      style={!showMouse ? styles : {}}
    >
      <div className="menu-name">{appName}</div>
      <div className="menu-arrow">
        <span className="menu-arrow-content"></span>
      </div>
    </div>
  );
};

export default MouseMenu;
