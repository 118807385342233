/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-07 10:33:40
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-09-16 16:57:49
 * @Description: 描述
 */
import { IExternalAddParams, IExternalModifyParams } from '@interfaces/i-api-request';
import { IPage } from '@interfaces/i-common';
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

// 公共变量
const config = 'admin/externalAppInfo';

// 获取app
export const getListPagingApi = (params: Partial<IPage>): AxiosPromise => {
  return axios({
    url: `${config}/listPaging`,
    method: 'get',
    params,
  });
};

// 添加
export const addExternalApi = (params: IExternalAddParams): AxiosPromise => {
  return axios({
    url: `${config}/add`,
    method: 'post',
    data: params,
  });
};

// 修改
export const modifyExternalApi = (params: IExternalModifyParams): AxiosPromise => {
  return axios({
    url: `${config}/modify`,
    method: 'post',
    data: params,
  });
};

// 删除
export const delExternalApi = (id: string): AxiosPromise => {
  return axios({
    url: `${config}/del`,
    method: 'get',
    params: { id },
  });
};
