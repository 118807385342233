import React, { PropsWithChildren } from 'react';
import style from './index.module.less';
import classNames from 'classnames';

interface IAppListProps {
  padding: number;
  height?: number;
  className?: string;
}

const AppList: React.FC<PropsWithChildren<IAppListProps>> = ({
  height,
  padding,
  children,
  ...props
}) => {
  return (
    <ul
      aria-hidden={true}
      className={classNames(style.appList, props.className || '')}
      style={{
        width: `${window.innerWidth}px`,
        height: height ? `${height}px` : 'auto',
        padding: `0 ${padding}px`,
      }}
    >
      {children}
    </ul>
  );
};

export default AppList;
