/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-06 14:19:04
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-07 09:57:28
 * @Description: 筛选组件
 */
import React from 'react';
import './list-filter.less';
import { Button, DatePicker, Form, Input } from 'antd';
import { IFilterProps } from '@interfaces/i-staff-manage';
import { disabledDate } from '@utils';

const { RangePicker } = DatePicker;
// const { Option } = Select;

const StaffListFilter: React.FC<IFilterProps> = ({
  isLoading,
  onFinish,
  /* onAdd, */
}): JSX.Element => {
  return (
    <div className="list-filter">
      <Form
        layout="inline"
        initialValues={{ layout: 'inline' }}
        onFinish={onFinish}
      >
        <Form.Item
          label="操作时间"
          name="time"
        >
          <RangePicker
            disabledDate={disabledDate}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="手机号"
          name="keyword"
        >
          <Input
            placeholder="请输入"
            maxLength={11}
            allowClear
          />
        </Form.Item>
        <Form.Item
          label="员工姓名"
          name="realName"
        >
          <Input
            placeholder="请输入"
            allowClear
          />
        </Form.Item>
        {/* <Form.Item label="账号状态" name="userStatus">
          <Select placeholder="启用/停用" allowClear style={{ width: 110 }}>
            <Option value="1">启用</Option>
            <Option value="0">停用</Option>
          </Select>
        </Form.Item> */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="filter-btn"
            loading={isLoading}
          >
            查询
          </Button>
          {/* <Button
            type="primary"
            ghost
            onClick={onAdd}
          >
            添加员工
          </Button> */}
        </Form.Item>
      </Form>
    </div>
  );
};

export default StaffListFilter;
