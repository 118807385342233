import style from './environment.module.less';
import { Form, Input, Select } from 'antd';
import { IInitEnv } from '@interfaces/i-system-init';
import { EnvType, NetworkType } from '@interfaces/enum';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { TAny } from '@interfaces/i-common';
import regular from '@config/regular';
import classNames from 'classnames';

const { Option } = Select;

interface EnvironmentProps {
  onFinish(env: IInitEnv): void;
}

const Environment = (props: EnvironmentProps, ref: React.Ref<FormInstance<TAny>>) => {
  const [envForm] = Form.useForm();
  const env = Form.useWatch('deployEnvironment', envForm);
  const networkType = Form.useWatch('networkEnvironment', envForm);

  const handleFinish = (params: IInitEnv) => {
    props.onFinish(params);
  };

  const selectEnv = (env: string) => {
    if (env === EnvType.cloud) {
      envForm.setFieldsValue({ networkEnvironment: NetworkType.outer });
    }
  };

  // const clientApiUrl = import.meta.env.VITE_CLIENT_API_URL || '';
  // const target = import.meta.env.VITE_API_TARGET || '';

  const changeNetwork = (type: NetworkType) => {
    if (type === NetworkType.outer) {
      envForm.setFieldsValue({ regionUrl: 'https://region.daqsoft.com/region/selectThreeRegion' });
    } else {
      envForm.setFieldsValue({ regionUrl: '' });
    }
  };

  return (
    <Form
      ref={ref}
      className="config-config"
      key="envForm"
      form={envForm}
      initialValues={{
        // 访问地址
        baseUrl: '',
        // 部署环境
        deployEnvironment: EnvType.cloud,
        // 网络环境
        networkEnvironment: NetworkType.outer,
        // 地区接口地址 https://region.daqsoft.com/region/selectThreeRegion
        regionUrl: 'https://region.daqsoft.com/region/selectThreeRegion',
      }}
      colon={false}
      requiredMark={false}
      onFinish={handleFinish}
      autoComplete="off"
    >
      <Form.Item
        label="访问地址"
        name="baseUrl"
        validateFirst
        rules={[
          {
            required: true,
            message: '请输入访问地址',
          },
          {
            pattern: regular.url.pattern,
            message: '请输入正确的网址',
          },
        ]}
      >
        <Input
          autoComplete="off"
          allowClear
          showCount
          // disabled
          maxLength={200}
          placeholder="请输入访问地址"
        />
      </Form.Item>
      <Form.Item
        label="部署环境"
        name="deployEnvironment"
        validateFirst
        rules={[
          {
            required: true,
            message: '请选择部署环境',
          },
        ]}
      >
        {/* TODO 打包本都部署和云上部署的包时，直接默认为对应的环境，用户不可选 */}
        <Select
          placeholder="请选择部署环境"
          onChange={selectEnv}
        >
          <Option value={EnvType.cloud}>云上环境</Option>
          <Option value={EnvType.local}>本地环境</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="网络环境"
        name="networkEnvironment"
        validateFirst
        rules={[
          {
            required: true,
            message: '请选择网络环境',
          },
        ]}
      >
        <Select
          placeholder="请选择网络环境"
          onChange={changeNetwork}
          disabled={env === EnvType.cloud}
        >
          <Option value={NetworkType.outer}>外网</Option>
          <Option
            value={NetworkType.inner}
            disbaled
          >
            内网
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="地区接口"
        name="regionUrl"
        className={classNames(networkType === NetworkType.outer ? style.none : '')}
        validateFirst
        rules={[
          {
            required: true,
            message: '请输入地区接口地址',
          },
          {
            pattern: regular.url.pattern,
            message: '请输入正确的接口地址',
          },
        ]}
      >
        <Input
          autoComplete="off"
          allowClear
          showCount
          maxLength={200}
          placeholder="请输入地区接口地址"
        />
      </Form.Item>
    </Form>
  );
};

export default React.forwardRef(Environment);
