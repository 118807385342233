import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import style from './index.module.less';
import BackgroundLayer from '@components/background-layer';
import { IAppStartMenu, IAppTaskbar } from '@interfaces/i-app-window';
import { DaqCarouselRef } from '@interfaces/i-carousel';
import { InputRef, Input } from 'antd';
import DaqCarousel from '@components/daq-carousel';
import AppList from '@components/app-list';
import AppInfo from '@components/app-info';
import { useStore } from '@stores';
import useAutoPaging from '@hooks/use-auto-paging';
import { errorTip, infoTip } from '@utils/error';
import api from '@api';
import SortableLi from '@components/sortable-li';
import { observer } from 'mobx-react-lite';
import { isDockApp, throttled, getViewPortPosition } from '@utils';
import { AppType, TurnPageDirection } from '@interfaces/enum';

import { UniqueIdentifier, useDndMonitor } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import Droppable from '@components/droppable';
import { useMemoizedFn, useUpdateEffect } from 'ahooks';
import AppInfoStore from '@stores/app-window/app-store';
import DaqSoftTrack from '@utils/daqsoft-track';

interface IAppGroupProps {
  group: IAppStartMenu | undefined;
  apps: IAppStartMenu[] | [];
  show: boolean;
}

const AppGroupView: React.FC<IAppGroupProps> = ({ show, group, apps = [] }) => {
  const { systemStore, universalSettingStore, systemSettingStore, dragStore, appWindowStore } =
    useStore();
  const { wallPaper: systemWallPaper } = systemSettingStore;
  const { isMasterSetupWallPager, wallPaper } = universalSettingStore;
  // 处理点击空白处隐藏分组
  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();

    // 如果点击空白处关闭切换应用页面
    if (!['button'].includes((e.target as HTMLElement).tagName.toLowerCase())) {
      // 如果点击的不是翻页按钮，隐藏分组
      systemStore.hideAppGroup();
      setEditGroupName(false);
      return;
    }
  };

  // 列表ref控制翻页
  const listRef = useRef<DaqCarouselRef>(null);
  // 滚动事件节流，处理翻页
  const run = throttled((e: React.WheelEvent<HTMLDivElement>) => {
    // 只处理纯滚动
    if (!e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey) {
      if (e.deltaY > 0) {
        // 向下滚动，左翻页
        listRef?.current?.next();
      } else if (e.deltaY < 0) {
        // 向下滚动，右翻页
        listRef?.current?.prev();
      }
    }
  });
  // 自动分页
  const appBlock = {
    width: 228,
    height: 200,
  };
  const { pageInfo } = useAutoPaging({
    containerId: 'appGroupView',
    appBlock,
    total: apps.length || 0,
  });

  const successTrack = (secondEventType: string, appId: string, relationId: string) => {
    DaqSoftTrack.track({
      firstEventType: '分组应用',
      secondEventType,
      appId,
      relationId,
      status: 1,
    });
  };
  const errorTrack = (
    secondEventType: string,
    appId: string,
    relationId: string,
    failReason: string,
  ) => {
    DaqSoftTrack.track({
      firstEventType: '分组应用',
      secondEventType,
      appId,
      relationId,
      failReason,
      status: 0,
    });
  };

  // 点击app
  const handleClick = async (e: React.MouseEvent, app: IAppStartMenu) => {
    // 点击app时阻止冒泡，避免点击应用时隐藏分组
    e.stopPropagation();

    // 处理过期  多开应用不处理
    if (app.openAppNum <= 1) {
      if (app.tag === '已过期') {
        errorTrack('打开应用', app.appId, app.relationId, '已过期');
        errorTip(`访问应用“${app.displayName}”失败，该应用已过期！`);
        return;
      } else if (app.serviceType === 2 && app.localServiceState === 0) {
        errorTip('应用部署中，请联系管理员！');
        errorTrack('打开应用', app.appId, app.relationId, '应用部署中');
        return;
      }
    }

    if (appWindowStore.tabExist(app.appUnionId) !== -1) {
      // 隐藏开始菜单
      systemStore.hideStartMenu();
      // 隐藏分组
      systemStore.hideAppGroup();
      // 隐藏任务栏
      if (universalSettingStore.autoHide && universalSettingStore.moveShow) {
        universalSettingStore.setMoveShow(false);
      }
      // 已打开过的显示
      appWindowStore.showOpenedApp(app.appUnionId);
    } else {
      // 处理新打开应用的情况
      if (app.appType === AppType.web && app.openAppNum > 1) {
        // 打开多开应用列表（web应用且开通个数大于1时）
        systemStore.setMultipleApp(app);
        systemStore.showMultipleAppMenu();
      } else {
        // 内置应用 或者 单开的web应用 外部应用

        // 单开的web应用和外部应用特殊处理
        if ([AppType.externalIn, AppType.web].includes(app?.appType)) {
          if (systemStore.gettingUrlApps.includes(app.appId)) {
            infoTip(`应用“${app.displayName}”正在打开中，请稍后！`);
            return;
          } else {
            systemStore.addGettingUrlApp(app.appId);
          }

          try {
            // 获取访问地址，如果失败则退出
            const { data } = await api.third.getWebAppAccessUrl({
              relationId: app?.appType === AppType.externalIn ? app.appId : app.relationId,
              appType: app.appType,
            });
            successTrack('打开应用', app.appId, app.relationId);
            if (!data.url) {
              systemStore.removeGettingUrlApp(app.appId);
              return;
            }
          } catch (e) {
            systemStore.removeGettingUrlApp(app.appId);
            errorTrack('打开应用', app.appId, app.relationId, String(e));
            return;
          }
        }

        // 隐藏开始菜单
        systemStore.hideStartMenu();
        systemStore.hideAppGroup();
        // 隐藏任务栏
        if (universalSettingStore.autoHide && universalSettingStore.moveShow) {
          universalSettingStore.setMoveShow(false);
        }
        appWindowStore.openApp(new AppInfoStore({ ...app } as IAppTaskbar));
        if (![AppType.externalIn, AppType.web].includes(app?.appType)) {
          successTrack('打开应用', app.appId, app.relationId);
        }

        systemStore.removeGettingUrlApp(app.appId);
      }
    }
  };

  // 处理组内拖动翻页
  const [turnPagePrevTimer, setTurnPagePrevTimer] = useState(0);
  const [turnPageNextTimer, setTurnPageNextTimer] = useState(0);
  // 悬停时间翻页
  const interval = 1000;
  const enterLeftTurnPageDroppable = useMemoizedFn(() => {
    // 新触发先清除计时器
    window.clearInterval(turnPagePrevTimer);
    // 翻页后，鼠标悬停超过1秒则继续翻页
    const timer = window.setInterval(() => {
      listRef?.current?.prev();
    }, interval);
    setTurnPagePrevTimer(timer);
  });
  const enterRightTurnPageDroppable = useMemoizedFn(() => {
    // 新触发先清除计时器
    window.clearInterval(turnPageNextTimer);
    // 翻页后，鼠标悬停超过1秒则继续翻页
    const timer = window.setInterval(async () => {
      listRef?.current?.next();
    }, interval);
    setTurnPageNextTimer(timer);
  });
  // 离开翻页检测区域，清除翻页定时器
  const leaveTurnPageDroppable = useMemoizedFn((active) => {
    // 拖动非组内元素则忽略
    if (!inGroup(active?.id)) {
      return;
    }

    if (turnPageNextTimer) {
      window.clearInterval(turnPageNextTimer);
      setTurnPageNextTimer(0);
    }
    if (turnPagePrevTimer) {
      window.clearInterval(turnPagePrevTimer);
      setTurnPagePrevTimer(0);
    }
  });

  // 碰撞到了拖出组的元素
  const enterLeaveGroupDroppable = useMemoizedFn((active) => {
    // 拖动非组内元素则忽略
    if (!inGroup(active?.id)) {
      return;
    }

    // 处理组内元素拖出组内的情况
    // 开始菜单当前页码
    const startMenuPageNo = systemStore.startMenuPageIndex;
    const startMenuPageInfo = systemStore.startMenuPageInfo;
    // 当前页如果是最后一页，并且不是满的，则添加到页面最后
    if (
      startMenuPageNo === startMenuPageInfo.pageCounts &&
      startMenuPageInfo.lastPageSize !== startMenuPageInfo.pageSize
    ) {
      systemStore.moveGroupAppToStartMenu(
        active.id as string,
        (startMenuPageNo - 1) * startMenuPageInfo.pageSize + startMenuPageInfo.lastPageSize,
      );
    } else {
      // 不是最后一页，或者是最后一页但是是满的，则替换最后一个位置
      systemStore.moveGroupAppToStartMenu(
        active.id as string,
        startMenuPageNo * startMenuPageInfo.pageSize - 1,
      );
    }

    systemStore.hideAppGroup();
  });

  // 获取组内app的位置
  const getAppInGroupIndex = (id: UniqueIdentifier) => {
    return apps.findIndex((app) => app.appUnionId === id);
  };

  // 查看拖动的app是否是组内的app
  const inGroup = (id: UniqueIdentifier) => {
    return apps.find((app) => app.appUnionId === id);
  };

  // 设置打开时候放缩动画的源点
  const [transformOrigin, setTransformOrigin] = useState('center center');
  // 打开分组，处理是否是拖动app到组内
  useUpdateEffect(() => {
    // 显示分组时，重置页码
    if (show) {
      listRef?.current?.goTo(1, false);

      // 计算动画源点（每次打开都重新计算，因为可能拖动变化了位置）
      let origin = 'center center';
      // rect是相对视口的位置，需要转换为#appGroupView中的位置
      const rect = getViewPortPosition(`app${group?.appUnionId || ''}`);
      if (rect) {
        // 44为分组白色区域高度的一半，20是分组白色区块的margin top， 130为分#appGroupView到顶部的距离（源点为分组白色区域中心）
        origin = `${rect.left + rect.width / 2}px ${rect.top + 20 + 44 - 130}px`;
      }
      setTransformOrigin(origin);

      // 处理组信息
      setTimeout(() => {
        // 清除分组状态（显示了组之后在清除分组状态，防止组开没打开时，触发分组的样式效果变了）
        dragStore.setStartMenuGroupingId('');

        // 进入分组时，存在拖动id，则添加app到组的最后 TODO 后期可优化一下，插入到组中的当前页面的最后
        if (dragStore.draggingId && group) {
          // timeout一下避免从上一个页面拖动app到下一个页面中的第一个元素组合为组，或拖进组时，组消失的问题（移动到上一页去了）
          setTimeout(() => {
            systemStore.moveStartMenuAppToGroup(dragStore.draggingId, group.appUnionId);
            // 组数据变化了重新设置一下组信息
            const targetApp = systemStore.getAppByUnionId(group.appUnionId);
            systemStore.setTargetAppGroup(targetApp);
          }, 100);
        }
      }, 300);
    }
  }, [show]);

  useUpdateEffect(() => {
    systemStore.recordAppGroupPageInfo({
      pageSize: pageInfo.pageSize,
      pageCounts: pageInfo.pageCounts,
      lastPageSize: apps.length % pageInfo.pageSize,
    });
  }, [pageInfo.pageSize, pageInfo.pageCounts, apps.length]);

  const clearGroupAppDragInfo = () => {
    // 清除拖动相关的标记
    dragStore.clearDragInfo();

    // 拖动结束清除翻页定时器
    window.clearInterval(turnPagePrevTimer);
    window.clearInterval(turnPageNextTimer);
    setTurnPageNextTimer(0);
    setTurnPagePrevTimer(0);
  };

  // 处理拖动逻辑
  useDndMonitor({
    onDragStart({ active }) {
      const activeId = active.id as string;
      // 只管拖动的元素是组内的情况
      const index = getAppInGroupIndex(activeId);
      if (index < 0) {
        return;
      }
    },
    onDragOver({ active, over }) {
      // 只处理组内的拖动
      if (
        // 拖动的app不是组内的
        !inGroup(active.id) ||
        // 没有碰撞或者碰撞的任务栏容器app
        !over ||
        isDockApp(over.id) ||
        // 碰撞到翻页容器
        [
          TurnPageDirection.prev,
          TurnPageDirection.next,
          TurnPageDirection.groupPrev,
          TurnPageDirection.groupNext,
        ].includes(over.id as TurnPageDirection) ||
        // 未显示组，则忽略（拖动到组外面去了）
        !systemStore.appGroupShow ||
        // 显示了组，但是并不是当前组，则忽略（拖动到其它组里面去了）
        (systemStore.appGroupShow && systemStore.targetAppGroup?.appUnionId !== group?.appUnionId)
      ) {
        return;
      }

      // 处理组内的翻页排序
      const currentPageNo = listRef?.current?.getCurrentPageNo() || 0;
      const currentPageStartIndex = (currentPageNo - 1) * pageInfo.pageSize;
      const pageApps = apps.slice(currentPageStartIndex, currentPageStartIndex + pageInfo.pageSize);
      // 获取app在当前页面内位置
      const activeAppInPageIndex = pageApps.findIndex((app) => app.appUnionId === active.id);
      const overAppInPageIndex = pageApps.findIndex((app) => app.appUnionId === over.id);
      // 如果拖动的app不在当前页面，并且over在，则移动到当前页面
      if (activeAppInPageIndex < 0 && overAppInPageIndex >= 0) {
        // 拖动的app在组中的位置
        const activeIndex = apps.findIndex((app) => app.appUnionId === active.id);
        // over的app在组中的位置
        const overIndex = apps.findIndex((app) => app.appUnionId === over.id);
        if (activeIndex !== overIndex && activeIndex >= 0 && overIndex >= 0) {
          systemStore.sortGroupApp(activeIndex, overIndex);
        }
      }
    },
    onDragEnd({ active, over }) {
      const activeId = active.id as string;
      // 拖动的不是组的app则忽略
      if (!inGroup(activeId)) {
        return;
      }

      // end的时候，拖动的app在组中的索引位置
      const activeIndex = getAppInGroupIndex(active.id);
      if (over) {
        const overIndex = getAppInGroupIndex(over.id);
        if (activeIndex !== overIndex && activeIndex >= 0 && overIndex >= 0) {
          systemStore.sortGroupApp(activeIndex, overIndex);
          // TODO 处理异常
          api.startMenu.saveStartMenuApps(systemStore.startMenuObj);
        }
      } else {
        const activeApp = systemStore.getAppByUnionId(activeId);
        if (!activeApp) {
          // 清除拖动相关的标记
          clearGroupAppDragInfo();
          return;
        }

        const dragInfo = dragStore.dragInfo;
        // 如果拖动前的app组id和现在app的组id不一致，或者拖动前的位置信息和当前位置信息不一致，则表示数据变化了，保存
        if (dragInfo.pId !== activeApp.pId || dragInfo.index !== activeIndex) {
          // TODO 处理异常
          api.startMenu.saveStartMenuApps(systemStore.startMenuObj);
        }
      }

      // 清除拖动相关的标记
      clearGroupAppDragInfo();
    },
  });

  const [editGroupName, setEditGroupName] = useState(false);
  const [groupName, setGroupName] = useState('未命名');

  useEffect(() => {
    setGroupName(group?.appName || '未命名');
  }, [group?.appName]);

  // 组名称输入框
  const inputRef = useRef<InputRef>(null);

  const renderGroupPage = () => {
    const pages = [];
    for (let i = 0; i < pageInfo.pageCounts; i++) {
      const pageId = `page${i}`;
      const startIndex = i * pageInfo.pageSize;
      const pageApps = apps.slice(startIndex, startIndex + pageInfo.pageSize);

      pages.push(
        <SortableContext
          key={pageId}
          items={pageApps.map((item) => item.appUnionId)}
        >
          <div
            className={style.list}
            style={{
              height: `${appBlock.height * pageInfo.rows}px`,
            }}
          >
            <div
              className={style.panel}
              style={{
                left: `${pageInfo.padding}px`,
                width: `calc(100% - ${pageInfo.padding * 2}px)`,
              }}
            ></div>
            <AppList padding={pageInfo.padding}>
              {pageApps.map((app) => (
                <SortableLi
                  key={`${app.appUnionId}`}
                  uid={`${app.appUnionId}`}
                  className={style.appItem}
                >
                  <AppInfo
                    app={app}
                    onClick={handleClick}
                    showAlias={universalSettingStore.showName}
                  ></AppInfo>
                </SortableLi>
              ))}
            </AppList>
          </div>
        </SortableContext>,
      );
    }

    return pages;
  };

  return (
    <BackgroundLayer
      className={classNames(style.appGroupView)}
      wallPaper={isMasterSetupWallPager === 0 ? wallPaper : systemWallPaper}
      style={{
        width: `${window.innerWidth}px`,
        height: `${window.innerHeight}px`,
      }}
      show={show}
      onClick={handleClose}
    >
      {!editGroupName ? (
        <h3
          className={style.title}
          onClick={(e) => {
            e.stopPropagation();
            setEditGroupName(true);

            // 处理input框文本全选
            window.setTimeout(() => {
              if (inputRef?.current) {
                // inputRef.current.focus();
                inputRef.current.select();
              }
            }, 100);
          }}
        >
          {groupName}
        </h3>
      ) : (
        <Input
          ref={inputRef}
          className={classNames(style.title, style.edit)}
          bordered={false}
          placeholder=""
          autoComplete="off"
          size="middle"
          defaultValue={groupName}
          maxLength={20}
          onChange={(e) => {
            const { value: inputValue } = e.target;
            setGroupName(inputValue);
          }}
          onBlur={() => {
            if (groupName) {
              systemStore.setGroupName(groupName);
              // 保存修改  TODO处理异常
              api.startMenu.saveStartMenuApps(systemStore.startMenuObj);
            } else {
              // 如果为空则还原为以前的名称
              const oldName = group?.appName || '未命名';
              setGroupName(oldName);
              systemStore.setGroupName(oldName);
            }

            // 退出编辑模式
            setEditGroupName(false);
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )}
      <div
        id="appGroupView"
        className={style.appList}
        onWheel={run}
      >
        <>
          <Droppable
            id="group-container-top"
            className={classNames(style.groupBoundary, style.top)}
            style={{
              width: `${window.innerWidth - 60}px`,
            }}
            onEnter={enterLeaveGroupDroppable}
          ></Droppable>
          <Droppable
            id="group-container-right"
            className={classNames(style.groupBoundary, style.right)}
            style={{
              height: `${window.innerHeight - 60}px`,
            }}
            onEnter={enterLeaveGroupDroppable}
          ></Droppable>
          {/* 不要底部，底部表示拖动到任务栏 */}
          <Droppable
            id="group-container-bottom"
            className={classNames(style.groupBoundary, style.bottom)}
            style={{
              width: `${window.innerWidth - 60}px`,
            }}
            onEnter={enterLeaveGroupDroppable}
          ></Droppable>
          <Droppable
            id="group-container-left"
            className={classNames(style.groupBoundary, style.left)}
            style={{
              height: `${window.innerHeight - 60}px`,
            }}
            onEnter={enterLeaveGroupDroppable}
          ></Droppable>
          {
            // 触发翻页的碰撞检测区域
            pageInfo.pageCounts > 1 ? (
              <>
                <Droppable
                  id={TurnPageDirection.groupPrev}
                  className={classNames(style.turnPageBoundary, style.prev)}
                  style={{
                    height: `${appBlock.height * pageInfo.rows}px`,
                    top: '130px',
                    left: `${pageInfo.padding - 10}px`,
                  }}
                  onEnter={enterLeftTurnPageDroppable}
                  onLeave={leaveTurnPageDroppable}
                ></Droppable>
                <Droppable
                  id={TurnPageDirection.groupNext}
                  className={classNames(style.turnPageBoundary, style.next)}
                  style={{
                    height: `${appBlock.height * pageInfo.rows}px`,
                    top: '130px',
                    right: `${pageInfo.padding - 10}px`,
                  }}
                  onEnter={enterRightTurnPageDroppable}
                  onLeave={leaveTurnPageDroppable}
                ></Droppable>
              </>
            ) : null
          }
          <DaqCarousel
            ref={listRef}
            initPageNo={1}
            pageCounts={pageInfo.pageCounts}
            style={{
              transformOrigin: transformOrigin,
            }}
            className={classNames(style.carousel, 'app-group-container')}
            afterTurnPage={(pageNo) => {
              systemStore.recordAppGroupPageIndex(pageNo);
            }}
          >
            {renderGroupPage()}
          </DaqCarousel>
        </>
      </div>
    </BackgroundLayer>
  );
};

export default observer(AppGroupView);
