/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-11 17:14:16
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-13 17:34:08
 * @Description: 员工管理
 */

import {
  IStaffAddParams,
  IStaffListPagingParams,
  IStaffModifyParams,
  IStaffModifyParams2,
} from '@interfaces/i-api-request';
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

// 公共变量
const config = 'admin/staff';

// 获取用户列表
export const getListPagingApi = (params: Partial<IStaffListPagingParams>): AxiosPromise => {
  return axios({
    url: `${config}/listPaging`,
    method: 'get',
    params,
  });
};

// 新增角色
export const addStaffApi = (params: IStaffAddParams): AxiosPromise => {
  return axios({
    url: `${config}/add`,
    method: 'post',
    data: params,
  });
};
// 获取初始化数据，应用列表，角色列表
export const getBeforeAddInfoApi = (): AxiosPromise => {
  return axios({
    url: `${config}/getBeforeAddInfo`,
    method: 'get',
  });
};

// 修改员工
export const modifyApi = (params: IStaffModifyParams): AxiosPromise => {
  return axios({
    url: `${config}/modify`,
    method: 'post',
    data: params,
  });
};
export const modifyManageAppApi = (params: IStaffModifyParams2): AxiosPromise => {
  return axios({
    url: `${config}/modifyManageApp`,
    method: 'post',
    data: params,
  });
};
// 获取员工详情
export const getDetailApi = (id: string): AxiosPromise => {
  return axios({
    url: `${config}/detail`,
    method: 'get',
    params: { id },
  });
};

// 删除员工
export const delStaffApi = (userId: string): AxiosPromise => {
  return axios({
    url: `${config}/del`,
    method: 'get',
    params: { userId },
  });
};

// 启用禁用
export const enableOrDisableApi = (params: {
  userId: string;
  userStatus: number;
}): AxiosPromise => {
  return axios({
    url: `${config}/enableOrDisable`,
    method: 'post',
    data: params,
  });
};

// 重置密码
export const resetPwdApi = (userId: string): AxiosPromise => {
  return axios({
    url: `${config}/resetPwd`,
    method: 'get',
    params: { userId },
  });
};
