import { makeAutoObservable } from 'mobx';

class DragStore {
  // 记录拖动目标拖动前的信息
  dragInfo = {
    // appUnionId
    id: '',
    // 父级id，组中的app需要设置，非组中的app则为空
    pId: '',
    // app的位置索引，有pId时表示，在组中的索引位置
    index: 0,
  };
  // 开始菜单触发分组时，正在分组的id，组合组时是目标app的id，拖动到组时是目标组的id（当碰撞后还没处理过数据的过程，如果悬停进入了分组或者拖动结束，则状态消失）（主要用来区分分组还是排序）
  startMenuGroupingId = '';
  // 开始菜单正在排序的id（主要用来区分分组还是排序）
  startMenuSortingId = '';
  // 上一次拖动的id
  lastDragId = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get draggingId() {
    return this.dragInfo.id;
  }

  setStartMenuGroupingId(id: string) {
    this.startMenuGroupingId = id;
  }

  setStartMenuSortingId(id: string) {
    this.startMenuSortingId = id;
  }

  setDragInfo({ id = '', pId = '', index = -1 }) {
    this.dragInfo = {
      id: id,
      pId: pId,
      index: index,
    };
  }

  clearDragInfo() {
    this.lastDragId = this.draggingId;
    this.dragInfo = {
      id: '',
      pId: '',
      index: -1,
    };
  }

  clear() {
    this.startMenuGroupingId = '';
    this.startMenuSortingId = '';
    this.clearDragInfo();
  }
}

const dragStore = new DragStore();
export default dragStore;
