/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-06 14:38:01
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-21 17:09:30
 * @Description: 通用设置页面
 */
import React, { useState } from 'react';
import './index.less';
import dark from '@assets/images/universal-settings/dark.png';
import light from '@assets/images/universal-settings/light.png';
import taskbarLogo from '@assets/images/universal-settings/taskbar-logo.png';
import classNames from 'classnames';
import ContainerCard from '@components/container-card';
import { ConfigProvider, Form, Radio, RadioChangeEvent, Select } from 'antd';
import { useStore } from '@stores/index';
import DaqIcon from '@components/daq-icon';
import ColorList from './components/color-list';
import { observer } from 'mobx-react-lite';
import { AlignType } from '@interfaces/i-universal-setting';
import api from '@api';
import { useMemoizedFn } from 'ahooks';
import { ISetting } from '@interfaces/i-api-request';
import DaqImgUpload from '@components/daq-img-upload';
import DaqSoftTrack from '@utils/daqsoft-track';

const { Option } = Select;

const UniversalSettings: React.FC = (): JSX.Element => {
  const colorList = [
    '0181ff',
    'a550a7',
    'ec519e',
    'ed4d54',
    'f18431',
    'f8b732',
    '62ba46',
    '1fc09c',
  ];
  const [color, setColor] = useState({ primaryColor: '#0181ff' });
  const [form] = Form.useForm();
  const { universalSettingStore, systemSettingStore } = useStore();
  const {
    screenPosition,
    setPosition,
    autoHide,
    setAutoHide,
    model,
    setModel,
    initSetting,
    setInitSetting,
    isDockBig,
    setIsDockBig,
    showName,
    setShowName,
    wallPaper,
    setWallPaper,
    isMasterSetupWallPager,
  } = universalSettingStore;

  const setSave = useMemoizedFn(async () => {
    await api.systemSetting.setSaveApi(initSetting);
  });

  const onChangeSetting = (obj: Partial<ISetting>) => {
    setInitSetting(obj);
    setSave();
  };
  const successTrack = (
    secondEventType: string,
    thirdEventType: string,
    fourthEventType?: string,
  ) => {
    DaqSoftTrack.track({
      firstEventType: '通用设置',
      secondEventType,
      thirdEventType,
      fourthEventType,
      status: 1,
    });
  };

  const onChangeDark = (type: string) => {
    setModel(type === '浅色' ? 0 : 1);
    onChangeSetting({ model: type === '浅色' ? 0 : 1 });
    successTrack('主题外观', type);
  };
  const onFormLayoutChange = ({
    screenPosition,
    autoHide,
    isDockBig,
  }: {
    screenPosition: AlignType;
    autoHide: string;
    isDockBig: string;
  }) => {
    if (screenPosition !== undefined) {
      setPosition(screenPosition);
      onChangeSetting({ screenPosition });
      successTrack('任务栏设置', '置于屏幕位置', screenPosition);
    }

    if (autoHide !== undefined) {
      setAutoHide(JSON.parse(autoHide));
      const displaySetting = JSON.parse(autoHide) ? 'hidden' : 'fixed';
      onChangeSetting({ displaySetting });
      successTrack('任务栏设置', '显示设置', displaySetting);
    }
    if (isDockBig !== undefined) {
      setIsDockBig(JSON.parse(isDockBig));
      const taskbarDynamicShow = JSON.parse(isDockBig) ? 1 : 0;
      onChangeSetting({ taskbarDynamicShow });
      successTrack('任务栏设置', '任务栏应用', String(taskbarDynamicShow));
    }
  };

  const onColorChange = (nextColor: Partial<typeof color>) => {
    const mergedNextColor = {
      ...color,
      ...nextColor,
    };
    setColor(mergedNextColor);
    ConfigProvider.config({ theme: mergedNextColor });
    onChangeSetting({ highlightColor: nextColor.primaryColor });
    successTrack('高亮颜色', String(nextColor.primaryColor));
  };

  const onFontSizeChange = (fontSize: string) => {
    onChangeSetting({ pixel: fontSize });
    document.documentElement.style.setProperty('--font-size-base', fontSize);
    successTrack('系统字体', '大小', fontSize);
  };
  const onFontWeightChange = (fontWeight: string) => {
    onChangeSetting({ thickness: fontWeight });
    document.documentElement.style.setProperty('--font-weight-base', fontWeight);
    successTrack('系统字体', '粗细', fontWeight);
  };

  const onShowNameChange = (e: RadioChangeEvent) => {
    const showName = e.target.value;
    setShowName(showName);
    onChangeSetting({ showName });
    successTrack('显示应用名称', showName);
  };

  const onWallPaperChange = (url: string) => {
    setWallPaper(url);
    onChangeSetting({ wallPaper: url });
    successTrack('桌面背景', url === '' ? '删除' : '设置');
  };

  return (
    <div className="universal-settings">
      <div className="universal-settings-body">
        <ContainerCard title="主题外观">
          <div className="universal-settings-body-theme">
            <div
              aria-hidden="true"
              className={classNames('universal-settings-body-theme-center', { active: !model })}
              onClick={() => {
                onChangeDark('浅色');
              }}
            >
              <img
                src={light}
                alt="浅色"
              />
              <div>浅色</div>
            </div>
            <div
              aria-hidden="true"
              className={classNames('universal-settings-body-theme-center', { active: model })}
              onClick={() => {
                onChangeDark('深色');
              }}
            >
              <img
                src={dark}
                alt="深色"
              />
              <div>深色</div>
            </div>
          </div>
        </ContainerCard>
        <ContainerCard title="任务栏设置">
          <div className="universal-settings-body-taskbar">
            <img
              src={taskbarLogo}
              alt="taskbar-logo"
            />
            <Form
              initialValues={{
                screenPosition,
                autoHide,
                isDockBig,
              }}
              form={form}
              onValuesChange={onFormLayoutChange}
              colon={false}
              labelAlign="right"
            >
              <Form.Item name="screenPosition">
                <div className="universal-settings-body-taskbar-form">
                  <div className="universal-settings-body-taskbar-form-label">置于屏幕位置</div>
                  <Radio.Group
                    value={screenPosition}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="left">左边</Radio.Button>
                    <Radio.Button value="bottom">底部</Radio.Button>
                    <Radio.Button value="right">右边</Radio.Button>
                  </Radio.Group>
                </div>
              </Form.Item>
              <Form.Item name="autoHide">
                <div className="universal-settings-body-taskbar-form">
                  <div className="universal-settings-body-taskbar-form-label text-right">
                    &emsp;&emsp;显示设置
                  </div>
                  <Radio.Group
                    value={autoHide}
                    buttonStyle="solid"
                  >
                    <Radio.Button value={false}>显示固定</Radio.Button>
                    <Radio.Button value={true}>自动隐藏</Radio.Button>
                  </Radio.Group>
                </div>
              </Form.Item>
              <Form.Item name="isDockBig">
                <div className="universal-settings-body-taskbar-form">
                  <div className="universal-settings-body-taskbar-form-label text-right">
                    &emsp;任务栏应用
                  </div>
                  <Radio.Group
                    value={isDockBig}
                    buttonStyle="solid"
                  >
                    <Radio.Button value={true}>动态显示</Radio.Button>
                    <Radio.Button value={false}>静态显示</Radio.Button>
                  </Radio.Group>
                </div>
              </Form.Item>
            </Form>
          </div>
        </ContainerCard>
      </div>
      <div className="universal-settings-body">
        <ContainerCard title="高亮颜色">
          <ColorList
            colorList={colorList}
            onColorChange={onColorChange}
            activeColor={initSetting.highlightColor}
          />
        </ContainerCard>
        <ContainerCard title="显示应用名称">
          <Radio.Group
            value={showName}
            buttonStyle="solid"
            onChange={onShowNameChange}
          >
            <Radio.Button value={0}>默认应用名称</Radio.Button>
            <Radio.Button value={1}>自定义应用别称</Radio.Button>
          </Radio.Group>
        </ContainerCard>
      </div>
      <div className="universal-settings-body">
        <ContainerCard title="系统字体">
          <div className="universal-settings-body-text">
            <div className="universal-settings-body-text-select">
              <DaqIcon icon="&#xe872;" />
              <Select
                defaultValue={initSetting.thickness}
                style={{ width: 155 }}
                onChange={onFontWeightChange}
                bordered={false}
              >
                <Option value="400">标准</Option>
                <Option value="500">中粗</Option>
                <Option value="600">较粗</Option>
              </Select>
            </div>
            <div className="universal-settings-body-text-select">
              <DaqIcon icon="&#xeb7f;" />
              <Select
                defaultValue={initSetting.pixel}
                style={{ width: 150 }}
                onChange={onFontSizeChange}
                bordered={false}
              >
                <Option value="14px">标准</Option>
                <Option value="16px">适中</Option>
                <Option value="18px">较大</Option>
              </Select>
            </div>
          </div>
        </ContainerCard>
        <ContainerCard
          title="桌面背景"
          text="图片不大于5MB，尺寸1920*1080px，建议png格式"
        >
          <div className="universal-settings-body-wallPaper">
            <DaqImgUpload
              isRemove
              description="桌面背景"
              initialValues={
                isMasterSetupWallPager === 1 ? systemSettingStore.wallPaper : wallPaper
              }
              className="ant-upload-common ant-upload-wallPaper"
              onChange={onWallPaperChange}
              sizeLimit={1024 * 1024 * 5}
            />
            {isMasterSetupWallPager === 1 && (
              <div className="is-master-setup-wallPager">管理员已接管配置</div>
            )}
          </div>
        </ContainerCard>
      </div>
    </div>
  );
};

export default observer(UniversalSettings);
