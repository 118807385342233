/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2023-02-03 13:41:45
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-13 14:28:22
 * @Description: 未开通天气页面
 */
import React, { useState } from 'react';
import './index.less';
import ContainerCard from '@components/container-card';
import { Button, Descriptions, message, Popconfirm } from 'antd';
import lightBg from '@assets/images/weather-bg.png';
import darkBg from '@assets/images/weather-bg-dark.png';
import Scrollbars from 'rc-scrollbars';
import { weatherOpen } from '@api/api-serviceConfig';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores';
import DaqIcon from '@components/daq-icon';

const WeatherView: React.FC = (): JSX.Element => {
  const { userStore, universalSettingStore } = useStore();
  const [loading, setLoading] = useState(false);
  const openedWeather = async () => {
    try {
      setLoading(true);
      const { data } = await weatherOpen({
        authName: userStore.authenticationName,
        serviceName: '天气服务',
      });
      userStore.setWeatherKey(data);
      message.success('天气服务开通成功！');
    } catch (error) {
      message.error(String(error));
    }
    setLoading(false);
  };
  return (
    <div className="weather-view">
      <Scrollbars autoHide>
        <div
          className="weather-view-banner"
          style={{
            backgroundImage:
              universalSettingStore.model === 1 ? `url(${darkBg})` : `url(${lightBg})`,
          }}
        >
          <div className="weather-view-banner-title">欢迎开通天气服务</div>
          <div className="weather-view-banner-info">
            <div className="weather-view-banner-info-img">
              <img
                src="images/weather-service.png"
                alt=""
              />
            </div>
            <div className="weather-view-banner-info-action">
              <div className="title">天气服务</div>
              <div className="remake">
                城市天气预报提供包括中国3000+市县区在内的全球20万+城市的天气预报。支持实时天气及预警、未来24小时/7天气、空气质量、2小时降水量查询。
              </div>
              <Popconfirm
                placement="top"
                title="确认要开通天气服务？"
                onConfirm={openedWeather}
                okText="确认"
                cancelText="取消"
              >
                <Button
                  type="primary"
                  className="btn"
                  loading={loading}
                >
                  立即开通
                </Button>
              </Popconfirm>
            </div>
          </div>
        </div>
        <div className="weather-view-content">
          <ContainerCard title="服务内容">
            <Descriptions
              layout="vertical"
              colon={false}
              column={5}
            >
              <Descriptions.Item label="地理信息">支持查询</Descriptions.Item>
              <Descriptions.Item label="天气预报">实时天气</Descriptions.Item>
              <Descriptions.Item label="天气预报">每小时预报(00-23小时)</Descriptions.Item>
              <Descriptions.Item label="天气预报">每日预报(未来7天)</Descriptions.Item>
              <Descriptions.Item label="分钟降水">未来两小时(每五分钟)</Descriptions.Item>
              <Descriptions.Item label="格点天气">实时天气</Descriptions.Item>
              {/* <Descriptions.Item label="格点天气">每小时预报(24-72小时)</Descriptions.Item> */}
              {/* <Descriptions.Item label="格点天气">每日预报(3-7天)</Descriptions.Item> */}
              <Descriptions.Item label="天气预警">支持查询</Descriptions.Item>
              <Descriptions.Item label="天气指数">支持查询</Descriptions.Item>
              <Descriptions.Item label="空气质量">支持查询</Descriptions.Item>
            </Descriptions>
          </ContainerCard>
          <ContainerCard title="服务使用">
            <ul className="use-steps">
              <li className="use-step">
                <p className="use-step-no">
                  <span>0</span>1
                </p>
                <p className="use-step-name">开通服务</p>
                <div className="use-step-icon">
                  <DaqIcon icon="&#xe8bd;"></DaqIcon>
                </div>
              </li>
              <li className="use-step">
                <p className="use-step-no">
                  <span>0</span>2
                </p>
                <p className="use-step-name">服务充值</p>
                <div className="use-step-icon">
                  <DaqIcon icon="&#xe602;"></DaqIcon>
                </div>
              </li>
              <li className="use-step">
                <p className="use-step-no">
                  <span>0</span>3
                </p>
                <p className="use-step-name">服务设置</p>
                <div className="use-step-icon">
                  <DaqIcon icon="&#xe627;"></DaqIcon>
                </div>
              </li>
            </ul>
            <ul className="use-info">
              <li>1.开通服务：点击立即开通即可，天气服务支持地区、景点天气服务查询；</li>
              <li>2.服务充值：天气服务支持包月、包年不限次数查询；</li>
              <li>
                3.服务设置：完成充值后，需设置地区或景点天气，设置成功后，业务系统端，将根据设置的天气，提供天气服务查询。
              </li>
            </ul>
          </ContainerCard>
        </div>
      </Scrollbars>
    </div>
  );
};

export default observer(WeatherView);
