import style from './index.module.less';
import WeatherBox from '@views/weather/components/weather-box';
import { IWeatherWarning } from '@interfaces/i-weather';
import WeatherEmpty from '@views/weather/components/weather-empty';

const WeatherWarning = (props: Partial<IWeatherWarning> & { noData?: boolean }) => {
  return (
    <WeatherBox
      title="天气预警"
      className={style.weatherWarning}
    >
      {props.noData ? (
        <WeatherEmpty></WeatherEmpty>
      ) : (
        <>
          <div className={style.warning}>
            {props.warningPic ? (
              <div className={style.warningImg}>
                <img
                  src={props.warningPic}
                  alt={props.typeName}
                />
              </div>
            ) : null}
            <div>
              <div className={style.title}>{props.title || '-'}</div>
              <div className={style.date}>发布日期：{props.pubTime || '-'}</div>
            </div>
          </div>
          {props.text ? (
            <>
              <div className={style.line}></div>
              <p className={style.warningText}>{props.text}</p>
            </>
          ) : null}
          {props.defenseGuide ? (
            <>
              <div className={style.line}></div>
              <p className={style.defenseGuide}>防御指南</p>
              <p
                className={style.defenseGuide}
                dangerouslySetInnerHTML={{ __html: props.defenseGuide }}
              ></p>
            </>
          ) : null}
        </>
      )}
    </WeatherBox>
  );
};

export default WeatherWarning;
