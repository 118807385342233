/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-25 10:40:30
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-11-14 17:20:26
 * @Description: 桌面，业务逻辑主文件
 */
import React, { useState } from 'react';
import './index.less';
import Desktop from '@components/desktop';
import TaskBar from '@components/taskbar';
import Background from '@components/background';
import AppGroupView from '@components/app-group-view';

import Login from '@views/login';
import StartMenu from '@views/start-menu';

import { useStore } from '@stores';
import { observer } from 'mobx-react-lite';
import UpdatePassword from '@views/index/components/update-password';
import MultipleApp from '@views/multiple-app';
import BindPhone from './components/bind-phone';
import WebsocketDom from './components/websocket-dom';

import SystemInit from '@views/system-init';
import {
  CollisionDetection,
  DndContext,
  MeasuringStrategy,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';

import { useEventEmitter, useMemoizedFn } from 'ahooks';
import { AppType, TurnPageDirection } from '@interfaces/enum';
import { isDockApp } from '@utils';
import { TRASH_ID } from '@utils/constant';
import TitleBar from '@components/title-bar';
import { createPortal } from 'react-dom';
import { IEventData } from '@interfaces/i-common';

const GROUPING_OFFSET = 15;

const Index: React.FC = (): JSX.Element => {
  const {
    userStore,
    systemStore,
    universalSettingStore,
    systemSettingStore,
    dragStore,
    appWindowStore,
  } = useStore();
  const { loaded, startMenuShow, multipleAppMenuShow, multipleAppObj, toggleStartMenu } =
    systemStore;
  const { isMasterSetupWallPager, wallPaper } = universalSettingStore;
  const { wallPaper: systemWallPaper } = systemSettingStore;
  const [initialized, setInitialized] = useState(window.systemInitialized);
  const deviceInfo = window?.electronAPI?.versions();

  const eventBus = useEventEmitter<IEventData>();
  systemStore.setEventBus(eventBus);

  // 开始菜单点击回调
  const handleStartMenu = () => {
    toggleStartMenu();
  };

  // 全局屏蔽默认右键菜单
  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const finishInit = () => {
    setInitialized(true);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // 解决点击事件与拖动事件冲突，拖动距离大于8才判定为拖动
        distance: 8,
      },
    }),
  );

  const collisionDetectionStrategy: CollisionDetection = useMemoizedFn((args) => {
    // 任务栏拖动
    if (isDockApp(dragStore.draggingId)) {
      // 任务栏app拖出任务栏取消固定，碰撞检测
      let rectIntersectionCollisions = rectIntersection({
        ...args,
        droppableContainers: args.droppableContainers.filter((container) => {
          return TRASH_ID === String(container.id);
        }),
      });
      if (rectIntersectionCollisions.length > 0) {
        return rectIntersectionCollisions;
      }

      // 任务栏app排序 碰撞检测
      rectIntersectionCollisions = rectIntersection({
        ...args,
        droppableContainers: args.droppableContainers.filter((container) => {
          return isDockApp(container.id) && container.id !== 'dockAppContext';
        }),
      });
      return rectIntersectionCollisions || [];
    } else if (dragStore.draggingId) {
      // 开始菜单拖动

      // 开始菜单app分组内拖动
      if (systemStore.targetAppGroup && systemStore.appGroupShow) {
        // 组容器边缘检查（碰撞到则表示拖出组）
        let rectIntersectionCollisions = rectIntersection({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) => {
            return container.id.toString().includes('group-container');
          }),
        });
        if (rectIntersectionCollisions.length > 0) {
          return rectIntersectionCollisions;
        }

        // 组内翻页容器检查（白色背景两侧边）
        rectIntersectionCollisions = rectIntersection({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) => {
            return [TurnPageDirection.groupPrev, TurnPageDirection.groupNext].includes(
              container.id as TurnPageDirection,
            );
          }),
        });
        // 碰撞到翻页容器则返回
        if (rectIntersectionCollisions.length > 0) {
          return rectIntersectionCollisions;
        }

        // 组内排序检查，精确监测本页的app碰撞
        const pageApps =
          systemStore.targetAppGroupObj?.openAppList.slice(
            (systemStore.appGroupPageIndex - 1) * systemStore.appGroupPageInfo.pageSize,
            systemStore.appGroupPageIndex * systemStore.appGroupPageInfo.pageSize,
          ) || [];
        const droppableContainers = args.droppableContainers.filter((container) =>
          pageApps.find((app) => app.appUnionId === container.id),
        );
        // console.log(
        //   args.active.id,
        //   args.active.rect.current.initial?.left,
        //   args.active.rect.current.translated?.left,
        // );
        // console.log(
        //   pageApps.map((app) => {
        //     const container = droppableContainers.find((item) => item.id === app.appUnionId);
        //     return `${app.appName}${app.appUnionId}:${container?.rect.current?.left}`;
        //   }),
        // );
        rectIntersectionCollisions = rectIntersection({
          ...args,
          collisionRect: {
            ...args.collisionRect,
            left: args.collisionRect.left,
            right: args.collisionRect.right,
          },
          droppableContainers: droppableContainers.filter(
            (item) => item.id !== dragStore.draggingId,
          ),
        });

        return rectIntersectionCollisions.length > 0 ? rectIntersectionCollisions : [];
      }

      // TODO 占时屏蔽开始菜单拖入任务栏
      // 开始菜单app拖进任务栏后排序 碰撞检测
      // let rectIntersectionCollisions = rectIntersection({
      //   ...args,
      //   droppableContainers: args.droppableContainers.filter((container) => {
      //     return isDockApp(container.id) && container.id !== 'dockAppContext';
      //   }),
      // });
      // if (rectIntersectionCollisions.length > 0) {
      //   dragStore.setStartMenuGroupingId('');
      //   dragStore.setStartMenuSortingId('');
      //   return rectIntersectionCollisions;
      // }

      // 开始菜单app拖进任务栏容器 碰撞检测
      // rectIntersectionCollisions = rectIntersection({
      //   ...args,
      //   droppableContainers: args.droppableContainers.filter((container) => {
      //     return container.id === 'dockAppContext';
      //   }),
      // });
      // if (rectIntersectionCollisions.length > 0) {
      //   dragStore.setStartMenuGroupingId('');
      //   dragStore.setStartMenuSortingId('');
      //   return rectIntersectionCollisions;
      // }

      // 开始菜单左右翻页，使用交叉碰撞来判断是否是碰撞
      let rectIntersectionCollisions = rectIntersection({
        ...args,
        droppableContainers: args.droppableContainers.filter((container) => {
          return [TurnPageDirection.prev, TurnPageDirection.next].includes(
            container.id as TurnPageDirection,
          );
        }),
      });
      // 碰撞到，则返回，处理翻页和删除置底
      if (rectIntersectionCollisions.length > 0) {
        // Cannot update a component (`EnableApps`) while rendering a different component (`DndContext2`). To locate the bad setState() call inside `DndContext2`
        setTimeout(() => {
          dragStore.setStartMenuGroupingId('');
        }, 0);
        dragStore.setStartMenuSortingId('');
        return rectIntersectionCollisions;
      }

      // 开始菜单app碰撞检测，精确监测本页的app碰撞
      const pageApps = systemStore.startMenuObj.slice(
        (systemStore.startMenuPageIndex - 1) * systemStore.startMenuPageInfo.pageSize,
        systemStore.startMenuPageIndex * systemStore.startMenuPageInfo.pageSize,
      );
      const droppableContainers = args.droppableContainers.filter((container) =>
        pageApps.find((app) => app.appUnionId === container.id),
      );
      // console.log(
      //   args.active.id,
      //   args.active.rect.current.initial?.left,
      //   args.active.rect.current.translated?.left,
      // );
      // console.log(
      //   pageApps.map((app) => {
      //     const container = droppableContainers.find((item) => item.id === app.appUnionId);
      //     return `${app.displayName}${app.appUnionId}:${container?.rect.current?.left}`;
      //   }),
      // );
      rectIntersectionCollisions = rectIntersection({
        ...args,
        collisionRect: {
          ...args.collisionRect,
          left: args.collisionRect.left,
          right: args.collisionRect.right,
        },
        droppableContainers: droppableContainers.filter((item) => item.id !== dragStore.draggingId),
      });

      if (rectIntersectionCollisions.length) {
        const overCollision = rectIntersectionCollisions[0];
        const overTop = overCollision?.data?.droppableContainer.rect.current.top;
        const overLeft = overCollision?.data?.droppableContainer.rect.current.left;
        // 排序中
        if (dragStore.startMenuSortingId === overCollision.id) {
          // console.log('排序中');
          // Cannot update a component (`EnableApps`) while rendering a different component (`DndContext2`). To locate the bad setState() call inside `DndContext2`
          setTimeout(() => {
            dragStore.setStartMenuGroupingId('');
          }, 0);
          return rectIntersectionCollisions;
        }
        // 如果正在分组的id等于over id，则表示在分组
        if (dragStore.startMenuGroupingId === overCollision.id) {
          // console.log('分组中');
          dragStore.setStartMenuSortingId('');
          // 不返回碰撞结果，防止触发排序动画
          return [];
        } else if (overCollision.id !== dragStore.draggingId) {
          const activeApp = systemStore.getAppByUnionId(dragStore.draggingId);
          // 相交时偏移不超过20px，则识别为分组
          if (
            activeApp?.appType !== AppType.group &&
            (Math.abs(overTop - args.collisionRect.top) <= GROUPING_OFFSET ||
              Math.abs(overLeft - args.collisionRect.left) <= GROUPING_OFFSET)
          ) {
            // console.log('触发分组');
            dragStore.setStartMenuSortingId('');
            // Cannot update a component (`EnableApps`) while rendering a different component (`DndContext2`). To locate the bad setState() call inside `DndContext2`
            setTimeout(() => {
              dragStore.setStartMenuGroupingId(String(overCollision.id));
            }, 0);
            // 不返回碰撞结果，防止触发排序动画
            return [];
          } else {
            // console.log('触发排序');
            // Cannot update a component (`EnableApps`) while rendering a different component (`DndContext2`). To locate the bad setState() call inside `DndContext2`
            setTimeout(() => {
              dragStore.setStartMenuGroupingId('');
            }, 0);
            dragStore.setStartMenuSortingId(String(overCollision.id));
            return rectIntersectionCollisions;
          }
        }
      }

      // Cannot update a component (`EnableApps`) while rendering a different component (`DndContext2`). To locate the bad setState() call inside `DndContext2`
      setTimeout(() => {
        dragStore.setStartMenuGroupingId('');
      }, 0);
      dragStore.setStartMenuSortingId('');
      return [];
    }

    return [];
  });

  return (
    <div
      className="index"
      onContextMenu={handleContextMenu}
    >
      {/* 此元素用于处理fixed拖动 定位原点问题，常驻的原因：每次拖动重新渲染元素会导致闪烁 */}
      <div id="useDraggableInPortal"></div>
      {/* 重新定义windows下 TitleBar */}
      {deviceInfo?.deviceType === 'win32' ? createPortal(<TitleBar />, document.body) : null}
      <Background wallPaper={isMasterSetupWallPager === 0 ? wallPaper : systemWallPaper} />
      {userStore.token && loaded ? (
        <>
          <DndContext
            id="drag-container"
            sensors={sensors}
            collisionDetection={collisionDetectionStrategy}
            modifiers={[restrictToWindowEdges]}
            measuring={{
              droppable: {
                strategy: MeasuringStrategy.Always,
                // 位置信息更新频率
                frequency: 250,
              },
            }}
            onDragStart={({ active }) => {
              // 统一处理开始菜单和任务栏的拖动信息 (碰撞算法中需要根据id来区分开始菜单、组、任务栏中的碰撞)
              const activeId = active.id as string;
              // 任务栏app拖动
              if (isDockApp(activeId)) {
                const index = appWindowStore.getDockAppIndex(activeId);
                if (index < 0) {
                  return;
                }

                // 开始拖动，记录拖动的id
                dragStore.setDragInfo({
                  id: activeId,
                  // 拖动任务栏app时pId字段无效
                  pId: '',
                  index,
                });
              } else {
                // 开始菜单app拖动
                let index = systemStore.getStartMenuTopLevelAppIndex(activeId);
                if (index < 0) {
                  // 可能在组内
                  const app = systemStore.getAppByUnionId(activeId);
                  if (!app || !app.pId) {
                    return;
                  }

                  // 组中的位置
                  index = systemStore.getGroupAppIndex(app.pId, activeId);
                  // 开始拖动，记录拖动的id
                  dragStore.setDragInfo({
                    id: activeId,
                    pId: app.pId,
                    index,
                  });
                } else {
                  // 开始拖动，记录拖动的id
                  dragStore.setDragInfo({
                    id: activeId,
                    pId: '',
                    index,
                  });
                }
              }
            }}
          >
            <StartMenu show={startMenuShow}></StartMenu>
            {/* 在组件里面直接使用group.openAppList || []去分页会无限触发分页，提到外面就不得了，不晓得原因 */}
            <AppGroupView
              group={systemStore.targetAppGroupObj}
              apps={systemStore.targetAppGroupObj?.openAppList || []}
              show={systemStore.appGroupShow}
            />
            <TaskBar handleStartMenu={() => handleStartMenu()} />
          </DndContext>

          <MultipleApp
            show={multipleAppMenuShow}
            app={multipleAppObj}
          />

          {/* 这个不是桌面，实际只有打开的app展示，整个index就是桌面 */}
          <Desktop />

          {/* 必须放desktop后面，层级高于desktop */}
          <UpdatePassword />
          <BindPhone />
        </>
      ) : (
        <>
          <Login />
          <SystemInit
            show={!initialized}
            finishInit={finishInit}
          />
        </>
      )}
      <WebsocketDom></WebsocketDom>
    </div>
  );
};
export default observer(Index);
