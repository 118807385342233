/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-15 18:27:44
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-13 22:38:28
 * @Description: 描述
 */
import React, { useState } from 'react';
import style from './user-info.module.less';
import avatar from '@assets/images/avatar.png';
import { useStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { message, Modal, Tooltip } from 'antd';
import SwitchAccount from './switch-account';
import { loadResource, loginByUsername } from '@api/api-common';
import { ISetting, ISystemSetting } from '@interfaces/i-api-request';
import classNames from 'classnames';
import DaqSoftTrack from '@utils/daqsoft-track';

// 点
const UserInfo: React.FC = (): JSX.Element => {
  const { userStore, systemStore, appWindowStore, universalSettingStore, systemSettingStore } =
    useStore();
  const [switchAccount, setSwitchAccount] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const changeUser = async (username: string) => {
    try {
      setDisabled(true);
      let setting: ISetting;
      let SystemSetting: ISystemSetting;
      const { data } = await loginByUsername(username);
      await userStore.setToken(data.token || '');
      await userStore.setUser({
        ...(data.userVo ?? {}),
        authenticationName: data.authenticationName,
        roleName: data.roleName,
        weatherKey: data.weatherKey,
      });
      await userStore.setUserList(data.userList);
      await userStore.setFirstLogin(data.first);
      DaqSoftTrack.setUserID(data?.userVo?.userId ?? '');
      DaqSoftTrack.track({
        firstEventType: '登录',
        secondEventType: '切换账号登录',
        thirdEventType: username,
        userId: data?.userVo?.userId,
        status: 1,
      });
      loadResource().then((result) => {
        setting = result.data.settingVo ?? {};
        message.success('切换成功，即将关闭所有窗口进行初始化！', 2).then(() => {
          // 设置用户权限
          userStore.setPermissions(result.data?.loginAfterRolePermissionVo);
          // 初始化开始菜单数据
          systemStore.initStartMenu(result.data.startAppRelationList);
          // 保存任务栏固定app信息 res.taskbarList
          universalSettingStore.setInitSetting(setting, 'init');
          appWindowStore.initDockApps(result.data.taskbarList);
          // 保存系统设置
          systemSettingStore.setSystemSetting(SystemSetting);
          setDisabled(false);
        });
      });
    } catch (error) {
      DaqSoftTrack.track({
        firstEventType: '登录',
        secondEventType: '切换账号登录',
        thirdEventType: username,
        failReason: String(error),
        status: 0,
      });
      setDisabled(false);
    }
  };
  return (
    <div className={style.userInfo}>
      <div
        className={classNames(
          style.avatar,
          (userStore?.userList?.length ?? 0) === 0 ? '' : style.switch,
        )}
        onClick={() => {
          if ((userStore?.userList?.length ?? 0) === 0) {
            return;
          }
          setSwitchAccount(true);
        }}
      >
        {(userStore?.userList?.length ?? 0) === 0 ? null : (
          <div className={style.curvedCorner}></div>
        )}
        <img
          className={style.avatarSrc}
          alt={userStore.username}
          src={userStore.avatar || avatar}
        />
      </div>
      <div className={style.phone}>{userStore.maskPhone}</div>
      {userStore.roleName && (
        <div className={style.role}>
          <Tooltip
            title={userStore.roleName}
            mouseEnterDelay={1.5}
          >
            {userStore.roleName}
          </Tooltip>
        </div>
      )}
      <div className={style.authentication}>
        <Tooltip
          title={userStore.authenticationName}
          mouseEnterDelay={1.5}
        >
          {userStore.authenticationName}
        </Tooltip>
      </div>
      <Modal
        title="切换账号"
        width={785}
        destroyOnClose
        maskClosable={false}
        closable={false}
        open={switchAccount}
        footer={null}
      >
        <SwitchAccount
          disabled={disabled}
          username={userStore.username}
          userList={userStore.userList ?? []}
          onCancel={() => {
            setSwitchAccount(false);
          }}
          onSwitch={(name) => {
            if (userStore.username === name) {
              return;
            }
            changeUser(name);
          }}
        />
      </Modal>
    </div>
  );
};

export default observer(UserInfo);
