import { message, Modal } from 'antd';
import OperateMessage from '@components/operate-message';
import Password from '@components/password';
import { useState } from 'react';
import { useDebounceFn } from 'ahooks';
import { ISecurityForm } from '@interfaces/i-user-center';
import api from '@api';
import { useImmer } from 'use-immer';
import { useStore } from '@stores';
import InfoGroup from './info-group';
import './update-password.less';
import { observer } from 'mobx-react-lite';

// 修改密码
const UpdatePassword = () => {
  const [resultInfo, setResultInfo] = useImmer({
    message: '',
    type: 'info',
  });
  const [submitting, setSubmitting] = useState(false);
  const { userStore } = useStore();

  const { run: setPassword } = useDebounceFn(
    ({ password, oldPassword }: ISecurityForm) => {
      if (password && oldPassword && !submitting) {
        setSubmitting(true);
        api.userCenter
          .setPassword({
            password,
            oldPassword,
          })
          .then(() => {
            userStore.setFirstLogin(false);
            message.success('修改密码成功，须重新登录！');

            userStore.logout();
          })
          .catch((error) => {
            setResultInfo((state) => {
              state.message = error.message;
              state.type = 'error';
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    { wait: 300 },
  );

  return (
    <>
      <Modal
        className="os-dialog info-form"
        title="修改密码"
        destroyOnClose
        closable={false}
        maskClosable={false}
        keyboard={false}
        open={userStore.first}
        footer={null}
      >
        {resultInfo.message ? (
          <OperateMessage
            message={resultInfo.message}
            type={resultInfo.type}
          />
        ) : null}
        <InfoGroup className="info-group" />
        <Password
          submitting={submitting}
          onOk={setPassword}
        ></Password>
      </Modal>
    </>
  );
};

export default observer(UpdatePassword);
