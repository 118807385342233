import style from './index.module.less';
import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface WeatherBoxProps {
  title?: string;
  info?: string;
  className?: string;
}
export type WeatherBoxClassProps = PropsWithChildren<WeatherBoxProps>;

const Overview: React.FC<WeatherBoxClassProps> = (props): JSX.Element => {
  return (
    <div className={classNames(style.weatherBox, props.className || '')}>
      {props.title ? <h3 className={style.weatherBoxTitle}>{props.title}</h3> : null}
      <div className={style.weatherBoxContent}>
        {props.info ? (
          <div
            className={style.weatherBoxInfo}
            title={props.info}
          >
            {props.info}
          </div>
        ) : null}
        {props.children}
      </div>
    </div>
  );
};

export default Overview;
