import { useEffect } from 'react';
import { useImmer } from 'use-immer';
import { observer } from 'mobx-react-lite';
import { Tooltip } from 'antd';
import api from '@api';
import './index.less';
import logo from '@assets/images/logo.png';
import logoSecond from '@assets/images/logo-second.png';
import FrostedGlass from '@components/frosted-glass';
import DaqIcon from '@components/daq-icon';
import { ILoginRes } from '@interfaces/i-api-response';
import { useStore } from '@stores';
import appWindowStore from '@stores/app-window/app-window-store';
import { ISetting, ISystemSetting } from '@interfaces/i-api-request';
import ImageCaptcha from '@views/login/components/image-captcha';
import { VerifyStatus } from '@interfaces/enum';
import NormalLogin from '@views/login/components/normal-login';
import RememberLogin from '@views/login/components/remember-login';
import { preloadImg } from '@utils';
import moment from 'moment';
import DaqSoftTrack from '@utils/daqsoft-track';

const Login = () => {
  let loadingTimer = 0;

  const [state, setState] = useImmer({
    verify: VerifyStatus.unverified, // unverify verifying verified
    account: '',
    time: '',
    date: '',
    loading: false,
    loadingPercent: 0,
  });
  // TODO 引入其他store，保存初始化信息
  const { userStore, universalSettingStore, systemStore, systemSettingStore } = useStore();

  useEffect(() => {
    const timer = setInterval(() => {
      setState((state) => {
        const today = new Date();
        state.time = `${moment(today).format('HH:mm')}`;
        state.date = `${moment(today).format('YYYY年MM月DD日 dddd')}`;
      });
    });

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerClose = () => {
    // TODO 弹窗
    // TODO 只支持点击链接自动打开的，和window.open打开的window
    window.close();
  };

  // 点击登录时回调
  const loadResourceStart = (account: string) => {
    clearInterval(loadingTimer);
    setState((state) => {
      state.account = account;
      state.loading = true;
      state.loadingPercent = 0;
    });

    // 开始模拟登录进度
    loadingTimer = window.setInterval(() => {
      setState((state) => {
        if (++state.loadingPercent > 90) {
          // 如果处理到大于90的时候都还没登录完成，就不动了
          clearInterval(loadingTimer);
        }
      });
    }, 10);
  };

  // 登录失败时回调
  const loginFailed = (account: string, message?: string) => {
    DaqSoftTrack.track({
      firstEventType: '登录',
      secondEventType: '账号密码登录',
      thirdEventType: account,
      failReason: message,
      status: 0,
    });
    setState((state) => {
      state.verify = VerifyStatus.unverified;
      state.account = '';
      state.loading = false;
      state.loadingPercent = 0;
    });
  };

  // 登录成功-loaded
  const loaded = async (account: string, setting: ISetting, SystemSetting: ISystemSetting) => {
    // 清除登录计时器，处理登录加载状态为100%
    await clearInterval(loadingTimer);
    // 加载进度设置为100
    await setState((state) => {
      state.loadingPercent = 100;
    });
    // 保存系统设置
    await systemSettingStore.setSystemSetting(SystemSetting);
    // 保存设置信息
    // 全局设置信息字段 res.settingVo
    await universalSettingStore.setInitSetting(setting ?? {}, 'init');
    // 给显示100一个露脸的机会
    setTimeout(() => {
      setState((state) => {
        state.loading = false;
        systemStore.setLoaded(true);
        // 加载完成才设置账号记录，否则普通登录时会展示记住用户后的loading样式
        userStore.setAccount(account);
      });
    }, 100);
  };

  // 登录成功时回调
  const loginSuccess = (account: string, res: ILoginRes) => {
    setState((state) => {
      state.verify = VerifyStatus.unverified;
    });
    DaqSoftTrack.setUserID(res.userVo.userId ?? '');
    DaqSoftTrack.track({
      firstEventType: '登录',
      secondEventType: '账号密码登录',
      thirdEventType: account,
      userId: res.userVo.userId,
      status: 1,
    });
    // 保存用户信息
    userStore.setToken(res.token || '');
    userStore.setUser({
      ...(res.userVo ?? {}),
      authenticationName: res.authenticationName,
      roleName: res.roleName,
      weatherKey: res.weatherKey,
    });
    userStore.setUserList(res.userList);
    userStore.setFirstLogin(res.first);

    // 触发进度条
    loadResourceStart(account);
    const startTimes = Date.now();
    let setting: ISetting;
    let SystemSetting: ISystemSetting;

    // 加载资源
    api
      .loadResource()
      .then((result) => {
        setting = result.data.settingVo ?? {};
        SystemSetting = result.data.generalSettingVo;
        universalSettingStore.loadingDock(true);
        // 设置用户权限
        userStore.setPermissions(result.data?.loginAfterRolePermissionVo);
        userStore.setDeployEnvironment(result.data?.environmentConfigVo?.deployEnvironment);
        // 初始化开始菜单数据
        systemStore.initStartMenu(result.data.startAppRelationList);
        // 保存任务栏固定app信息 res.taskbarList
        appWindowStore.initDockApps(result.data.taskbarList);
      })
      .finally(() => {
        // 加载成功或失败都进入页面
        const endTimes = Date.now();
        // 预加载图片成功后才进入桌面，未配置桌面图片就走定时器
        const imgList: string[] = [];
        if (SystemSetting?.loginLogo) {
          imgList.push(SystemSetting.loginLogo);
        }
        if (SystemSetting?.startMenuButton) {
          imgList.push(SystemSetting.startMenuButton);
        }
        if (SystemSetting?.wallPaper) {
          imgList.push(SystemSetting.wallPaper);
        }
        if (setting?.wallPaper) {
          imgList.push(setting.wallPaper);
        }

        if (imgList.length > 0) {
          preloadImg(imgList).then(() => {
            loaded(account, setting, SystemSetting);
          });
        } else {
          // 害怕接口太快，等待至少2秒
          const waitTime = 2000 - (endTimes - startTimes);
          setTimeout(
            () => {
              loaded(account, setting, SystemSetting);
            },
            waitTime > 0 ? waitTime : 0,
          );
        }
      });
  };

  const prevLogin = () => {
    setState((state) => {
      state.verify = VerifyStatus.verifying;
    });
  };

  const verifySuccess = () => {
    setState((state) => {
      state.verify = VerifyStatus.verified;
    });
  };

  const closeCaptcha = () => {
    setState((state) => {
      state.verify = VerifyStatus.unverified;
    });
  };

  return (
    <FrostedGlass className="login-wrapper">
      <div className="slogan">
        欢迎使用 <span>·</span>
        {!systemSettingStore.systemName
          ? '中科大旗文旅产业生态操作系统'
          : systemSettingStore.systemName}
      </div>
      {userStore.account ? (
        <RememberLogin
          loading={state.loading}
          loadingPercent={state.loadingPercent}
          account={state.account}
          verify={state.verify}
          prevLogin={prevLogin}
          loginFailed={loginFailed}
          loginSuccess={loginSuccess}
          loginLogo={
            systemSettingStore.secondLoginLogo ? systemSettingStore.secondLoginLogo : logoSecond
          }
        ></RememberLogin>
      ) : (
        <NormalLogin
          loading={state.loading}
          loadingPercent={state.loadingPercent}
          account={state.account}
          verify={state.verify}
          prevLogin={prevLogin}
          loginFailed={loginFailed}
          loginSuccess={loginSuccess}
          loginLogo={systemSettingStore.loginLogo ? systemSettingStore.loginLogo : logo}
        ></NormalLogin>
      )}
      <div className="footer">
        <div className="watch">
          <p className="watch-time">{state.time}</p>
          <p className="watch-date">{state.date}</p>
        </div>
        <Tooltip title="关闭">
          <div
            aria-hidden={true}
            className="close"
            data-track-info={JSON.stringify({
              firstEventType: '关闭系统',
              secondEventType: '登录页面关闭系统',
              status: 1,
            })}
            onClick={handlerClose}
          >
            <DaqIcon icon="&#xe606;"></DaqIcon>
          </div>
        </Tooltip>
      </div>
      {state.verify === VerifyStatus.verifying ? (
        <ImageCaptcha
          show={state.verify === VerifyStatus.verifying}
          onSuccess={verifySuccess}
          onClose={closeCaptcha}
        ></ImageCaptcha>
      ) : null}
    </FrostedGlass>
  );
};

export default observer(Login);
