/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-06 14:05:19
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-06 14:25:31
 * @Description: 员工列表
 */
import {
  delStaffApi,
  enableOrDisableApi,
  getDetailApi,
  getListPagingApi,
  resetPwdApi,
} from '@api/api-staff';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DaqIcon from '@components/daq-icon';
import useDebounceUpdateEffect from '@hooks/use-debounce-update-effect';
import { IStaffListPagingParams } from '@interfaces/i-api-request';
import { IStaffListPagingRes } from '@interfaces/i-api-response';
import { useDebounceFn, useMemoizedFn, useMount } from 'ahooks';
import { Avatar, Button, message, Modal } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useImmer } from 'use-immer';
import ListContent from './components/list-content';
import ListDetail from './components/list-detail';
import ListFilter from './components/list-filter';
import ListModify from './components/list-modify';
import './index.less';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { IMProps } from '@interfaces/i-staff-manage';
import DaqSoftTrack from '@utils/daqsoft-track';

interface IProps {
  goAddRole?: () => void;
  event$: EventEmitter<void>;
}
const StaffList: React.FC<IProps> = ({ goAddRole, event$ }): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [add, setAdd] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [MTitle, setMTitle] = useState('重置密码');
  const [clipboard, setClipboard] = useState('');
  const [resetInfo, setResetInfo] = useState<IStaffListPagingRes | null>(null);
  const [detail, setDetail] = useState<IStaffListPagingRes | null>(null);
  const [dataList, setDataList] = useState<IStaffListPagingRes[]>([]);
  const [state, setState] = useImmer<Partial<IStaffListPagingParams>>({
    startDateTime: '',
    endDateTime: '',
    realName: '',
    userStatus: '',
    keyword: '',
    pageNo: 1,
    pageSize: 10,
    pageTotal: 1,
    recordCount: 0,
  });

  event$.useSubscription((value: unknown) => {
    if (value !== 'staff') {
      return;
    }
    setAdd(false);
    setIsDetail(false);
  });

  const successTrack = (secondEventType: string, thirdEventType: string) => {
    DaqSoftTrack.track({
      firstEventType: '员工管理',
      secondEventType,
      thirdEventType,
      status: 1,
    });
  };

  const errorTrack = (secondEventType: string, thirdEventType: string, failReason: string) => {
    DaqSoftTrack.track({
      firstEventType: '员工管理',
      secondEventType,
      thirdEventType,
      failReason,
      status: 1,
    });
  };

  // 获取角色列表数据
  const getListPaging = useMemoizedFn(
    async (params: Partial<IStaffListPagingParams>, isTrack = false) => {
      try {
        const { data, page } = await getListPagingApi({
          ...params,
          pageSize: state.pageSize,
        });
        const arr = data ?? [];

        let List: IStaffListPagingRes[] = [];
        if (params.pageNo !== 1) {
          List = [...dataList];
        }

        setDataList([...List, ...arr]);
        setState((_) => {
          _.pageNo = page?.pageNo;
          _.pageTotal = page?.pageTotal;
          _.recordCount = page?.recordCount;
        });
        if (isTrack) {
          successTrack(
            '员工列表',
            String([
              `${params.endDateTime !== '' ? '日期' : ''}${params.keyword !== '' ? '手机号' : ''}${
                params.realName !== '' ? '员工姓名' : ''
              }`,
            ]),
          );
        }
      } catch (error) {
        console.error(error);
        if (isTrack) {
          errorTrack(
            '员工列表',
            String([
              `${params.endDateTime !== '' ? '日期' : ''}${params.keyword !== '' ? '手机号' : ''}${
                params.realName !== '' ? '员工姓名' : ''
              }`,
            ]),
            String(error),
          );
        }
      }
      setIsLoading(false);
    },
  );

  // 获取角色详情
  const getDetail = async (params: string, type: string) => {
    try {
      const { data } = await getDetailApi(params);
      setDetail(data);
      if (type === 'revise') {
        setAdd(true);
        setIsDetail(false);
      } else {
        setAdd(false);
        setIsDetail(true);
      }
      successTrack('员工管理', '查看');
    } catch (error) {
      console.error(error);
      errorTrack('员工管理', '查看', String(error));
    }
  };
  // 删除
  const delStaff = async (params: string, type = 'content') => {
    try {
      await delStaffApi(params);
      message.success('删除成功！');
      successTrack('员工管理', '删除');
      setDataList([]);
      await getListPaging({
        ...state,
        pageNo: 1,
      });
      if (type === 'detail') {
        setIsDetail(false);
      }
    } catch (error) {
      console.error(error);
      errorTrack('员工管理', '删除', String(error));
    }
  };
  // 启用禁用
  const enableOrDisable = async (userId: string, userStatus: number) => {
    const status = userStatus === 1 ? '启用' : '禁用';
    try {
      await enableOrDisableApi({
        userId,
        userStatus,
      });
      await getListPaging({
        ...state,
        pageNo: 1,
      });
      message.success(`${status}成功！`);
      successTrack('员工管理', status);
    } catch (error) {
      console.error(error);
      errorTrack('员工管理', status, String(error));
    }
  };
  const onResetPwd = async (info: IStaffListPagingRes) => {
    try {
      const { data } = await resetPwdApi(info.userId);
      setResetInfo(info);
      setMTitle('重置密码');
      setClipboard(`
      员工账号「${info?.phone}」，已重置密码。随机密码${data}，请妥善管理密码，24小时内未登录将自动失效`);
      setIsModalVisible(true);
      successTrack('员工管理', '重置密码');
    } catch (error) {
      console.error(error);
      errorTrack('员工管理', '重置密码', String(error));
    }
  };

  const { run } = useDebounceFn((value) => {
    const startDateTime = value?.time ? moment(value?.time?.[0]).format('YYYY-MM-DD') : '';
    const endDateTime = value?.time ? moment(value?.time?.[1]).format('YYYY-MM-DD') : '';
    setState((_) => {
      _.startDateTime = startDateTime;
      _.endDateTime = endDateTime;
      _.realName = value.realName;
      _.keyword = value.keyword;
      _.userStatus = value.userStatus;
    });
    setIsLoading(true);

    const params = { ...value };
    delete params.time;
    setDataList([]);
    getListPaging(
      {
        ...params,
        startDateTime,
        endDateTime,
        pageNo: 1,
      },
      true,
    );
  });

  useMount(() => {
    getListPaging({ pageNo: 1 });
  });

  useDebounceUpdateEffect(() => {
    if (add || isDetail) {
      return;
    }
    setDetail(null);
  }, [isDetail, add]);

  return (
    <div className="staff-list">
      <div className={`list-box box-${!(add || isDetail) ? 'show' : 'hide'}`}>
        <ListFilter
          isLoading={isLoading}
          onFinish={run}
          onAdd={() => {
            setAdd(true);
          }}
        />
        <ListContent
          isLoading={isLoading}
          dataList={dataList}
          pageNo={state?.pageNo ?? 1}
          pageTotal={state?.pageTotal ?? 1}
          recordCount={state?.recordCount ?? 1}
          onNext={(pageNo) => {
            getListPaging(
              {
                ...state,
                pageNo,
              },
              true,
            );
          }}
          onRevise={(id: string) => {
            getDetail(id, 'revise');
          }}
          onDetail={(id: string) => {
            getDetail(id, 'detail');
          }}
          onRemove={(id: string) => {
            delStaff(id);
          }}
          onState={enableOrDisable}
          onResetPwd={onResetPwd}
        />
      </div>

      {add && (
        <ListModify
          detail={detail}
          className={`add-${add ? 'show' : 'hide'}`}
          onBack={async (info: Partial<IMProps>) => {
            if (info?.type === 'add') {
              setMTitle('添加员工');
              setClipboard(`
              员工账号「${info?.phone}」，已添加成功。随机密码${info?.password}，请妥善管理密码，24小时内未登录将自动失效`);
              setIsModalVisible(true);
            }
            setAdd(false);
            if (info?.type !== 'close') {
              setDataList([]);
              await getListPaging({
                ...state,
                pageNo: 1,
              });
            }
          }}
          goAddRole={goAddRole}
        />
      )}
      {isDetail && (
        <ListDetail
          detail={detail}
          className={`add-${isDetail ? 'show' : 'hide'}`}
          onBack={() => {
            setIsDetail(false);
          }}
          onRemove={(id: string) => {
            delStaff(id, 'detail');
          }}
          onRevise={(id: string) => {
            getDetail(id, 'revise');
          }}
        />
      )}
      <Modal
        transitionName="ant-fade"
        title={MTitle}
        width={320}
        destroyOnClose
        closable={false}
        open={isModalVisible}
        footer={null}
      >
        <Avatar
          src={resetInfo?.headSculpture ?? ''}
          size={74}
          icon={<DaqIcon icon="&#xe870;" />}
        />
        <div
          className="staff-modal-message"
          id="text"
        >
          {clipboard}
        </div>
        <CopyToClipboard text={clipboard}>
          <Button
            type="primary"
            style={{ marginBottom: '20px' }}
            block
            onClick={() => {
              message.success('复制成功！');
              setIsModalVisible(false);
            }}
          >
            复制信息
          </Button>
        </CopyToClipboard>
      </Modal>
    </div>
  );
};

export default StaffList;
