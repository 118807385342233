/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-07 19:16:01
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-06-07 19:28:05
 * @Description: color-list 组件
 */
import React, { ReactNode } from 'react';
import './color-list.less';
import classNames from 'classnames';

const color = {};
interface IProps {
  onColorChange: (value: Partial<typeof color>) => void;
  activeColor: string;
  colorList: string[];
}
const ColorList: React.FC<IProps> = ({
  onColorChange,
  activeColor,
  colorList,
}: IProps): JSX.Element => {
  return (
    <ul
      aria-hidden="true"
      className="color-list"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        const target = e.target as HTMLElement;
        const color = target.dataset.color;
        if (!color) {
          return;
        }
        onColorChange({ primaryColor: color });
      }}
    >
      {colorList.map((item): ReactNode => {
        return (
          <li
            className={classNames(`color-${item}`, { active: activeColor === `#${item}` })}
            data-color={`#${item}`}
            key={item}
          ></li>
        );
      })}
    </ul>
  );
};
export default React.memo(ColorList);
