/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-25 10:33:02
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-09-19 17:03:19
 * @Description: 公共输出文件
 */
import appWindowStore from './app-window/app-window-store';
import universalSettingStore from './universal-setting/universal-setting-store';
import systemSettingStore from './system-setting/system-setting-store';
import userStore from './user/user-store';
import systemStore from './system/system-store';
import dragStore from './drag/drag-store';

export function createStore() {
  return {
    userStore,
    universalSettingStore,
    systemSettingStore,
    appWindowStore,
    systemStore,
    dragStore,
  };
}

export const store = createStore();

export type TStore = ReturnType<typeof createStore>;
