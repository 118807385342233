/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2023-02-07 14:47:52
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-22 15:45:30
 * @Description: 配置文件
 */
import { TooltipPlacement } from 'antd/lib/tooltip';

export const weatherApp = {
  appId: '5',
  appLogo: 'images/weather-service.png',
  appName: '天气服务',
  appType: 1,
  appUnionId: '51',
  code: 'weatherService',
  displayName: '天气服务',
  relationId: '',
};

export interface IProps {
  placement?: TooltipPlacement;
}

export enum WeatherAppStatus {
  opened = 1,
  notOpen,
  expired,
  unsetLocation,
  changeLocation,
}

export interface IWeather {
  flag: boolean;
  iconPic: string;
  temp: number | string;
  msg: string;
  text: string;
  status: WeatherAppStatus;
  weatherKey: string;
  unicode: string;
}

export const initWeather = {
  flag: false,
  iconPic: '',
  temp: 0,
  status: WeatherAppStatus.notOpen,
  msg: '',
  text: '',
  weatherKey: '',
  unicode: '&#xe8c0;',
};
