/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-31 09:42:39
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-11 11:00:18
 * @Description: 背景组件
 */
import React from 'react';
import './index.less';
import desktop from '@assets/images/desktop/desktop.jpg';
interface IProps {
  wallPaper?: string;
}
const Background: React.FC<IProps> = ({ wallPaper }) => {
  return (
    <div
      className="background"
      style={{ backgroundImage: `url(${!wallPaper ? desktop : wallPaper})` }}
    ></div>
  );
};

export default Background;
