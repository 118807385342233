/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-08-23 10:00:24
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-11-15 09:59:39
 * @Description: 描述
 */
import './normal-login.less';
import LoginLoading from './login-loading';
import LoginForm from './login-form';
import classNames from 'classnames';
import { ILoginRes } from '@interfaces/i-api-response';

interface INormalLoginProps {
  loading: boolean;
  loadingPercent: number;
  account: string;
  verify: number;
  prevLogin(): void;
  loginFailed(account: string, message?: string): void;
  loginSuccess(account: string, res: ILoginRes): void;
  loginLogo: string;
}

const NormalLogin = (props: INormalLoginProps) => {
  return (
    <div className="login-box">
      <div className="logo-wrapper">
        <img
          className="logo"
          src={props.loginLogo}
          alt="大旗操作系统"
        />
      </div>
      <LoginLoading
        className={classNames({ hide: !props.loading })}
        account={props.account}
        percent={props.loadingPercent}
      />
      <LoginForm
        className={classNames({ hide: props.loading })}
        verify={props.verify}
        prevLogin={props.prevLogin}
        loginFailed={props.loginFailed}
        loginSuccess={props.loginSuccess}
      />
    </div>
  );
};

export default NormalLogin;
