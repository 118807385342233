/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-13 13:46:56
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-01 19:24:11
 * @Description: 描述
 */
import { AlignType } from '@interfaces/i-universal-setting';
import AppInfoStore from '@stores/app-window/app-store';
import React from 'react';
import './index.less';

export interface MenuProps {
  position: AlignType;
  showMenu: string;
  appInfo: AppInfoStore;
  MenuClick: (appInfo: AppInfoStore, type: string) => void;
}

const ContextMenu: React.FC<MenuProps> = ({ position, showMenu, appInfo, MenuClick }) => {
  const styles = { display: 'none' };
  return (
    <div
      aria-hidden
      id="context-menu"
      className={position}
      style={showMenu !== appInfo.appUnionId ? styles : {}}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ul>
        <li
          aria-hidden
          onClick={(e) => {
            e.stopPropagation();
            MenuClick(appInfo, 'isOpen');
          }}
        >
          {appInfo.open ? '关闭' : '打开'}应用
        </li>
        <li
          aria-hidden
          onClick={(e) => {
            e.stopPropagation();
            MenuClick(appInfo, 'isFixed');
          }}
        >
          {appInfo.fixed ? '取消' : '固定'}置底显示
        </li>
      </ul>
      <div className="menu-arrow">
        <span className="menu-arrow-content"></span>
      </div>
    </div>
  );
};

export default ContextMenu;
