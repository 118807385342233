/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-10 10:30:45
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-11-29 15:58:52
 * @Description: 应用程序窗口-工具栏-tab组件
 */
import React from 'react';
import AppInfoStore from '@stores/app-window/app-store';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import DaqIcon from '@components/daq-icon';
import { ErrorImg, showNameFN } from '@utils';
import { Tooltip } from 'antd';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
interface IToolTab {
  activeAppId: string;
  appInfo: AppInfoStore;
  closeApp: (value: AppInfoStore) => void;
  changeApp: (id: string) => void;
  index: number;
  showName: number;
}

const ToolTab: React.FC<IToolTab> = ({
  activeAppId,
  appInfo,
  changeApp,
  closeApp,
  showName,
}): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: appInfo.appUnionId,
  });
  const styles = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 10 : undefined,
    cursor: isDragging ? 'grabbing' : '',
  };
  return (
    <div
      className={classNames('tab', { active: appInfo?.appUnionId === activeAppId })}
      key={appInfo?.appUnionId}
      onClick={(e: React.SyntheticEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        changeApp(appInfo?.appUnionId);
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={styles}
    >
      <div className="content">
        <Tooltip
          title={showNameFN(appInfo, showName)}
          {...(isDragging ? { open: false } : {})}
          mouseEnterDelay={2}
        >
          <img
            className="logo"
            src={appInfo.appLogo}
            alt=""
            onError={ErrorImg}
          />
          <span className="name">{showNameFN(appInfo, showName)}</span>
        </Tooltip>
      </div>
      <div
        className="close"
        onClick={(e: React.SyntheticEvent<HTMLDivElement>) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          closeApp(appInfo);
        }}
      >
        <DaqIcon icon="&#xe68f;" />
      </div>
    </div>
  );
};

export default observer(ToolTab);
