import style from './steps.module.less';
import classNames from 'classnames';
import DaqIcon from '@components/daq-icon';
import { ISteps } from '@interfaces/i-user-center';

//
const Steps = ({ activeIndex, steps }: { activeIndex: number; steps: ISteps[] }) => {
  return (
    <ul className={style.steps}>
      {steps.map((step, index) => {
        return (
          <li
            key={step.id}
            className={classNames(style.step, index <= activeIndex ? style.active : '')}
          >
            <span className={style.label}>{step.label}</span>
            <div className={style.status}>
              {index <= activeIndex ? (
                <DaqIcon icon="&#xe8bd;" />
              ) : (
                <div className={style.statusPoint}></div>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default Steps;
