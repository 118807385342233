/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-06 09:45:41
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-07 18:04:13
 * @Description: 员工管理
 */
import AppMenu from '@components/app-menu';
import AppSideBar from '@components/app-side-bar';
import { IDataItem } from '@interfaces/i-common';
import StaffList from './staff-list';
import RoleList from './role-list';
import React, { useState } from 'react';
import './index.less';
import { useEventEmitter } from 'ahooks';

const StaffManage: React.FC = (): JSX.Element => {
  const [activeId, setActiveId] = useState('staff');
  const event$ = useEventEmitter();
  const menus: IDataItem[] = [
    {
      id: 'staff',
      label: '员工设置',
      icon: '&#xeb83;',
      color: '#02c8fd',
    },
    {
      id: 'role',
      label: '角色设置',
      icon: '&#xeb84;',
      color: '#ffb400',
    },
  ];

  const handleClick = (menu: IDataItem) => {
    setActiveId(menu.id);
    event$.emit(menu.id as unknown as void);
  };
  return (
    <div className="staff-manage">
      <AppSideBar>
        <AppMenu
          menus={menus}
          activeId={activeId}
          onClick={handleClick}
        ></AppMenu>
      </AppSideBar>
      <div className="staff-manage-content">
        {activeId === 'staff' ? (
          <StaffList
            goAddRole={() => {
              setActiveId('role');
            }}
            event$={event$}
          />
        ) : (
          // <RoleList event$={event$} />
          <RoleList />
        )}
      </div>
    </div>
  );
};

export default StaffManage;
