import FrostedGlass from '@components/frosted-glass';
import { useMemoizedFn } from 'ahooks';
import classNames from 'classnames';
import style from './index.module.less';
import { useImmer } from 'use-immer';
import React, { PropsWithChildren } from 'react';
import { MouseButton } from '@interfaces/enum';
import desktop from '@assets/images/desktop/desktop.jpg';

interface IBackgroundLayerProps {
  className?: string;
  show: boolean;
  wallPaper?: string;
  style?: React.CSSProperties;
  onClick(e: React.MouseEvent): void;
}

function BackgroundLayer(props: PropsWithChildren<IBackgroundLayerProps>) {
  // 左键按下后（还没触发Up事件），如果鼠标移动了，说明在划选，不认为是在点击
  const [state, setState] = useImmer({
    // 左键按下标志
    mouseLeftDown: false,
    mouseMove: false,
  });

  // 处理点击空白处隐藏弹层
  const handleClose = useMemoizedFn((e: React.MouseEvent) => {
    // 忽略鼠标的长按选择事件
    if (state.mouseLeftDown && state.mouseMove) {
      setState((state) => {
        state.mouseLeftDown = false;
        state.mouseMove = false;
      });
      return;
    } else {
      setState((state) => {
        state.mouseLeftDown = false;
        state.mouseMove = false;
      });
    }

    props.onClick(e);
  });

  const mouseLeftDown = useMemoizedFn((e: React.MouseEvent) => {
    // 只管左键点击
    if (e.button !== MouseButton.left) {
      return;
    }
    setState((state) => {
      state.mouseLeftDown = true;
      state.mouseMove = false;
    });
  });

  const mouseMove = useMemoizedFn(() => {
    if (state.mouseLeftDown) {
      setState((state) => {
        state.mouseMove = true;
      });
    } else {
      setState((state) => {
        state.mouseMove = false;
      });
    }
  });

  return (
    <>
      <div
        aria-hidden={true}
        className={classNames(
          style.backgroundLayer,
          props.show ? 'show' : '',
          props.className || '',
        )}
        style={{
          backgroundImage: `url(${!props.wallPaper ? desktop : props.wallPaper})`,
          ...props.style,
        }}
        onMouseDown={mouseLeftDown}
        onMouseMove={mouseMove}
        onClick={handleClose}
      >
        <FrostedGlass className={style.frostedGlass}></FrostedGlass>
        {props.children}
      </div>
    </>
  );
}

export default BackgroundLayer;
