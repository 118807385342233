/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-10-13 14:56:24
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-06 16:57:17
 * @Description: 描述
 */
import React from 'react';
import style from './index.module.less';
import DaqIcon from '@components/daq-icon';
import classNames from 'classnames';

import { IComponentProps, IDataItem } from '@interfaces/i-common';
import hasPermission from '@utils/hasPermission';

export interface IAppMenuProps extends IComponentProps {
  activeId: string;
  onClick(menu: IDataItem): void;
  menus: IDataItem[];
}

const AppMenu: React.FC<IAppMenuProps> = ({ menus, activeId, onClick }) => {
  const items = menus.map((menu) => {
    return (
      hasPermission(menu.permission) && (
        <li
          className={classNames(style.appMenuItem, activeId === menu.id ? style.active : '')}
          key={menu.id}
          aria-hidden={true}
          onClick={() => onClick(menu)}
        >
          <DaqIcon
            className={classNames('daq-icon', menu.id)}
            icon={menu.icon}
            color={menu.color}
          />
          <span className={style.label}>{menu.label}</span>
        </li>
      )
    );
  });

  return <ul className={style.appMenu}>{items}</ul>;
};

export default AppMenu;
