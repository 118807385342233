import React, { useRef, useState } from 'react';
import { useMemoizedFn, useUpdateEffect, useMount, useUnmount } from 'ahooks';
import { createPortal } from 'react-dom';
import style from './index.module.less';
import classNames from 'classnames';
import { useImmer } from 'use-immer';

import { DaqCarouselRef } from '@interfaces/i-carousel';
import DaqCarousel from '@components/daq-carousel';
import Droppable from '@components/droppable';
import { useStore } from '@stores';
import { IAppStartMenu, IAppTaskbar } from '@interfaces/i-app-window';
import AppInfoStore from '@stores/app-window/app-store';
import AppList from '@components/app-list';
import AppInfo from '@components/app-info';
import useAutoPaging from '@hooks/use-auto-paging';
import { AppType, TurnPageDirection } from '@interfaces/enum';
import { observer } from 'mobx-react-lite';
import { errorTip, infoTip } from '@utils/error';
import api from '@api';
import { SortableContext } from '@dnd-kit/sortable';
import SortableLi from '@components/sortable-li';
import AppGroup from '@components/app-group';

import { DragOverlay, UniqueIdentifier, useDndMonitor } from '@dnd-kit/core';
import { isDockApp, throttled } from '@utils';
import DaqSoftTrack from '@utils/daqsoft-track';

interface IEnableAppsProps {
  apps: IAppStartMenu[];
  // 是否是筛选过的，如果是，则禁止拖动
  filtered?: boolean;
}

const EnableApps = ({ apps, filtered = false }: IEnableAppsProps) => {
  const { systemStore, appWindowStore, universalSettingStore, dragStore } = useStore();
  // 列表ref控制翻页
  const listRef = useRef<DaqCarouselRef>(null);
  // 滚动事件节流，处理翻页
  const run = throttled((e: React.WheelEvent<HTMLDivElement>) => {
    // 只处理纯滚动
    if (!e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey) {
      if (e.deltaY > 0) {
        // 向下滚动，左翻页
        listRef?.current?.next();
      } else if (e.deltaY < 0) {
        // 向下滚动，右翻页
        listRef?.current?.prev();
      }
    }
  });
  // 自动分页
  const appBlock = {
    width: 228,
    height: 200,
  };
  // 自动计算分页
  const { pageInfo } = useAutoPaging({
    containerId: 'appList',
    appBlock,
    total: apps.length || 0,
  });

  useUpdateEffect(() => {
    systemStore.recordStartMenuPageInfo({
      pageSize: pageInfo.pageSize,
      pageCounts: pageInfo.pageCounts,
      lastPageSize: apps.length % pageInfo.pageSize,
    });
  }, [pageInfo.pageSize, pageInfo.pageCounts, apps.length]);

  const successTrack = (secondEventType: string, appId: string, relationId: string) => {
    DaqSoftTrack.track({
      firstEventType: '开始菜单',
      secondEventType,
      appId,
      relationId,
      status: 1,
    });
  };
  const errorTrack = (
    secondEventType: string,
    appId: string,
    relationId: string,
    failReason: string,
  ) => {
    DaqSoftTrack.track({
      firstEventType: '开始菜单',
      secondEventType,
      appId,
      relationId,
      failReason,
      status: 0,
    });
  };

  // 打开多开应用菜单
  const openMultipleAppMenu = (app: IAppStartMenu) => {
    systemStore.setMultipleApp(app);
    systemStore.showMultipleAppMenu();
  };
  const handleClick = async (e: React.MouseEvent, app: IAppStartMenu) => {
    // 点击app时阻止冒泡，避免点击多开应用时开始菜单关闭
    e.stopPropagation();
    appWindowStore.setShowMenu('');
    // 处理过期  多开应用不处理
    if (app.openAppNum <= 1) {
      if (app.tag === '已过期') {
        errorTrack('打开应用', app.appId, app.relationId, '已过期');
        errorTip(`访问应用“${app.displayName}”失败，该应用已过期！`);
        return;
      } else if (app.serviceType === 2 && app.localServiceState === 0) {
        errorTrack('打开应用', app.appId, app.relationId, '应用部署中');
        errorTip('应用部署中，请联系管理员！');
        return;
      }
    }

    if (app.appType === AppType.group) {
      // 处理点击分组应用
      systemStore.setTargetAppGroup(app);
      systemStore.showAppGroup();
    } else {
      if (appWindowStore.tabExist(app.appUnionId) !== -1) {
        // 隐藏开始菜单
        systemStore.hideStartMenu();
        // 隐藏任务栏
        if (universalSettingStore.autoHide && universalSettingStore.moveShow) {
          universalSettingStore.setMoveShow(false);
        }
        // 已打开过的显示
        appWindowStore.showOpenedApp(app.appUnionId);
      } else {
        // 处理新打开应用的情况
        if (app.appType === AppType.web && app.openAppNum > 1) {
          // 打开多开应用列表（web应用且开通个数大于1时）
          openMultipleAppMenu(app);
        } else {
          // 内置应用 或者 单开的web应用 外部应用

          // 单开的web应用和外部应用特殊处理
          if ([AppType.externalIn, AppType.web].includes(app?.appType)) {
            if (systemStore.gettingUrlApps.includes(app.appId)) {
              infoTip(`应用“${app.displayName}”正在打开中，请稍后！`);
              return;
            } else {
              systemStore.addGettingUrlApp(app.appId);
            }

            try {
              // 获取访问地址，如果失败则退出
              const { data } = await api.third.getWebAppAccessUrl({
                relationId: app?.appType === AppType.externalIn ? app.appId : app.relationId,
                appType: app.appType,
              });
              successTrack('打开应用', app.appId, app.relationId);
              if (!data.url) {
                systemStore.removeGettingUrlApp(app.appId);
                return;
              }
            } catch (e) {
              systemStore.removeGettingUrlApp(app.appId);
              errorTrack('打开应用', app.appId, app.relationId, String(e));
              return;
            }
          }

          // 隐藏开始菜单
          systemStore.hideStartMenu();
          // 隐藏任务栏
          if (universalSettingStore.autoHide && universalSettingStore.moveShow) {
            universalSettingStore.setMoveShow(false);
          }
          appWindowStore.openApp(new AppInfoStore({ ...app } as IAppTaskbar));
          if (![AppType.externalIn, AppType.web].includes(app?.appType)) {
            successTrack('打开应用', app.appId, app.relationId);
          }

          systemStore.removeGettingUrlApp(app.appId);
        }
      }
    }
  };

  const renderAppItem = (app: IAppStartMenu | undefined, noEvent = false, drag = false) => {
    if (!app) {
      return null;
    }

    return app.appType === AppType.group ? (
      <AppGroup
        group={app}
        onClick={noEvent ? undefined : handleClick}
        style={{
          cursor: drag ? 'grabbing' : 'pointer',
        }}
      ></AppGroup>
    ) : (
      <AppInfo
        app={app}
        style={{
          cursor: drag ? 'grabbing' : 'pointer',
        }}
        showAlias={universalSettingStore.showName}
        onClick={noEvent ? undefined : handleClick}
        noHover={noEvent}
      ></AppInfo>
    );
  };

  const [turnPagePrevTimer, setTurnPagePrevTimer] = useState(0);
  const [turnPageNextTimer, setTurnPageNextTimer] = useState(0);
  // 悬停时间翻页
  const interval = 1000;
  const enterLeftRunPageDroppable = useMemoizedFn(() => {
    // 新触发先清除计时器
    window.clearInterval(turnPagePrevTimer);
    // 翻页后，鼠标悬停超过1秒则继续翻页
    const timer = window.setInterval(() => {
      listRef?.current?.prev();
    }, interval);
    setTurnPagePrevTimer(timer);
  });
  const enterRightRunPageDroppable = useMemoizedFn(() => {
    // 新触发先清除计时器
    window.clearInterval(turnPageNextTimer);
    // 翻页后，鼠标悬停超过1秒则继续翻页
    const timer = window.setInterval(async () => {
      listRef?.current?.next();
    }, interval);
    setTurnPageNextTimer(timer);
  });
  // 离开翻页检测区域，清除翻页定时器
  const leaveTurnPageDroppable = useMemoizedFn((active) => {
    // 任务栏的app，则忽略
    if (isDockApp(active?.id)) {
      return;
    }

    if (turnPageNextTimer) {
      window.clearInterval(turnPageNextTimer);
      setTurnPageNextTimer(0);
    }
    if (turnPagePrevTimer) {
      window.clearInterval(turnPagePrevTimer);
      setTurnPagePrevTimer(0);
    }
  });

  useUpdateEffect(() => {
    // 显示开始菜单时，重置页码
    if (systemStore.startMenuShow) {
      listRef?.current?.goTo(1, false);
    }
  }, [systemStore.startMenuShow]);

  // 判断是否是开始菜单第一层元素
  const inStartMenuTopLevel = (id: UniqueIdentifier) => {
    return apps.find((app) => app.appUnionId === id);
  };

  // 上一次触发分组的信息
  const [lastGroupingInfo, setLastGroupingInfo] = useImmer({
    // 目标组或app的id
    groupId: '',
    // 拖动app的id
    appId: '',
  });
  const [groupingTimer, setGroupingTimer] = useState(0);

  // 处理拖动分组
  useUpdateEffect(() => {
    // console.log('分组悬停');
    // 拖动app组合为组时不处理悬停打开组的流程
    const targetApp = systemStore.getAppByUnionId(dragStore.startMenuGroupingId);
    if (!targetApp || targetApp.appType !== AppType.group) {
      window.clearTimeout(groupingTimer);
      setGroupingTimer(0);
      setLastGroupingInfo((state) => {
        state.groupId = '';
        state.appId = dragStore.draggingId;
      });
      return;
    }

    // 拖动app进已存在的组时，处理悬停进入分组中
    // 当前触发分组的信息和上一次触发分组的信息不一致时，重新触发悬停机制
    if (
      lastGroupingInfo.groupId !== dragStore.startMenuGroupingId ||
      lastGroupingInfo.appId !== dragStore.draggingId
    ) {
      window.clearTimeout(groupingTimer);
      // 记录当前触发分组的信息
      setLastGroupingInfo((state) => {
        state.groupId = dragStore.startMenuGroupingId;
        state.appId = dragStore.draggingId;
      });

      // 处理悬停1.5s打开组
      setGroupingTimer(
        window.setTimeout(() => {
          // 打开分组，分组组件中判断添加app到分组中（app group view）
          if (targetApp) {
            systemStore.setTargetAppGroup(targetApp);
            systemStore.showAppGroup();
            // 结束分组状态已经改到group view显示时处理
          }
        }, 1500),
      );
    }
  }, [dragStore.startMenuGroupingId]);

  const clearStartMenuDragInfo = useMemoizedFn(() => {
    // 清除开始菜单相关拖动信息
    dragStore.clearDragInfo();
    dragStore.setStartMenuSortingId('');
    dragStore.setStartMenuGroupingId('');

    // 拖动结束清除翻页定时器
    window.clearInterval(turnPagePrevTimer);
    window.clearInterval(turnPageNextTimer);
    setTurnPageNextTimer(0);
    setTurnPagePrevTimer(0);

    // 清除分组悬停计时器
    window.clearTimeout(groupingTimer);
    setGroupingTimer(0);
  });

  useMount(() => {
    // 开始菜单当前展示页码
    systemStore.recordStartMenuPageIndex(1);
    window.addEventListener('blur', clearStartMenuDragInfo);
  });

  useUnmount(() => {
    window.removeEventListener('blur', clearStartMenuDragInfo);
  });

  // 处理拖动逻辑
  useDndMonitor({
    onDragStart({ active }) {
      const activeId = active.id as string;
      // 只管开始菜单第一层元素
      const index = systemStore.getStartMenuTopLevelAppIndex(activeId);
      if (index < 0) {
        return;
      }
    },
    onDragOver({ active, over }) {
      const activeId = active.id as string;
      const overId = over ? (over.id as string) : '';

      if (
        // 拖动的不是开始菜单第一层app
        !inStartMenuTopLevel(activeId) ||
        // 没有碰撞或者碰撞的任务栏容器app
        !over ||
        isDockApp(overId) ||
        // 碰撞到翻页容器
        [
          TurnPageDirection.prev,
          TurnPageDirection.next,
          TurnPageDirection.groupNext,
          TurnPageDirection.groupPrev,
        ].includes(overId as TurnPageDirection)
      ) {
        return;
      }

      // 处理开始菜单第一层元素翻页排序
      if (dragStore.startMenuSortingId) {
        const currentPageNo = listRef?.current?.getCurrentPageNo() || 1;
        const pageApps = apps.slice(
          (currentPageNo - 1) * pageInfo.pageSize,
          currentPageNo * pageInfo.pageSize,
        );
        // 获取app在当前页面内位置
        const activeAppInPageIndex = pageApps.findIndex((app) => app.appUnionId === activeId);
        const overAppInPageIndex = pageApps.findIndex((app) => app.appUnionId === overId);
        // 如果拖动的app不在当前页面，并且over存在，则移动到当前页面
        if (activeAppInPageIndex < 0 && overAppInPageIndex >= 0) {
          // app在开始菜单第一层中的索引位置
          const activeIndex = systemStore.getStartMenuTopLevelAppIndex(activeId);
          // over的app在开始菜单第一层中的索引位置
          const overIndex = systemStore.getStartMenuTopLevelAppIndex(overId);
          if (activeIndex !== overIndex && activeIndex >= 0 && overIndex >= 0) {
            systemStore.sortStartMenuApp(activeIndex, overIndex);
          }
        }
      }
    },
    onDragEnd({ active, over }) {
      const activeId = active.id as string;
      // end的时候，拖动的app在开始菜单第一层中索引位置
      const activeIndex = systemStore.getStartMenuTopLevelAppIndex(activeId);
      // 拖动的不是开始菜单的app则忽略
      if (activeIndex < 0) {
        return;
      }

      // 正在分组(拖动触发分组高亮的状态，结束拖动或者打开分组后状态不属于分组)
      if (dragStore.startMenuGroupingId) {
        // 获取正在分组的目标元素(可能是组，可能是app)
        const targetApp = systemStore.getAppByUnionId(dragStore.startMenuGroupingId);
        if (!targetApp) {
          clearStartMenuDragInfo();
          return;
        }

        if (targetApp.appType === AppType.group) {
          // 目标是组，区分两种情况，一种是直接拖动app添加到组中（当前处理）；一种是拖动app悬停组上，打开组(在打开组时处理)
          const index = targetApp.openAppList.findIndex((app) => app.appUnionId === activeId);
          // 拖动的app不在目标组中，则移动到目标组中
          if (
            index < 0 &&
            systemStore.moveStartMenuAppToGroup(activeId, dragStore.startMenuGroupingId)
          ) {
            // TODO 处理异常
            api.startMenu
              .saveStartMenuApps(systemStore.startMenuObj)
              .then(() => {
                successTrack('应用分组', targetApp.appId, targetApp?.relationId);
              })
              .catch((error) => {
                errorTrack('应用分组', targetApp.appId, targetApp?.relationId, String(error));
              });
          }
        } else if (systemStore.combineGroup(activeId, dragStore.startMenuGroupingId)) {
          // 目标是app，则表示组合新组，新建组
          // TODO 处理异常
          api.startMenu
            .saveStartMenuApps(systemStore.startMenuObj)
            .then(() => {
              successTrack('应用分组', targetApp.appId, targetApp?.relationId);
            })
            .catch((error) => {
              errorTrack('应用分组', targetApp.appId, targetApp?.relationId, String(error));
            });
        }
      } else {
        const dragInfo = dragStore.dragInfo;
        const activeApp = systemStore.startMenuObj[activeIndex];
        if (over) {
          // over在
          const overIndex = systemStore.getStartMenuTopLevelAppIndex(over.id as string);
          if (activeIndex !== overIndex && activeIndex >= 0 && overIndex >= 0) {
            systemStore.sortStartMenuApp(activeIndex, overIndex);
            // TODO 处理异常
            api.startMenu
              .saveStartMenuApps(systemStore.startMenuObj)
              .then(() => {
                successTrack('应用排序', activeApp.appId, activeApp?.relationId);
              })
              .catch((error) => {
                errorTrack('应用排序', activeApp.appId, activeApp?.relationId, String(error));
              });
          } else if (dragInfo.pId !== activeApp.pId || dragInfo.index !== activeIndex) {
            // 如果拖动前的app组id和现在app的组id不一致，或者拖动前的位置信息和当前位置信息不一致，则表示数据变化了，保存
            // TODO 处理异常
            api.startMenu
              .saveStartMenuApps(systemStore.startMenuObj)
              .then(() => {
                successTrack('应用排序', activeApp.appId, activeApp?.relationId);
              })
              .catch((error) => {
                errorTrack('应用排序', activeApp.appId, activeApp?.relationId, String(error));
              });
          }
        } else if (dragInfo.pId !== activeApp.pId || dragInfo.index !== activeIndex) {
          // 如果拖动前的app组id和现在app的组id不一致，或者拖动前的位置信息和当前位置信息不一致，则表示数据变化了，保存
          // TODO 处理异常
          api.startMenu
            .saveStartMenuApps(systemStore.startMenuObj)
            .then(() => {
              successTrack('应用排序', activeApp.appId, activeApp?.relationId);
            })
            .catch((error) => {
              errorTrack('应用排序', activeApp.appId, activeApp?.relationId, String(error));
            });
        }
      }

      // 清除开始菜单相关拖动信息
      clearStartMenuDragInfo();
    },
  });

  const renderAppPage = () => {
    const pages = [];

    for (let i = 0; i < pageInfo.pageCounts; i++) {
      const pageId = `page${i}`;
      const startIndex = i * pageInfo.pageSize;
      const pageApps = apps.slice(startIndex, startIndex + pageInfo.pageSize);

      pages.push(
        <SortableContext
          key={pageId}
          items={pageApps.map((item) => item.appUnionId)}
        >
          <div
            style={{
              height: `${appBlock.height * pageInfo.rows}px`,
            }}
          >
            <AppList padding={pageInfo.padding}>
              {pageApps.map((app) => {
                // 开始菜单搜索数据不能进行拖动操作
                return filtered ? (
                  <li
                    key={app.appUnionId}
                    id={`app${app.appUnionId}`}
                    className={style.appItem}
                  >
                    {renderAppItem(app)}
                  </li>
                ) : (
                  <SortableLi
                    key={app.appUnionId}
                    uid={app.appUnionId}
                    className={classNames(
                      style.appItem,
                      app.appType === AppType.group ? style.group : '',
                      dragStore.startMenuGroupingId === app.appUnionId ? 'over' : '',
                    )}
                    animateDelay={1}
                  >
                    {renderAppItem(app)}
                  </SortableLi>
                );
              })}
            </AppList>
          </div>
        </SortableContext>,
      );
    }

    return pages;
  };

  return (
    <div
      id="appList"
      className={style.enableApps}
      onWheel={run}
    >
      {pageInfo.pageCounts > 0 ? (
        <>
          {
            // 触发翻页的碰撞检测区域
            pageInfo.pageCounts > 1 ? (
              <>
                <Droppable
                  id={TurnPageDirection.prev}
                  className={classNames(style.turnPageBoundary, style.prev)}
                  onEnter={enterLeftRunPageDroppable}
                  onLeave={leaveTurnPageDroppable}
                ></Droppable>
                <Droppable
                  id={TurnPageDirection.next}
                  className={classNames(style.turnPageBoundary, style.next)}
                  onEnter={enterRightRunPageDroppable}
                  onLeave={leaveTurnPageDroppable}
                ></Droppable>
              </>
            ) : null
          }
          <DaqCarousel
            ref={listRef}
            initPageNo={1}
            pageCounts={pageInfo.pageCounts}
            afterTurnPage={(pageNo) => {
              // 记录当前页码
              systemStore.recordStartMenuPageIndex(pageNo);
            }}
          >
            {renderAppPage()}
          </DaqCarousel>
          {dragStore.draggingId && !isDockApp(dragStore.draggingId)
            ? createPortal(
                <DragOverlay
                  adjustScale={false}
                  dropAnimation={null}
                >
                  <div
                    id="drag-app"
                    className="drag-app"
                  >
                    {renderAppItem(systemStore.getAppByUnionId(dragStore.draggingId), true, true)}
                  </div>
                </DragOverlay>,
                document.body,
              )
            : null}
        </>
      ) : (
        <div className={style.noContent}>搜索无内容</div>
      )}
    </div>
  );
};

export default observer(EnableApps);
