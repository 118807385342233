/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2023-02-03 13:41:45
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-28 09:54:52
 * @Description: 任务栏天气组件
 */
import React, { useState } from 'react';
import { message, Tooltip } from 'antd';
import DaqSidebar from '@components/daq-sidebar';
import weatherPng from '@assets/images/taskbar/weather.png';
import { getWeatherInfo, getWeatherInfoDetail } from '@api/api-taskbar';
import { useImmer } from 'use-immer';
import { useDebounceFn, useInterval, useUnmount, useUpdateEffect } from 'ahooks';
import DaqIcon from '@components/daq-icon';
import LocationConfig from '@views/weather/components/location-config';
import { useStore } from '@stores';
import AppInfoStore from '@stores/app-window/app-store';
import { initWeather, IProps, IWeather, WeatherAppStatus } from '../config';
import { IAppTaskbar } from '@interfaces/i-app-window';
import WeatherPanel from '@views/weather/components/weather-panel';
import DaqSoftTrack from '@utils/daqsoft-track';
import { observer } from 'mobx-react-lite';
import { IWeatherDetailInfo } from '@interfaces/i-weather';
import {
  switchAqiInfo,
  switchCOInfo,
  switchNO2Info,
  switchO3Info,
  switchPM10Info,
  switchPM2D5Info,
  switchSO2Info,
} from '@utils';

interface IRainRes {
  pcpn: string;
  time: string;
  type: string;
}

const DockWeather: React.FC<IProps> = ({ placement }): JSX.Element => {
  const key = 'weatherInfo';
  const [loading, setLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const [weatherInfo, setWeatherInfo] = useImmer<IWeather>(initWeather);
  const { userStore, appWindowStore, systemStore, universalSettingStore } = useStore();
  // 控制天气地区设置页面和天气详情页面展示，true为展示地区设置，false为展示天气详情
  const [showLocationConfig, setShowLocationConfig] = useState(false);

  // 天气应用数据
  const weatherApp = systemStore.weatherApp;
  const userType = userStore.permissions.type;

  const [weatherDetail, setWeatherDetail] = useState<IWeatherDetailInfo>({
    overview: {
      name: '',
      date: '',
      aqi: {
        value: '',
        text: '',
        level: 0,
      },
      temperature: '',
      weatherText: '',
      weatherIcon: '',
      infos: [],
      description: '',
    },
    forecast24h: [],
    weather7days: [],
    airQuality: {
      aqi: {
        text: '',
        value: '',
        level: 0,
      },
      description: '',
      infos: [],
    },
    rainfall2h: {
      description: '',
      infos: [],
    },
    warnings: [],
  });

  // 获取天气详细信息
  const getWeatherDetailInfo = async () => {
    try {
      const { data } = await getWeatherInfoDetail({ weatherKey: userStore.weatherKey });
      let messageContent = '';
      if (data?.status === 3) {
        if (userType === 0) {
          // 提示充值
          messageContent = '天气服务待充值，请充值后查询';
          openWeatherApp();
        } else {
          messageContent = '服务待充值，请联系管理员';
        }
        message.info({ key, content: messageContent });
        setShowSidebar(false);
        return;
      }

      const realtimeInfo = data?.realtimeVo;
      const airQuality = data?.airRealtimeVo;
      const rainfall = data?.pcpnFiveMinutes || [];
      const aqi = switchAqiInfo(airQuality?.aqi);
      setWeatherDetail({
        overview: {
          name: data?.defaultName,
          date: data?.chineseDate,
          aqi: {
            value: airQuality ? airQuality.aqi : '',
            ...aqi,
          },
          temperature: realtimeInfo ? realtimeInfo.temp : '',
          weatherText: realtimeInfo?.text || '',
          weatherIcon: realtimeInfo?.unicode || '',
          infos: realtimeInfo
            ? [
                {
                  name: realtimeInfo.windDir,
                  value: realtimeInfo.windScale ? `${realtimeInfo.windScale}级` : '-',
                },
                {
                  name: '相对湿度',
                  value:
                    realtimeInfo.humidity === 0 || realtimeInfo.humidity
                      ? `${realtimeInfo.humidity}%`
                      : '-',
                },
                {
                  name: '降水量',
                  value: realtimeInfo.precip ? `${realtimeInfo.precip}mm` : '-',
                },
                {
                  name: '紫外线',
                  value: `${realtimeInfo.ultraviolet || '-'}`,
                },
              ]
            : [],
          description: '',
        },
        forecast24h: data?.hourlyVoList || [],
        weather7days: data?.dailyVoList || [],
        airQuality: airQuality
          ? {
              aqi: {
                value: airQuality ? airQuality.aqi : '',
                ...aqi,
              },
              description: '',
              infos: [
                {
                  name: 'PM2.5',
                  value: airQuality.pm2p5,
                  ...switchPM2D5Info(airQuality.pm2p5),
                },
                {
                  name: 'PM10',
                  value: airQuality.pm10,
                  ...switchPM10Info(airQuality.pm10),
                },
                {
                  name: 'O3',
                  value: airQuality.o3,
                  ...switchO3Info(airQuality.o3),
                },
                {
                  name: 'CO',
                  value: airQuality.co,
                  ...switchCOInfo(airQuality.co),
                },
                {
                  name: 'SO2',
                  value: airQuality.so2,
                  ...switchSO2Info(airQuality.so2),
                },
                {
                  name: 'NO2',
                  value: airQuality.no2,
                  ...switchNO2Info(airQuality.no2),
                },
              ],
            }
          : {
              aqi: {
                value: airQuality ? airQuality.aqi : '',
                ...aqi,
              },
              description: '',
              infos: [],
            },
        rainfall2h: {
          description: '',
          infos: rainfall
            ? rainfall.map((item: IRainRes) => {
                return {
                  value: Number(item.pcpn),
                  time: item.time,
                };
              })
            : [],
        },
        warnings: data?.warningVoList || [],
      });
      setLoading(false);
    } catch (e) {
      setShowSidebar(false);
    }
  };

  const successTrack = (
    secondEventType: string,
    thirdEventType: string,
    appId: string,
    relationId: string,
  ) => {
    DaqSoftTrack.track({
      firstEventType: '底部任务栏',
      thirdEventType,
      secondEventType,
      appId,
      relationId,
      status: 1,
    });
  };

  // 隐藏开始菜单和任务栏
  const hideStartMenuAndTaskbar = () => {
    // 隐藏开始菜单
    systemStore.hideStartMenu();
    // 隐藏任务栏
    if (universalSettingStore.autoHide && universalSettingStore.moveShow) {
      universalSettingStore.setMoveShow(false);
    }
  };

  // 未开通天气服务或者天气查询次数不足，则打开天气服务app；其他状态打开天气侧边栏。
  const openSidebarOrApp = async () => {
    hideStartMenuAndTaskbar();
    try {
      const { data } = await getWeatherInfo();
      setWeatherInfo(data);
      let messageContent = '';
      if ([WeatherAppStatus.opened, WeatherAppStatus.changeLocation].includes(data?.status)) {
        // 已开通，则显示天气侧边
        setShowSidebar(true);
        setLoading(true);
        successTrack('天气服务', '打开侧边栏', '', '');
        // 获取天气详情数据，展示天气详情
        setShowLocationConfig(false);
        await getWeatherDetailInfo();
        if (data?.status === WeatherAppStatus.changeLocation) {
          messageContent = '天气服务设置已变更';
        }
      } else if (data?.status === WeatherAppStatus.unsetLocation) {
        if (userType === 0) {
          // 提示设置地区
          messageContent = '请设置天气查询区域';
          openWeatherApp('service-setting');
        } else {
          messageContent = '服务未设置区域，请联系管理员';
        }
      } else if (data?.status === WeatherAppStatus.expired) {
        if (userType === 0) {
          // 提示充值
          messageContent = '天气服务待充值，请充值后查询';
          openWeatherApp();
        } else {
          messageContent = '服务待充值，请联系管理员';
        }
      } else if (data?.status === WeatherAppStatus.notOpen) {
        if (userType === 0) {
          // 提示开通
          openWeatherApp();
        } else {
          messageContent = '服务未开通，请联系管理员';
        }
      }
      if (messageContent !== '') {
        message.info({ key, content: messageContent });
      }
    } catch (e) {
      // 异常
    }
  };

  const openWeatherApp = (url = '') => {
    // 不存在天气应用则忽略
    if (!weatherApp) {
      return;
    }

    if (weatherApp.appUnionId && appWindowStore.tabExist(weatherApp.appUnionId) !== -1) {
      // 已打开过的显示
      appWindowStore.showOpenedApp(weatherApp.appUnionId);
      // 前端控制三方应用跳转url地址： 天气服务首页,服务设置等
      userStore.setWeatherUrl(url);
    } else {
      appWindowStore.openApp(new AppInfoStore({ ...weatherApp } as IAppTaskbar));
      // 前端控制三方应用跳转url地址： 天气服务首页,服务设置等
      userStore.setWeatherUrl(url);
      successTrack(
        '天气服务',
        '跳转天气应用',
        weatherApp?.appId || '',
        weatherApp?.relationId || '',
      );
    }
  };

  const closeSidebar = async () => {
    setShowSidebar(false);
    successTrack('天气服务', '关闭侧边栏', '', '');
  };

  const { run: getWeather } = useDebounceFn(
    async () => {
      try {
        const { data } = await getWeatherInfo();
        setWeatherInfo(data);
      } catch (error) {
        // message.error('获取天气信息失败，请联系系统管理员！');
      }
    },
    {
      wait: 100,
    },
  );

  // 首次渲染时立即执行，然后每一个小时更新一次天气信息。
  const clearInterval = useInterval(
    async () => {
      await getWeather();
      if (showSidebar && !showLocationConfig) {
        getWeatherDetailInfo();
      }
    },
    60 * 60 * 1000,
    { immediate: true },
  );

  useUnmount(() => {
    clearInterval();
  });

  // 用户初次开通天气服务时，触发获取开通信息
  useUpdateEffect(() => {
    getWeather();
  }, [userStore.weatherKey, userStore.userId]);

  // 主体账号修改了天气地区信息 已无用，三方业务无法发送推送，保留后期扩展
  systemStore.on('updateWeather', () => {
    // 关闭侧边
    setShowSidebar(false);
    // 更新天气信息
    getWeather();
  });

  return (
    <>
      {/* 1已开通 2未开通 3查询次数不足 4未设置地区或景区 5天气服务已变更：只有1和5才显示天气图标，其他情况展示默认天气按钮 */}
      {[WeatherAppStatus.opened, WeatherAppStatus.changeLocation].includes(weatherInfo.status) ? (
        <Tooltip
          getPopupContainer={() => document.getElementById('taskbar') as HTMLElement}
          mouseEnterDelay={1}
          placement={placement}
          title={weatherInfo.text}
        >
          <div
            className="weather open"
            onClick={openSidebarOrApp}
          >
            <DaqIcon
              className="daq-weather-icon weather-icon"
              icon={weatherInfo?.unicode || `&#xe8c0;`}
            />
            <span className="celsius">
              {weatherInfo?.temp !== '' ? weatherInfo?.temp : '-'}&#176;
            </span>
          </div>
        </Tooltip>
      ) : (
        <div className="weather">
          <Tooltip
            getPopupContainer={() => document.getElementById('taskbar') as HTMLElement}
            mouseEnterDelay={1}
            placement={placement}
            title="天气服务"
          >
            <img
              src={weatherPng}
              alt=""
              onClick={openSidebarOrApp}
            />
          </Tooltip>
        </div>
      )}
      <DaqSidebar
        width={343}
        onClose={closeSidebar}
        // scroll={!showLocationConfig}
        open={showSidebar}
      >
        {showLocationConfig ? (
          <LocationConfig
            closeSidebar={closeSidebar}
            backWeather={async () => {
              setShowLocationConfig(false);
              setLoading(true);
              await getWeather();
              await getWeatherDetailInfo();
            }}
          ></LocationConfig>
        ) : (
          <WeatherPanel
            loading={loading}
            weatherDetail={weatherDetail}
            openLocationConfig={() => {
              setShowLocationConfig(true);
            }}
          ></WeatherPanel>
        )}
      </DaqSidebar>
    </>
  );
};

export default observer(DockWeather);
