import React from 'react';
import './index.less';
import { showNameFN } from '@utils';
import DaqIcon from '@components/daq-icon';
import DaqImage from '@components/daq-image';

import { IAppStartMenuRes } from '@interfaces/i-api-response';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

interface IAppInfoProps {
  onClick?(e: React.MouseEvent, app: IAppStartMenuRes): void;
  app: IAppStartMenuRes;
  showAlias?: number;
  noHover?: boolean;
  style?: React.CSSProperties;
}

const AppInfo: React.FC<IAppInfoProps> = ({
  onClick,
  app,
  showAlias = 0,
  noHover = false,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'app',
        app.openAppNum <= 1 && app.tag && app.tag !== '服务中' && !noHover ? 'expire' : '',
      )}
      style={{
        ...props.style,
      }}
      title={showNameFN(app, showAlias)}
      onClick={(e) => {
        onClick && onClick(e, app);
      }}
    >
      <div className="app-logo">
        <DaqImage
          alt={app.appName}
          src={app.appLogo}
        ></DaqImage>
        {app.openAppNum > 1 ? (
          <DaqIcon
            className="daq-icon multiple"
            icon="&#xec1c;"
          ></DaqIcon>
        ) : null}
      </div>
      {app.tag && app.tag !== '服务中' ? <h3 className="app-tag">{app.tag}</h3> : null}
      {/*多开应用展示appName 其它根据配置展示*/}
      <h3 className="app-name">{showNameFN(app, showAlias)}</h3>
    </div>
  );
};

export default observer(AppInfo);
