/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2023-02-07 10:24:24
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-07 10:41:51
 * @Description: 系统业务服务
 */
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

// 公共变量
const config = 'admin/serviceConfig';

// 天气开通
export function weatherOpen({
  authName,
  serviceName,
}: {
  authName: string;
  serviceName: string;
}): AxiosPromise {
  return axios({
    url: `${config}/weatherOpen`,
    method: 'post',
    data: { authName, serviceName },
  });
}
