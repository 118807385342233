/**
 * 多开应用的应用列表
 * 开始菜单打开的时候，保持开始菜单显示状态
 * 任务栏打开的时候，关闭开始菜单
 */
import style from './index.module.less';
import MultipleAppList from './components/multiple-app-list';
import React, { useState } from 'react';
import { IAppStartMenu } from '@interfaces/i-app-window';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores';
import BackgroundLayer from '@components/background-layer';
import { useUpdateEffect } from 'ahooks';
import { getViewPortPosition } from '@utils';

function MultipleApp({ app, show }: { app: IAppStartMenu | undefined; show: boolean }) {
  const { systemStore, systemSettingStore, universalSettingStore } = useStore();
  const { wallPaper: systemWallPaper } = systemSettingStore;
  const { isMasterSetupWallPager, wallPaper } = universalSettingStore;

  // 处理点击空白处隐藏开始菜单
  const handleClose = (e: React.MouseEvent) => {
    // 如果点击空白处关闭切换应用页面
    if (!['button'].includes((e.target as HTMLElement).tagName.toLowerCase())) {
      // 如果点击的不是翻页按钮，则隐藏应用切换
      systemStore.hideMultipleAppMenu();
    }
  };

  const [transformOrigin, setTransformOrigin] = useState('center center');
  // 处理打开分组时的一个初始位置
  useUpdateEffect(() => {
    if (show) {
      // 每次打开都重新计算，因为可能拖动变化了位置
      let origin = 'center center';
      const rect = getViewPortPosition(`app${app?.appUnionId || ''}`);
      if (rect) {
        // 44为应用图标高度的一半，20是图标的margin top， 130为分组容器到顶部的距离（源点为应用图标中心点）
        origin = `${rect.left + rect.width / 2}px ${rect.top + 20 + 44 - 130}px`;
      }

      // console.log(rect, origin);

      setTransformOrigin(origin);
    }
  }, [app?.appUnionId, show]);

  return (
    <BackgroundLayer
      className={style.multipleApp}
      wallPaper={isMasterSetupWallPager === 0 ? wallPaper : systemWallPaper}
      show={show}
      onClick={handleClose}
    >
      <h3 className={style.title}>{app?.appName}</h3>
      <MultipleAppList
        style={{
          transformOrigin: transformOrigin,
        }}
        mainApp={app}
        apps={app?.openAppList || []}
      ></MultipleAppList>
    </BackgroundLayer>
  );
}

export default observer(MultipleApp);
