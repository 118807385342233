import './index.less';
import { useImmer } from 'use-immer';
import { useUpdateEffect } from 'ahooks';
import classNames from 'classnames';

const LoadingProcess = ({ loading = false }: { loading: boolean }) => {
  const [state, setState] = useImmer({
    loading: false,
    percent: 0,
  });

  useUpdateEffect(() => {
    let timer = 0;
    if (loading) {
      timer = window.setInterval(() => {
        setState((state) => {
          let amount = 0;
          if (state.percent >= 0 && state.percent < 20) {
            amount = 1;
          } else if (state.percent >= 20 && state.percent < 50) {
            amount = 0.4;
          } else if (state.percent >= 50 && state.percent < 80) {
            amount = 0.2;
          } else if (state.percent >= 80 && state.percent < 99) {
            amount = 0.05;
          }
          if (state.percent < 99) {
            state.percent += amount;
            state.loading = true;
          } else {
            clearInterval(timer);
          }
        });
      }, 5);
    } else {
      setState((state) => {
        state.percent = 100;
        state.loading = true;
      });

      setTimeout(() => {
        setState((state) => {
          state.loading = false;
        });
      }, 300);
    }

    return () => {
      clearInterval(timer);
    };
  }, [loading]);

  return (
    <div className={classNames('loading-process', { loading: state.loading })}>
      <div
        className="loading-process-bar"
        style={{ width: `${state.percent}%` }}
      ></div>
    </div>
  );
};

export default LoadingProcess;
