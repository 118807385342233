/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-08 11:33:32
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-07 17:03:21
 * @Description: 应用程序窗口-工具栏
 */
import React from 'react';
import './tool-bar.less';
import { WindowSize, pointEvents } from '@interfaces/enum';
import { observer } from 'mobx-react-lite';
import AppInfoStore from '@stores/app-window/app-store';
import ToolTab from './tool-tab';
import ToolAction from './tool-action';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import DaqSoftTrack from '@utils/daqsoft-track';
import NavigationBtn from '@components/navigation-btn';
interface IToolBar {
  onClose: () => void;
  onMaximize: () => void;
  onMinimize: () => void;
  onRestore: () => void;
  closeApp: (value: AppInfoStore, index: number) => void;
  changeApp: (id: string) => void;
  windowSize: WindowSize;
  tabList: AppInfoStore[];
  activeAppId: string;
  showName: number;
  dragTabApp: (startIndex: number, endIndex: number) => void;
  setIframePoint: (pointEvents: pointEvents) => void;
}

const ToolBar: React.FC<IToolBar> = ({
  onClose,
  onMaximize,
  onMinimize,
  onRestore,
  closeApp,
  changeApp,
  windowSize,
  tabList,
  activeAppId,
  showName,
  dragTabApp,
  setIframePoint,
}): JSX.Element => {
  // 拖动结束重新排序
  const onDragEnd = ({ over, active }: DragEndEvent) => {
    if (!active) {
      return;
    }
    setIframePoint(pointEvents.auto);
    const overId = over?.id as string;
    const activeId = active?.id as string;
    const startIndex = getIndex(activeId);
    const endIndex = getIndex(overId);
    dragTabApp(startIndex, endIndex);
    DaqSoftTrack.track({
      firstEventType: '应用窗口',
      secondEventType: '拖拽排序',
      appId: activeId,
      status: 1,
    });
  };

  // 拖动开始之前激活当前app
  const onDragStart = ({ active }: DragStartEvent) => {
    const activeId = active?.id as string;
    setIframePoint(pointEvents.none);

    if (activeAppId === activeId) {
      return;
    }
    changeApp(activeId);
  };

  const getIndex = (id: string) => tabList.findIndex((item) => item.appUnionId === id);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  return (
    <div className="toolbar">
      <NavigationBtn
        canGoBack={false}
        canGoForward={false}
      />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
      >
        <SortableContext
          items={tabList.map((item) => item.appUnionId)}
          strategy={horizontalListSortingStrategy}
        >
          <div
            className="toolbar-tabs"
            data-op="0"
          >
            {tabList.map((item: AppInfoStore, index: number) => (
              <ToolTab
                key={item?.appUnionId}
                index={index}
                appInfo={item}
                activeAppId={activeAppId}
                closeApp={(appInfo) => {
                  DaqSoftTrack.track({
                    firstEventType: '关闭应用',
                    secondEventType: '应用窗口',
                    appId: appInfo.appId,
                    relationId: appInfo.relationId,
                    status: 1,
                  });
                  closeApp(appInfo, index);
                }}
                changeApp={changeApp}
                showName={showName}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <div className="toolbar-action-btn">
        <ToolAction
          windowSize={windowSize}
          onClose={onClose}
          onMaximize={onMaximize}
          onMinimize={onMinimize}
          onRestore={onRestore}
        />
      </div>
    </div>
  );
};

export default observer(ToolBar);
