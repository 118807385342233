import { useDebounceEffect, useSize, useUpdateEffect } from 'ahooks';
import { useImmer } from 'use-immer';

interface AutoPagingProps {
  // 块信息
  appBlock?: {
    width: number;
    height: number;
  };
  // 页面水平方向单边padding
  pageHPadding?: number;
  // 分页区域的容器id，监听大小变化，自动分页
  containerId: string;
  // 需要处理分页的数据的总数量
  total: number;
}

// appBlock 单块app大小 （按理应该通过dom获取，减少麻烦先直接写死，修改单块的大小样式需要对应修改此处的大小）
export default function useAutoPaging({
  appBlock = { width: 228, height: 200 },
  pageHPadding = 160,
  containerId,
  total,
}: AutoPagingProps) {
  // 容器大小
  const containerSize = useSize(window.document.getElementById(containerId));
  // 动态计算容器中一页可显示多少
  const computedPageSize = () => {
    const containerHeight = containerSize?.height || 0;
    // app列表区域横向padding
    const horizontalPadding = pageHPadding * 2;
    const containerWidth = (containerSize?.width || horizontalPadding) - horizontalPadding;
    let rows = 0;
    let columns = 0;
    let lastPadding = 0;
    if (containerHeight < 2 * appBlock.height) {
      rows = 1;
    } else {
      rows = Math.floor(containerHeight / appBlock.height);
    }

    if (containerWidth % appBlock.width >= appBlock.width / 2) {
      // 如果剩余宽度大于小块的一半，则使用padding数据补齐为一整块
      lastPadding = pageHPadding - (appBlock.width - (containerWidth % appBlock.width)) / 2;
      columns = Math.ceil(containerWidth / appBlock.width);
    } else {
      // 如果剩余宽度小于小块的一半，则把剩余的宽度处理为padding
      lastPadding = pageHPadding + (containerWidth % appBlock.width) / 2;
      columns = Math.floor(containerWidth / appBlock.width);
    }

    return {
      columns,
      rows,
      // 每页数据个数
      pageSize: columns * rows,
      // 列表横向单边padding
      padding: lastPadding,
    };
  };

  const initSize = computedPageSize();
  // state
  const [state, setState] = useImmer<{
    // 横向余留padding
    padding: number;
    // 列数
    columns: number;
    // 行数
    rows: number;
    // 每页个数
    pageSize: number;
    // 总页数
    pageCounts: number;
  }>({
    padding: initSize.padding,
    columns: initSize.columns,
    rows: initSize.rows,
    // 每页个数
    pageSize: initSize.pageSize,
    // 分页数
    pageCounts: 0,
  });

  // 容器大小变化时，重新计算分页信息
  // TODO window添加一个resize防抖处理  目前resize时，视口跟着变导致flex布局先布局了，然后在计算分页，导致前后不一致
  useDebounceEffect(
    () => {
      setState((state) => {
        const { padding, pageSize, rows, columns } = computedPageSize();
        state.rows = rows;
        state.columns = columns;
        state.padding = padding;
        state.pageSize = pageSize;
      });
    },
    [containerSize],
    { wait: 500 },
  );

  // 分页大小或者数据总量变化时，重新计算分页数
  useUpdateEffect(() => {
    setState((state) => {
      state.pageCounts = state.pageSize ? Math.ceil(total / state.pageSize) : 0;
    });
  }, [state.pageSize, total]);

  return { pageInfo: state };
}
