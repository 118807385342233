/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-09-16 11:46:00
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-06 16:58:07
 * @Description: 记录
 */
import DaqIcon from '@components/daq-icon';
import { disabledDate } from '@utils';
import { DatePicker, Form, Select } from 'antd';
import * as React from 'react';
import { useMemoizedFn } from 'ahooks';
import { getListPagingApi } from '@api/api-externalAppInfoLog';
import {
  IAppManageRecordListParams,
  IExternalLogListPagingParams,
} from '@interfaces/i-api-request';
import { useImmer } from 'use-immer';
import { useEffect, useState } from 'react';
import { IExternalLogListRes } from '@interfaces/i-api-response';
import InfiniteScroll from '@components/infinite-scroll';
import useDebounceUpdateEffect from '@hooks/use-debounce-update-effect';
import DaqEmpty from '@components/daq-empty';
import moment from 'moment';
import { recordListApi } from '@api/api-appManagement';
import DaqSoftTrack from '@utils/daqsoft-track';

interface IProps {
  className?: string;
  appId: string;
  type: string;
}
const { Option } = Select;
const RecordModal: React.FC<IProps> = ({ appId, className, type }): JSX.Element => {
  const [dataList, setDataList] = useState<IExternalLogListRes[]>([]);
  const [state, setState] = useImmer<
    Partial<IExternalLogListPagingParams & IAppManageRecordListParams>
  >({
    eventName: '',
    externalAppId: '',
    relationId: '',
    year: '',
    pageNo: 1,
    pageSize: 10,
    pageTotal: 1,
    recordCount: 0,
  });
  const successTrack = () => {
    DaqSoftTrack.track({
      firstEventType: '应用管理',
      secondEventType: '查看记录',
      appId: state.externalAppId,
      relationId: state.relationId,
      status: 1,
    });
  };
  const errorTrack = (failReason: string) => {
    DaqSoftTrack.track({
      firstEventType: '应用管理',
      secondEventType: '查看记录',
      appId: state.externalAppId,
      relationId: state.relationId,
      failReason,
      status: 0,
    });
  };
  // 获取记录列表数据
  const getListPaging = useMemoizedFn(async (params: Partial<IExternalLogListPagingParams>) => {
    try {
      let promiseData;
      if (type === 'add') {
        promiseData = await getListPagingApi({
          ...params,
          pageSize: state.pageSize,
        });
      } else {
        promiseData = await recordListApi({
          ...params,
          pageSize: state.pageSize,
        });
      }
      successTrack();
      const { data, page } = promiseData;
      const arr = data ?? [];
      let List: IExternalLogListRes[] = [];
      if (params.pageNo !== 1) {
        List = [...dataList];
      }

      setDataList([...List, ...arr]);
      setState((_) => {
        _.pageNo = page?.pageNo;
        _.pageTotal = page?.pageTotal;
        _.recordCount = page?.recordCount;
      });
    } catch (error) {
      console.error(error);
      errorTrack(String(error));
    }
  });
  useEffect(() => {
    if (appId === '') {
      return;
    }
    setState((_) => {
      if (type === 'add') {
        _.externalAppId = appId;
      } else {
        _.relationId = appId;
      }
    });
  }, [appId, setState, type]);

  useDebounceUpdateEffect(() => {
    if (appId === '') {
      return;
    }
    getListPaging({ ...state, pageNo: 1 });
  }, [state.eventName, state.year, state.externalAppId, state.relationId]);

  return (
    <div className={`add-modal-body add-modal-body-record ${className}`}>
      <div className="add-modal-body-header">
        <Form layout="inline">
          <Form.Item
            name="year"
            label="年份"
          >
            <DatePicker
              inputReadOnly
              picker="year"
              style={{ width: 170 }}
              disabledDate={disabledDate}
              onChange={(value) => {
                const year = value ? moment(value).format('YYYY') : '';
                setState((_) => {
                  _.year = year;
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="eventName"
            label="事件名称"
          >
            <Select
              defaultValue=" "
              style={{ width: 180 }}
              onChange={(value) => {
                setState((_) => {
                  _.eventName = value;
                });
              }}
            >
              <Option value=" ">全部</Option>
              {type === 'add' && <Option value="应用名称">应用名称</Option>}
              <Option value="应用别称">应用别称</Option>
              <Option value="应用LOGO">应用LOGO</Option>
              <Option value="访问地址">访问地址</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="add-modal-body-content">
        {dataList.length > 0 ? (
          <InfiniteScroll
            pageNo={state.pageNo ?? 0}
            pageTotal={state.pageTotal ?? 0}
            recordCount={state.recordCount ?? 0}
            onNext={(pageNo: number) => {
              getListPaging({ ...state, pageNo });
            }}
          >
            {dataList.map((item, index) => (
              <div
                className={`add-modal-body-content-list ${
                  index === dataList.length - 1 ? 'add-modal-body-content-list-end' : ''
                }`}
                key={index}
              >
                <div className="time-icon">
                  <DaqIcon
                    className="daq-icon record-info-time"
                    icon="&#xe8c5;"
                  ></DaqIcon>
                </div>
                <div className="record-info">
                  <div className="record-info-time">{item.gmtCreate}</div>
                  <div className="record-info-text">
                    <div>事件：{item.eventName}</div>
                    <div>记录：{item.remark}</div>
                    {type !== 'add' && item.dataSource === '大旗云' && (
                      <div>来源：{item.dataSource}</div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <DaqEmpty message="暂无应用记录信息" />
        )}
      </div>
    </div>
  );
};

export default RecordModal;
