/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-06 15:43:43
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-09 17:31:29
 * @Description: 员工
 */
import { IStaffListPagingRes } from '@interfaces/i-api-response';
import { ICStaffProps } from '@interfaces/i-staff-manage';
import { ErrorAvatarImg } from '@utils';
import { Button, Popconfirm, Switch } from 'antd';
import React from 'react';
import avatar from '@assets/images/avatar.png';
import './list-content.less';
import DaqEmpty from '@components/daq-empty';
import InfiniteScroll from '@components/infinite-scroll';
import AnimationList from '@components/animation-list';

const StaffListContent: React.FC<ICStaffProps> = ({
  onNext,
  onResetPwd,
  onRevise,
  onDetail,
  // onRemove,
  onState,
  dataList,
  pageNo,
  pageTotal,
  recordCount,
  isLoading,
}): JSX.Element => {
  return (
    <ul className="list-content staff-list-content">
      <li className="header tr">
        <div className="td">员工信息</div>
        <div className="td">姓名</div>
        <div className="td">角色权限</div>
        <div className="td">管理应用数</div>
        <div className="td">状态</div>
        <div className="td">操作时间</div>
        <div className="td">操作</div>
      </li>
      {dataList.length > 0 ? (
        <InfiniteScroll
          className="body"
          pageNo={pageNo}
          pageTotal={pageTotal}
          recordCount={recordCount}
          onNext={(pageNo: number) => {
            onNext?.(pageNo);
          }}
        >
          <AnimationList childClassName="tbody">
            {dataList.map((item: IStaffListPagingRes, index: number) => {
              return (
                <li
                  className="tr"
                  key={item.userId + index}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDetail?.(item.userId);
                  }}
                >
                  <div className="td staff-info">
                    <div className="staff-info-avatar">
                      <img
                        src={item.headSculpture ?? avatar}
                        alt={item.realName}
                        onError={ErrorAvatarImg}
                      />
                    </div>
                    <div className="staff-info-content">
                      {/* <div className="staff-info-content-name">{item.realName}</div> */}
                      <div className="staff-info-content-name">{item.phone}</div>
                      <div className="staff-info-content-phone">{item.userName}</div>
                    </div>
                  </div>
                  <div className="td">{item.realName}</div>
                  <div className="td">{item.roleName}</div>
                  {/* <div className="td">{item.roleName}</div> */}
                  <div className="td">{item.manageAppNums}</div>
                  <div
                    className="td"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Popconfirm
                      title={`您确定要${item.userStatus !== 1 ? '启用' : '禁用'}此员工吗？`}
                      okText="确定"
                      cancelText="取消"
                      onConfirm={() => {
                        onState?.(item.userId, Number(!item.userStatus));
                      }}
                    >
                      <Switch
                        size="small"
                        checkedChildren="启用"
                        unCheckedChildren="禁用"
                        checked={item.userStatus === 1}
                      />
                    </Popconfirm>
                  </div>
                  <div className="td">{item.gmtModified}</div>
                  <div
                    className="td"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Popconfirm
                      title="您确定要重置密码吗？"
                      okText="确定"
                      cancelText="取消"
                      onConfirm={() => {
                        onResetPwd?.(item);
                      }}
                    >
                      <Button
                        size="small"
                        className="btn-white"
                      >
                        重置密码
                      </Button>
                    </Popconfirm>
                    <Button
                      size="small"
                      className="btn-white"
                      onClick={(e) => {
                        e.stopPropagation();
                        onRevise?.(item.userId);
                      }}
                    >
                      修改
                    </Button>
                    {/* <Popconfirm
                      title="您确定要删除此员工吗？"
                      okText="确定"
                      cancelText="取消"
                      onConfirm={() => {
                        onRemove?.(item.userId, index);
                      }}
                    >
                      <Button
                        size="small"
                        className="btn-white"
                      >
                        删除
                      </Button>
                    </Popconfirm> */}
                  </div>
                </li>
              );
            })}
          </AnimationList>
        </InfiniteScroll>
      ) : (
        !isLoading && <DaqEmpty message="暂无员工信息" />
      )}
    </ul>
  );
};

export default StaffListContent;
