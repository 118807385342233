/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-15 10:12:16
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-22 18:59:37
 * @Description: 描述
 */
import { IBaseUserInfo } from '@interfaces/i-user-info';
import { ErrorAvatarImg } from '@utils';
import { useMount } from 'ahooks';
import { Button } from 'antd';
import avatar from '@assets/images/avatar.png';
import classNames from 'classnames';
import React, { useState } from 'react';
import './switch-account.less';
import Scrollbars from 'rc-scrollbars';

interface IProps {
  disabled: boolean;
  username: string;
  userList: IBaseUserInfo[];
  onCancel: () => void;
  onSwitch: (username: string) => void;
}
const SwitchAccount: React.FC<IProps> = ({
  disabled,
  username,
  userList,
  onCancel,
  onSwitch,
}): JSX.Element => {
  const [name, setName] = useState('');
  useMount(() => {
    setName(username);
  });
  return (
    <div className="switch-account">
      <div className="switch-account-content">
        <Scrollbars autoHide>
          {userList.map((item: IBaseUserInfo) => {
            return (
              <div
                className={classNames('switch-account-list', {
                  'common-active': item.username === name,
                  'user-current': item.username === username && item.username !== name,
                })}
                key={item.username}
                onClick={() => {
                  setName(item.username);
                }}
              >
                <div className="switch-account-list-avatar">
                  <img
                    src={item.avatar ?? avatar}
                    alt={''}
                    onError={ErrorAvatarImg}
                  />
                </div>
                <div className="switch-account-list-info">
                  <div className="switch-account-list-info-phone">{item.phone}</div>
                  <div className="switch-account-list-info-name">{item.username}</div>
                </div>
              </div>
            );
          })}
        </Scrollbars>
      </div>
      <div className="switch-account-btn">
        <Button
          type="primary"
          disabled={disabled || username === name}
          loading={disabled}
          onClick={() => {
            onSwitch(name);
          }}
        >
          确定
        </Button>
        <Button onClick={onCancel}>取消</Button>
      </div>
    </div>
  );
};

export default SwitchAccount;
