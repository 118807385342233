/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-11 13:52:31
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-07 11:44:46
 * @Description: 自定义按钮，用于多选和单选组件
 */
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import './index.less';

interface IProps {
  onClick?: (key: string, value: string) => void;
  value: string;
  label: string;
  active?: boolean;
  disabled?: boolean;
}
const CheckButton: React.FC<IProps> = ({
  onClick,
  value,
  label,
  active,
  disabled,
}): JSX.Element => {
  return (
    <Tooltip
      title={label}
      mouseEnterDelay={1.5}
    >
      <div
        className={classNames('check-button', {
          'common-active common-active-btn': active,
          'check-button-disabled': disabled,
        })}
        key={value}
        onClick={() => {
          onClick?.(value, label);
        }}
      >
        {label}
      </div>
    </Tooltip>
  );
};

export default CheckButton;
