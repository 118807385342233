/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-20 18:06:55
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-25 09:39:22
 * @Description: 描述
 */
import React from 'react';
import './index.less';
import EmptyImg from '@assets/images/empty.png';
import EmptyDark from '@assets/images/empty-dark.png';
import { Button, Empty } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores';
interface IProps {
  image?: string;
  message?: string;
  btnLabel?: string;
  onClick?: () => void;
}
const DaqEmpty: React.FC<IProps> = ({ image, message = '暂无数据', onClick, btnLabel }) => {
  const { universalSettingStore } = useStore();
  return (
    <Empty
      className="daq-empty"
      image={image ? image : universalSettingStore.model === 1 ? EmptyDark : EmptyImg}
      description={<span>{message}</span>}
    >
      {btnLabel !== undefined && (
        <Button
          type="primary"
          onClick={onClick}
        >
          {btnLabel}
        </Button>
      )}
    </Empty>
  );
};

export default observer(DaqEmpty);
