import DaqIcon from '@components/daq-icon';
import style from './description.module.less';

interface DescriptionProps {
  icon: string;
  text: string;
}

const Description = (props: DescriptionProps) => {
  return (
    <div className={style.description}>
      <DaqIcon
        className={`daq-icon ${style.icon}`}
        icon={props.icon}
      ></DaqIcon>
      <span>{props.text}</span>
    </div>
  );
};

export default Description;
