/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-21 16:44:05
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-21 16:47:58
 * @Description: 描述
 */
import React from 'react';
import './index.less';

const EndMessage: React.FC = (): JSX.Element => {
  return <div className="end-message">已没更多内容了</div>;
};

export default EndMessage;
