/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-11-02 10:17:44
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-11-14 17:24:43
 * @Description: titleBar
 */
import React, { useRef, useState } from 'react';
import './index.less';
import DaqIcon from '@components/daq-icon';
import { useEventListener, useLatest } from 'ahooks';

const TitleBar: React.FC = (): JSX.Element => {
  const [delayTimer, setDelayTimer] = useState(0);
  const latestDelayTimer = useLatest(delayTimer);
  const titleBarRef = useRef(null);
  const [showTitle, setShowTitle] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(true);

  useEventListener(
    'mouseenter',
    () => {
      if (showTitle) {
        return;
      }
      const timer = window.setTimeout(() => {
        setShowTitle(true);
      }, 1200);
      setDelayTimer(timer);
    },
    { target: titleBarRef },
  );

  useEventListener(
    'mouseleave',
    () => {
      if (latestDelayTimer.current === 0 && !showTitle) {
        return;
      }
      setShowTitle(false);
      clearDelayTimer();
    },
    { target: titleBarRef },
  );

  const clearDelayTimer = () => {
    if (latestDelayTimer.current) {
      clearTimeout(latestDelayTimer.current);
      setDelayTimer(0);
    }
  };

  return (
    <>
      <div
        className="titlebar"
        data-show={showTitle}
        ref={titleBarRef}
      >
        <div className="titlebar-box">
          <div className="titlebar-box-action">
            <div
              className="fullscreen"
              data-track-info={JSON.stringify({
                firstEventType: '系统窗口菜单栏',
                secondEventType: '切换全屏',
                status: 1,
              })}
              onClick={() => {
                setIsFullScreen(!isFullScreen);
                window?.electronAPI?.windowFullScreen();
              }}
            >
              {isFullScreen ? <DaqIcon icon="&#xe88d;" /> : <DaqIcon icon="&#xe88e;" />}
            </div>
            <div
              className="minimize"
              data-track-info={JSON.stringify({
                firstEventType: '系统窗口菜单栏',
                secondEventType: '最小化',
                status: 1,
              })}
              onClick={() => {
                window?.electronAPI?.windowMinimize();
              }}
            >
              <DaqIcon icon="&#xe60e;" />
            </div>
            <div
              className="closed"
              onClick={() => {
                // 埋点放在on-close-window.ts  兼容mac与windows
                window?.electronAPI?.windowClosed();
              }}
            >
              <DaqIcon icon="&#xe68f;" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TitleBar;
