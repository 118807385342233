/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-04 14:09:27
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-22 17:45:29
 * @Description: 应用管理
 */
import { getOpenListApi } from '@api/api-appManagement';
import AppMenu from '@components/app-menu';
import AppSideBar from '@components/app-side-bar';
import { IAppManageOpenListParams } from '@interfaces/i-api-request';
import { IOpenListRes } from '@interfaces/i-api-response';
import { IDataItem } from '@interfaces/i-common';
import { useMemoizedFn, useMount } from 'ahooks';
import React, { useState } from 'react';
import { useImmer } from 'use-immer';
import Apps from './components/apps';
import { EMenus, IMenus } from './config';
import './index.less';
import useDebounceUpdateEffect from '@hooks/use-debounce-update-effect';
import InfiniteScroll from '@components/infinite-scroll';
import DaqEmpty from '@components/daq-empty';
import { Drawer } from 'antd';
import AddApps from './components/add-apps';
import { getListPagingApi } from '@api/api-externalAppInfo';
import DaqIcon from '@components/daq-icon';
import ModalBox from './components/modal-box';
import moment from 'moment';
import AnimationList from '@components/animation-list';

const AppManage: React.FC = (): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('添加应用');
  const [detail, setDetail] = useState<IOpenListRes | null>(null);
  const [activeId, setActiveId] = useState('all');
  const [isLoading, setIsLoading] = useState(true);
  const [appsList, setAppsList] = useState<IOpenListRes[]>([]);
  const [state, setState] = useImmer<Partial<IAppManageOpenListParams>>({
    state: '',
    serviceType: '',
    pageNo: 1,
    pageSize: 25,
    pageTotal: 1,
    recordCount: 0,
  });
  // TODO 内外网切换
  const isLocally = import.meta.env.VITE_ENV_TYPE === 'locally' ? true : false;

  const handleClick = (menu: IDataItem) => {
    if (menu.id === activeId) {
      return;
    }
    setIsLoading(true);
    setActiveId(menu.id);
    clear();
  };

  const getOpenList = useMemoizedFn(
    async (params: Partial<IAppManageOpenListParams>, type?: string) => {
      try {
        let ListInfo;
        if (type === 'add') {
          ListInfo = await getListPagingApi({
            ...params,
            pageSize: state.pageSize,
          });
        } else {
          ListInfo = await getOpenListApi({
            ...params,
            pageSize: state.pageSize,
          });
        }

        const { data, page } = ListInfo;
        const arr = data ?? [];
        let List: IOpenListRes[] = [];
        if (params.pageNo !== 1) {
          List = [...appsList];
        }

        setAppsList([...List, ...arr]);
        setState((_) => {
          _.pageNo = page?.pageNo;
          _.pageTotal = page?.pageTotal;
          _.recordCount = page?.recordCount;
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
  );

  const clear = () => {
    setAppsList([]);
    setState((_) => {
      _.pageNo = 1;
      _.pageTotal = 1;
      _.recordCount = 1;
    });
  };

  //添加外部应用
  const onClose = () => {
    setVisible(false);
  };

  useMount(() => {
    setAppsList([]);
    getOpenList({ pageNo: 1 }, activeId);
  });

  useDebounceUpdateEffect(
    () => {
      setIsLoading(true);
      if (isLocally) {
        localList();
      } else {
        serveList();
      }
    },
    [activeId],
    1000,
  );
  //  本地服务：接口 改变serviceType ，state 默认全部
  const localList = () => {
    let state = '';
    if (activeId === 'serving') {
      state = '1';
    }
    if (activeId === 'expired') {
      state = '2';
    }
    setState((_) => {
      _.serviceType = state;
    });
    getOpenList({ serviceType: state, pageNo: 1 }, activeId);
  };
  //  本地服务：接口 state 默认 serviceType:1
  const serveList = () => {
    let state = '';
    if (activeId === 'serving') {
      state = '1';
    }
    if (activeId === 'expired') {
      state = '0';
    }
    setState((_) => {
      _.state = state;
      _.serviceType = '1';
    });
    getOpenList({ serviceType: '1', state, pageNo: 1 }, activeId);
  };

  const formatDate = (date: string, format = 'YYYY-MM-DD') => {
    if (date?.includes('9999')) {
      return '长期有效';
    }
    return moment(date).format(format);
  };

  return (
    <div className="app-manage">
      <AppSideBar>
        <AppMenu
          menus={!isLocally ? EMenus : IMenus}
          activeId={activeId}
          onClick={handleClick}
        />
      </AppSideBar>
      {appsList.length > 0 || (!isLoading && activeId === 'add') ? (
        <InfiniteScroll
          pageNo={state.pageNo ?? 1}
          pageTotal={state.pageTotal ?? 1}
          recordCount={state.recordCount ?? 1}
          onNext={(pageNo: number) => {
            getOpenList(
              {
                ...state,
                pageNo,
              },
              activeId,
            );
          }}
        >
          <AnimationList
            className="app-manage-body"
            key="app-manage"
          >
            {activeId === 'add' ? (
              <AddApps
                key="addApps"
                onClick={() => {
                  setVisible(true);
                  setDetail(null);
                  setDrawerTitle('添加应用');
                }}
              />
            ) : null}

            {appsList.map((item: IOpenListRes, index: number) => {
              return (
                <Apps
                  appLogo={activeId === 'add' ? item.logoUrl : item.appLogo}
                  appName={activeId === 'add' ? item.name : item.appName}
                  displayName={activeId === 'add' ? item.aliasName : item.displayName}
                  state={activeId === 'add' ? -1 : item.state}
                  dueDay={activeId === 'add' ? -1 : item.dueDay}
                  tag={activeId === 'add' ? '' : item.tag}
                  dueTime={
                    activeId === 'add'
                      ? formatDate(item?.gmtModified, 'YYYY-MM-DD HH:mm')
                      : formatDate(item?.dueTime ?? '')
                  }
                  types={activeId}
                  key={activeId === 'add' ? item?.id : item?.relationId ?? index}
                  onClick={() => {
                    setVisible(true);
                    setDetail(item);
                    setDrawerTitle(activeId === 'add' ? item.aliasName : item.displayName);
                  }}
                />
              );
            })}
          </AnimationList>
        </InfiniteScroll>
      ) : !isLoading ? (
        <DaqEmpty message="暂无开通应用" />
      ) : null}

      <Drawer
        title={drawerTitle}
        destroyOnClose={true}
        width={590}
        closable={false}
        onClose={onClose}
        open={visible}
        extra={
          <div onClick={onClose}>
            <DaqIcon icon="&#xe86d;" />
          </div>
        }
      >
        <ModalBox
          type={activeId}
          detail={detail}
          onBack={async (id) => {
            onClose();
            if (!id) {
              return;
            }
            setAppsList([]);
            await getOpenList(
              {
                ...state,
                pageNo: 1,
              },
              activeId,
            );
          }}
        />
      </Drawer>
    </div>
  );
};

export default AppManage;
