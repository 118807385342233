import style from './index.module.less';
import React, { PropsWithChildren } from 'react';
import { useDroppable, Active } from '@dnd-kit/core';
import { useUpdateEffect } from 'ahooks';
import classNames from 'classnames';

interface IDroppableProps {
  id: string;
  className?: string;
  // 进入回调，添加active用于判断是否做做逻辑处理
  onEnter?: (active: Active | null) => void;
  // 离开回调
  onLeave?: (active: Active | null) => void;
  style?: React.CSSProperties;
}

const Droppable = ({ onEnter, onLeave, ...props }: PropsWithChildren<IDroppableProps>) => {
  const { setNodeRef, isOver, active } = useDroppable({
    id: props.id,
  });

  useUpdateEffect(() => {
    if (isOver) {
      onEnter && onEnter(active);
    } else {
      onLeave && onLeave(active);
    }
  }, [isOver, onLeave, onEnter]);

  return (
    <div
      ref={setNodeRef}
      style={props.style}
      className={classNames(style.droppable, props.className)}
    >
      {props.children}
    </div>
  );
};

export default Droppable;
