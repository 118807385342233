/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-10-22 10:06:58
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-22 13:40:04
 * @Description: 列表动画组件
 */
import React, { JSXElementConstructor, PropsWithChildren, useEffect, useState } from 'react';

interface Props {
  transitionDuration?: number; //动画的持续时间（以毫秒为单位）
  delay?: number; //动画之间的延迟（以毫秒为单位）。
  wrapperTag?: JSXElementConstructor<any>; // 覆盖包装 div 的 HTML 元素。
  childTag?: JSXElementConstructor<any>; //覆盖包裹在每个子元素周围的 HTML 元素。
  className?: string; //向父级容器添加一个className。
  childClassName?: string; // 为每个子容器添加一个className，允许您设置组件的直接子级的样式。
  visible?: boolean; // 如果未定义，则可以使用 visible 属性来控制淡入发生的时间。如果在淡入动画完成后设置为 false，则子项将一一淡出。
  onComplete?: () => any; //动画完成时要调用的回调。
}

const AnimationList = (props: PropsWithChildren<Props>) => {
  const [maxIsVisible, setMaxIsVisible] = useState(0);

  const delay = props.delay || 50,
    transitionDuration = props.transitionDuration || 400,
    visible = typeof props.visible === 'undefined' ? true : props.visible,
    WrapperTag = props.wrapperTag || 'div',
    ChildTag = props.childTag || 'div';

  useEffect(() => {
    let count = React.Children.count(props.children);
    if (!visible) {
      count = 0;
    }

    if (count == maxIsVisible) {
      const timeout = setTimeout(() => {
        if (props.onComplete) {
          props.onComplete();
        }
      }, transitionDuration);

      return () => clearTimeout(timeout);
    }

    const increment = count > maxIsVisible ? 1 : -1;
    const timeout = setTimeout(() => {
      setMaxIsVisible(maxIsVisible + increment);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay, maxIsVisible, visible, transitionDuration, props]);

  return (
    <WrapperTag className={props.className}>
      {React.Children.map(props.children, (child, i) => {
        return (
          <>
            {child && (
              <ChildTag
                className={props.childClassName}
                style={{
                  transition: `opacity ${transitionDuration}ms, transform ${transitionDuration}ms`,
                  transform: maxIsVisible > i ? 'none' : 'translateY(20px)',
                  opacity: maxIsVisible > i ? 1 : 0,
                }}
              >
                {child}
              </ChildTag>
            )}
          </>
        );
      })}
    </WrapperTag>
  );
};

export default AnimationList;
