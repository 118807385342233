import { Select, Form, Input, Cascader } from 'antd';
import { IInitUser, IRegion } from '@interfaces/i-system-init';
import { TAny } from '@interfaces/i-common';
import { UserType } from '@interfaces/enum';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { useUpdateEffect } from 'ahooks';
// import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

interface UserProps {
  user: IInitUser;
  regions: IRegion[];
  onFinish(user: IInitUser): void;
  onMessage(msg: string, type?: string): void;
}

const UserInfo = (props: UserProps, ref: React.Ref<FormInstance<TAny>>) => {
  const [form] = Form.useForm<IInitUser>();

  const handleFinish = (params: IInitUser) => {
    props.onFinish(params);
  };

  useUpdateEffect(() => {
    form.setFieldsValue({
      phone: props.user.phone,
      customerName: props.user.customerName,
    });
  }, [props.user.phone, props.user.customerName]);

  return (
    <Form
      ref={ref}
      className="config-config"
      key="envForm"
      form={form}
      labelCol={{ span: 3 }}
      initialValues={{
        phone: props.user.phone,
        // 客户名称
        customerName: props.user.customerName,
        // 所属地区
        region: '',
        // 用户类型
        userType: null,
      }}
      colon={false}
      requiredMark={false}
      onFinish={handleFinish}
      autoComplete="off"
    >
      <Form.Item
        label="用户类型"
        name="userType"
        validateFirst
        rules={[
          {
            required: true,
            message: '请选择用户类型',
          },
        ]}
      >
        <Select
          defaultActiveFirstOption={false}
          placeholder="请选择用户类型"
        >
          <Option value={UserType.business}>B端用户</Option>
          <Option value={UserType.government}>G端用户</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="主体账号"
        name="phone"
      >
        <Input
          autoComplete="off"
          disabled
          placeholder="请输入主体账号"
        />
      </Form.Item>
      <Form.Item
        label="客户名称"
        name="customerName"
        validateFirst
        rules={[
          {
            required: true,
            message: '请输入客户名称',
          },
        ]}
      >
        <Input
          autoComplete="off"
          allowClear
          showCount
          maxLength={30}
          disabled={!!props.user.customerName}
          placeholder="请输入客户名称"
        />
      </Form.Item>
      <Form.Item
        label="所属地区"
        name="region"
        validateFirst
        rules={[
          {
            required: true,
            message: '请选择所属地区',
          },
        ]}
      >
        <Cascader
          options={props.regions}
          fieldNames={{
            label: 'name',
            value: 'region',
            children: 'children',
          }}
          changeOnSelect
          expandTrigger="hover"
          placeholder="请选择所属地区"
        />
      </Form.Item>
    </Form>
  );
};

export default React.forwardRef(UserInfo);
