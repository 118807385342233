/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-08 15:23:57
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-11-01 15:20:23
 * @Description: 桌面
 */
import React from 'react';
import './index.less';
import { useStore } from '@stores';
import AppWindow from '@components/app-window';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

const IndexContent: React.FC = () => {
  const { universalSettingStore, appWindowStore } = useStore();
  const { screenPosition, autoHide } = universalSettingStore;
  const { windowFullscreen } = appWindowStore;
  return (
    <div
      id="desktop"
      className={classNames(screenPosition, { autoHide: autoHide })}
      data-fullscreen={windowFullscreen}
    >
      <AppWindow />
    </div>
  );
};

export default observer(IndexContent);
