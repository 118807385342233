import ClassNames from 'classnames';
import React from 'react';

interface ILoginFormItemProps {
  name?: string;
  children?: React.ReactNode;
  error?: boolean;
  label?: string;
  showLabel?: boolean;
  topLabel?: boolean;
}

function LoginFormItem(
  props: ILoginFormItemProps = {
    name: '',
    error: false,
    label: '',
    showLabel: true,
    topLabel: false,
  },
) {
  return (
    <div className={ClassNames('login-form-item', { 'login-error': props.error })}>
      <label
        htmlFor={props.name}
        className={ClassNames('login-form-item__label', {
          'label-top': props.topLabel || props.error,
        })}
      >
        {props.label}
      </label>
      {props.children}
    </div>
  );
}

export default LoginFormItem;
