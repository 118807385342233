/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-25 17:03:53
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-05-26 10:11:58
 * @Description: 发生错误时——错误组件
 */

import { FallbackProps } from 'react-error-boundary';

/**
 * @description: 发生错误展示模板
 * @param {FallbackProps} param1
 * @return {*}
 */
const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div role="alert">
      <p>发生错误:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>重试</button>
    </div>
  );
};

//
/**
 * @description: onError自定方法，处理错误
 * @param {Error} error 报错信息
 * @param {object} info 报错具体信息
 * @return {*}
 */
const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.error(error);
  console.log(info);

  // 例如log到一个错误日志客户端
};

export { myErrorHandler, ErrorFallback };
