/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-08 11:33:32
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-21 13:58:46
 * @Description: 应用程序窗口主文件
 */
import React from 'react';
import { useStore } from '@stores';
import { observer } from 'mobx-react-lite';
import './index.less';
import ToolBar from './components/tool-bar';
import AppInfoStore from '@stores/app-window/app-store';
import { useCreation } from 'ahooks';
import WindowScreen from './components/window-screen';
import { WindowSize } from '@interfaces/enum';
import classNames from 'classnames';

const AppWindow: React.FC = (): JSX.Element => {
  const { appWindowStore, universalSettingStore, userStore } = useStore();
  const {
    windowSize,
    windowHide,
    windowClose,
    windowFullscreen,
    tabAppListJs,
    screenListJs,
    activeAppId,
    activeApp,
    closeApp,
    closeWindow,
    setActiveAppId,
    hideWindow,
    restoreWindow,
    maximizeWindow,
    dragTabApp,
    setIframePoint,
  } = appWindowStore;
  const { showName } = universalSettingStore;
  const { weatherKey, weatherUrl } = userStore;

  const toolBarDom = useCreation(
    () => (
      <ToolBar
        tabList={tabAppListJs}
        activeAppId={activeAppId}
        windowSize={windowSize}
        onMinimize={hideWindow}
        onRestore={restoreWindow}
        onMaximize={maximizeWindow}
        onClose={closeWindow}
        changeApp={setActiveAppId}
        closeApp={closeApp}
        dragTabApp={dragTabApp}
        showName={showName}
        setIframePoint={setIframePoint}
      />
    ),
    [tabAppListJs, activeAppId, windowSize, showName],
  );

  const ScreenDom = useCreation(
    () => (
      <WindowScreen
        activeApp={activeApp as AppInfoStore}
        screenList={screenListJs}
        windowFullscreen={windowFullscreen}
        serviceKey={{
          weatherService: { key: weatherKey, url: weatherUrl },
        }}
      />
    ),
    [activeApp, screenListJs, windowFullscreen, weatherKey, weatherUrl],
  );

  return (
    <div
      className={classNames('app-window', { restore: windowSize === WindowSize.restore })}
      data-hide={windowHide}
      data-close={windowClose}
    >
      {toolBarDom}
      {ScreenDom}
    </div>
  );
};

export default observer(AppWindow);
