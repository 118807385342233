/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-15 17:03:08
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-12 10:12:37
 * @Description: 描述
 */
import { IDataItem } from '@interfaces/i-common';
import AppSideBar from '@components/app-side-bar';
import AppMenu from '@components/app-menu';
import UniversalSettings from './universal-settings';
import Scrollbars from 'rc-scrollbars';
import React, { useState } from 'react';
import './index.less';
import SystemSettings from './system-settings';

const SettingManage: React.FC = (): JSX.Element => {
  const [activeId, setActiveId] = useState('universal');
  const menus: IDataItem[] = [
    {
      id: 'universal',
      label: '通用设置',
      icon: '&#xe887;',
      color: '#00dead',
    },
    {
      id: 'system',
      label: '系统设置',
      icon: '&#xe885;',
      color: '#2c90ff',
      permission: 'systemSetting',
    },
    // {
    //   id: 'oem',
    //   label: 'OEM配置',
    //   icon: '&#xeb78;',
    //   color: '#2ebcff',
    //   permission: '2',
    // },
  ];

  const settingManageBox: { [key: string]: JSX.Element } = {
    universal: <UniversalSettings />,
    system: <SystemSettings />,
  };

  const handleClick = (menu: IDataItem) => {
    setActiveId(menu.id);
  };

  return (
    <>
      <AppSideBar>
        <AppMenu
          menus={menus}
          activeId={activeId}
          onClick={handleClick}
        ></AppMenu>
      </AppSideBar>
      <div className="setting-manage">
        <Scrollbars autoHide>{settingManageBox[activeId]}</Scrollbars>
      </div>
    </>
  );
};

export default SettingManage;
