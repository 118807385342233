import style from './sidebar.module.less';
import classNames from 'classnames';
import { ISidebarMenuProps } from '@interfaces/i-system-init';

interface SidebarProps {
  menus: ISidebarMenuProps[];
  activeIndex: number;
}

const Sidebar = (props: SidebarProps) => {
  return (
    <ul className={style.sidebar}>
      {props.menus.map((menu, index) => {
        return (
          <li
            className={classNames(style.menu, props.activeIndex === index ? style.active : '')}
            key={menu.id}
          >
            <span>{menu.name}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default Sidebar;
