/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-08-01 11:22:17
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-22 10:49:58
 * @Description: 描述
 */
import DaqIcon from '@components/daq-icon';
import './add-apps.less';

interface IProps {
  onClick?: () => void;
}
const AddApps = (props: IProps): JSX.Element => {
  return (
    <div
      className="add-apps"
      onClick={props.onClick}
    >
      <DaqIcon icon="&#xe87d;" /> 添加应用
    </div>
  );
};
export default AddApps;
