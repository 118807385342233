import { useDebounceFn } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import React, { useState } from 'react';
import { TAny } from '@interfaces/i-common';
import SystemTip from '@views/index/components/system-tip';
import { createPortal } from 'react-dom';
import DaqSoftTrack from '@utils/daqsoft-track';
import { message as Message } from 'antd';

enum WsMessageType {
  account = 'account',
  app = 'app',
  weather = 'weatherFixed',
}

// 客户端时使用的后台地址
const clientApiUrl = import.meta.env.VITE_CLIENT_API_URL || '';
// 客户端时api前缀，不存在表示客户端链接开发环境后台
const clientApiTarget = import.meta.env.VITE_API_TARGET || '';

interface IMessage {
  topic: WsMessageType;
  event: string;
  msg: string;
  data: TAny;
}

const WebsocketDom = () => {
  const { userStore, systemStore, appWindowStore } = useStore();
  // 控制websocket是否启用
  const [enable, setEnable] = useState(false);
  // 控制提示信息是否展示
  const [showTip, setShowTip] = useState(false);
  // 提示信息设置
  const [systemTip, setSystemTip] = useState({
    title: '',
    message: '',
    buttonText: '知道了',
    user: {
      avatar: '',
      phone: '',
    },
  });

  // 埋点信息
  const successTrack = (
    firstEventType: string,
    secondEventType: string,
    appId?: string,
    relationId?: string,
  ) => {
    DaqSoftTrack.track({
      firstEventType,
      secondEventType,
      appId,
      relationId,
      status: 1,
    });
  };

  // 处理账号信息
  const accountMessage = (message: IMessage) => {
    switch (message.event) {
      case 'logout':
        setSystemTip({
          title: '退出登录',
          message: message.msg,
          buttonText: '知道了',
          user: {
            avatar: userStore.avatar,
            phone: userStore.maskPhone,
          },
        });
        setShowTip(true);
        successTrack('退出登录', message.msg);
        userStore.logout();
        break;
      default:
        break;
    }
  };

  // 处理应用信息
  const appMessage = (message: IMessage) => {
    switch (message.event) {
      case 'openApp':
        // 开通app时，开始菜单中添加app
        systemStore.openAppUpdateStateMenu(message.data);
        // 更新任务栏app信息、更新打开的窗口信息
        appWindowStore.changeDockAndTab(message.data);
        break;
      case 'modify':
        // 修改应用信息时，更新开始菜单信息
        systemStore.updateStartMenuApp(message.data);
        // 更新任务栏app信息、更新打开的窗口信息
        appWindowStore.changeDockAndTab(message.data);
        break;
      case 'delete':
        // 删除应用信息时，更新开始菜单信息
        systemStore.changeStartMenuApp(message.data, 'delete');
        // 更新任务栏app信息、更新打开的窗口信息
        appWindowStore.changeDockAndTab(message.data, 'delete');
        break;
      default:
        break;
    }
  };

  const weatherMessage = (message: IMessage) => {
    switch (message.event) {
      // 主体修改了天气的地区、景区设置，员工需要更新天气相关信息
      case 'modify':
        // 有天气应用时才处理
        if (systemStore.weatherApp) {
          Message.info(message.msg);

          // 更新任务栏天气信息
          // 关闭天气侧边栏
          systemStore.emit('updateWeather');
        }
        break;
      default:
        break;
    }
  };

  const dealMessage = (message: IMessage) => {
    switch (message.topic) {
      case WsMessageType.account:
        accountMessage(message);
        break;
      case WsMessageType.app:
        appMessage(message);
        break;
      case WsMessageType.weather:
        console.log(message);
        weatherMessage(message);
        break;
      default:
        console.log('其它ws消息', message);
        break;
    }
  };

  const [heartbeatTimer, setHeartbeatTimer] = useState(0);
  // 最大重连次数  （重连次数 - 1）* 重连间隔 <= 心跳间隔
  const maxReconnectAttempts = 5;
  // 心跳时间间隔
  const heartbeatTime = 60 * 1000;
  const protocol =
    window.location.protocol.indexOf('https') === 0 || (clientApiUrl && clientApiTarget) ? 's' : '';
  const host = window.location.host;
  // 后台断开链接，会先执行onClose -> shouldReconnect -> Reconnect 超过次数 -> onReconnectStop
  const { sendJsonMessage, readyState } = useWebSocket(
    clientApiUrl
      ? `ws${protocol}://${clientApiUrl.split('//')[1]}/websocket/websocketServer/${
          userStore.unionCode
        }/${userStore.token}`
      : `ws${protocol}://${host}/websocket/websocketServer/${userStore.unionCode}/${userStore.token}`,
    {
      share: true,
      // 是否需要重连
      shouldReconnect: () => {
        // TODO 判断退出原因，处理是否重连
        return true;
      },
      // 重连次数
      reconnectAttempts: maxReconnectAttempts,
      // 重连间隔
      reconnectInterval: 5000,
      onReconnectStop(num) {
        // 重连结束
        if (num >= maxReconnectAttempts) {
          // setSystemTip({
          //   title: '网络异常',
          //   message: '请检查网络并刷新',
          //   buttonText: '知道了',
          //   user: {
          //     avatar: userStore.avatar,
          //     phone: userStore.maskPhone,
          //   },
          // });
          // setShowTip(true);
        }
      },
      onOpen() {
        // 心跳，数据整的太复杂了
        sendJsonMessage({
          topic: 'account',
          event: 'checkLoginInvalid',
          data: userStore.token,
        });
        setHeartbeatTimer(
          window.setInterval(() => {
            sendJsonMessage({
              topic: 'account',
              event: 'checkLoginInvalid',
              data: userStore.token,
            });
          }, heartbeatTime),
        );
      },
      onMessage(event) {
        try {
          const message = JSON.parse(event.data);
          dealMessage(message);
        } catch (e) {
          console.error(e);
        }
      },
      // onError() {
      //   console.log('onMessage');
      // },
      onClose() {
        clearInterval(heartbeatTimer);
        setHeartbeatTimer(0);
      },
    },
    enable,
  );

  // 根据登录加载状态来控制websocket的链接或断开
  React.useEffect(() => {
    setEnable(Boolean(userStore.token && systemStore.loaded));
  }, [userStore.token, systemStore.loaded]);

  // 鼠标移动表示用户在使用，发送消息给后台延长登录有效期
  const { run } = useDebounceFn(
    () => {
      sendJsonMessage({
        topic: 'account',
        event: 'mouseMove',
        data: userStore.token,
      });
    },
    {
      // 5秒时间内有操作则不触发
      wait: 1000,
      // 最大等待2分钟（即如果2分钟内用户一直移动鼠标，则也触发一次调用）
      maxWait: 2 * 60 * 1000,
    },
  );
  // websocket链接后则，监听鼠标移动事件，后台更新登录有效期 TODO 可能需要添加键盘事件监听
  React.useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      window.addEventListener('mousemove', run);
    } else if (readyState === ReadyState.CLOSED) {
      window.removeEventListener('mousemove', run);
    }
  }, [readyState, run]);

  return (
    <div
      className="websocket-dom"
      style={{
        display: 'none',
      }}
    >
      {createPortal(
        <SystemTip
          {...systemTip}
          show={showTip}
          onClose={(show) => {
            setShowTip(show);
          }}
        ></SystemTip>,
        document.body,
      )}
    </div>
  );
};

export default observer(WebsocketDom);
