import style from './index.module.less';
import WeatherBox from '@views/weather/components/weather-box';
import { IDayWeather } from '@interfaces/i-weather';
import { useMount, useUpdateEffect } from 'ahooks';
import moment from 'moment';
import { useState } from 'react';
import DaqEmpty from '@components/daq-empty';

const Weather7days = ({ infos }: { infos: IDayWeather[] }) => {
  const todayDate = moment();
  const today = todayDate.format('YYYY-MM-DD');
  const [data, setData] = useState<
    {
      name: string;
      date: string;
      img: string;
      max: number;
      min: number;
    }[]
  >([]);

  useMount(() => {
    setData(
      infos.map((item) => {
        const date = moment(item.fxDate);
        let week = '';
        switch (date.day()) {
          case 0:
            week = '周日';
            break;
          case 1:
            week = '周一';
            break;
          case 2:
            week = '周二';
            break;
          case 3:
            week = '周三';
            break;
          case 4:
            week = '周四';
            break;
          case 5:
            week = '周五';
            break;
          case 6:
            week = '周六';
            break;
          default:
            break;
        }
        return {
          name: item.fxDate === today ? '今日' : week,
          date: item.fxDate.slice(5),
          img: todayDate.hour() > 18 || todayDate.hour() < 6 ? item.iconNightPic : item.iconDayPic,
          max: item.tempMax,
          min: item.tempMin,
        };
      }),
    );
  });

  useUpdateEffect(() => {
    setData(
      infos.map((item) => {
        const date = moment(item.fxDate);
        let week = '';
        switch (date.day()) {
          case 0:
            week = '周日';
            break;
          case 1:
            week = '周一';
            break;
          case 2:
            week = '周二';
            break;
          case 3:
            week = '周三';
            break;
          case 4:
            week = '周四';
            break;
          case 5:
            week = '周五';
            break;
          case 6:
            week = '周六';
            break;
          default:
            break;
        }
        return {
          name: item.fxDate === today ? '今日' : week,
          date: item.fxDate.slice(5),
          img: todayDate.hour() > 18 || todayDate.hour() < 6 ? item.iconNightPic : item.iconDayPic,
          max: item.tempMax,
          min: item.tempMin,
        };
      }),
    );
  }, [infos]);

  return (
    <WeatherBox title="未来7日天气">
      {infos.length ? (
        <ul className={style.weatherDays}>
          {data.map((item, index) => {
            return (
              <li
                className={style.weatherDay}
                key={index}
              >
                <p className={style.date}>
                  {item.name}
                  <span>（{item.date}）</span>
                </p>
                <div className={style.temperatureImg}>
                  <img
                    src={item.img}
                    alt="图片"
                  />
                </div>
                <div className={style.temperature}>
                  <span>{item.min}℃</span>
                  <div className={style.bar}></div>
                  <span>{item.max}℃</span>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <DaqEmpty></DaqEmpty>
      )}
    </WeatherBox>
  );
};

export default Weather7days;
