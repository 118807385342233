import './login-loading.less';
import classNames from 'classnames';

function LoginLoading({
  account,
  percent,
  className = '',
}: {
  account: string;
  percent: number;
  className?: string;
}) {
  return (
    <div className={classNames('login-loading', className)}>
      <div className="login-user">{account.replace(/^(\d{3})\d*(\d{4})$/, '$1****$2')}</div>
      <div className="logging">
        <p>玩命加载中...</p>
        <div className="logging-bar">
          <div className="wrapper"></div>
          <div
            className="bar"
            style={{ width: `${percent}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default LoginLoading;
