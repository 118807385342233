/*
 * 毛玻璃
 */
import React, { PropsWithChildren } from 'react';
import { IComponentCommonProps } from '@interfaces/i-common';
import './index.less';

interface IOnClick extends IComponentCommonProps {
  onContextMenu?(e: React.MouseEvent): void;
  onClick?(e: React.MouseEvent): void;
}
export type IFrostedGlassProps = PropsWithChildren<IOnClick>;

const FrostedGlass: React.FC<IFrostedGlassProps> = (props): JSX.Element => {
  return (
    <div
      aria-hidden={true}
      className={`frosted-glass ${props.className || ''}`}
      onContextMenu={props.onContextMenu}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export default FrostedGlass;
