/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2023-02-03 15:43:09
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-22 16:57:35
 * @Description: 侧边栏通用组件
 */
import React, { PropsWithChildren, useRef /*useState*/ } from 'react';
import './index.less';
import { Drawer } from 'antd';
import { observer } from 'mobx-react-lite';
import Scrollbars from 'rc-scrollbars';
import desktop from '@assets/images/desktop/desktop.jpg';
import { useStore } from '@stores';
import { LoadingOutlined } from '@ant-design/icons';
import { /*useMemoizedFn,*/ useUpdateEffect } from 'ahooks';

interface IProps {
  loading?: boolean;
  width: number;
  open: boolean;
  // // 是否开启滚动效果
  // scroll: boolean;
  onClose?: () => void;
}

const DaqSidebar: React.FC<PropsWithChildren<IProps>> = ({
  // scroll,
  loading,
  open,
  onClose,
  width = 343,
  children,
}) => {
  const scrollbarRef = useRef<Scrollbars>(null);
  const { systemSettingStore, universalSettingStore } = useStore();
  const { isMasterSetupWallPager, wallPaper } = universalSettingStore;
  const { wallPaper: systemWallPaper } = systemSettingStore;

  useUpdateEffect(() => {
    setTimeout(() => {
      scrollbarRef?.current?.scrollToTop();
    }, 300);
  }, [open]);

  // const [timer, setTimer] = useState<number>(0);
  // 处理滚动回弹
  // const onWheel = useMemoizedFn((e: React.WheelEvent) => {
  //   if (!scroll) {
  //     return;
  //   }
  //
  //   const values = scrollbarRef?.current?.getValues();
  //   const $target = scrollbarRef?.current?.view;
  //
  //   clearTimeout(timer);
  //   if (values?.scrollTop === 0 && e.deltaY < 0) {
  //     // 向下滚动到顶部了
  //     $target?.style?.setProperty('transform', `translateY(100px)`);
  //     setTimer(
  //       window.setTimeout(() => {
  //         $target?.style?.setProperty('transform', 'translateY(0)');
  //       }, 200),
  //     );
  //   } else if (
  //     values &&
  //     values.scrollTop === values.scrollHeight - values.clientHeight &&
  //     e.deltaY > 0
  //   ) {
  //     // 向上滚动到底部了
  //     $target?.style?.setProperty('transform', `translateY(-100px)`);
  //     setTimer(
  //       window.setTimeout(() => {
  //         $target?.style?.setProperty('transform', 'translateY(0)');
  //       }, 200),
  //     );
  //   } else {
  //     $target?.style?.setProperty('transform', 'translateY(0)');
  //   }
  // });

  return (
    <Drawer
      width={width}
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      className="daq-sidebar"
      contentWrapperStyle={{
        backgroundImage: `url(${
          (isMasterSetupWallPager === 0 ? wallPaper : systemWallPaper) || desktop
        })`,
        backgroundPosition: 'top right',
      }}
      maskStyle={{
        backgroundColor: 'transparent',
      }}
    >
      <Scrollbars
        ref={scrollbarRef}
        className="scroll-container"
        autoHide
      >
        <div
          className="daq-sidebar-content"
          // onWheel={onWheel}
        >
          {children}
        </div>
      </Scrollbars>
      {loading ? (
        <div className="loading">
          <LoadingOutlined />
          加载中...
        </div>
      ) : null}
    </Drawer>
  );
};
export default observer(DaqSidebar);
