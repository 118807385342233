import React, { useRef } from 'react';
import style from './multiple-app-list.module.less';
import { Carousel } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { useStore } from '@stores';
import { IAppStartMenu, IAppTaskbar } from '@interfaces/i-app-window';
import AppInfoStore from '@stores/app-window/app-store';
import AppList from '@components/app-list';
import useAutoPaging from '@hooks/use-auto-paging';
import useWheelTurnPageThrottle from '@hooks/use-wheel-turn-page-throttle';
import { observer } from 'mobx-react-lite';
import { errorTip, infoTip } from '@utils/error';
import api from '@api';
import AppInfo from '@components/app-info';
import DaqSoftTrack from '@utils/daqsoft-track';

const MultipleAppList = ({
  mainApp,
  apps,
  ...props
}: {
  mainApp: IAppStartMenu | undefined;
  apps: IAppStartMenu[];
  style?: React.CSSProperties;
}) => {
  const { systemStore, appWindowStore } = useStore();
  // 列表ref控制翻页
  const listRef = useRef<CarouselRef>(null);
  // 滚动事件节流，处理翻页
  const { run } = useWheelTurnPageThrottle({ listRef });

  const appBlock = {
    width: 228,
    height: 200,
  };
  const { pageInfo } = useAutoPaging({
    containerId: 'multipleAppList',
    appBlock,
    total: apps.length || 0,
  });
  const successTrack = (secondEventType: string, appId: string, relationId: string) => {
    DaqSoftTrack.track({
      firstEventType: '多开应用',
      secondEventType,
      appId,
      relationId,
      status: 1,
    });
  };
  const errorTrack = (
    secondEventType: string,
    appId: string,
    relationId: string,
    failReason: string,
  ) => {
    DaqSoftTrack.track({
      firstEventType: '多开应用',
      secondEventType,
      appId,
      relationId,
      failReason,
      status: 0,
    });
  };

  const handleClick = async (e: React.MouseEvent, app: IAppStartMenu) => {
    e.stopPropagation();

    if (app.serviceType === 2 && app.localServiceState === 0) {
      errorTip('应用部署中，请联系管理员！');
      errorTrack('打开应用', app.appId, app.relationId, '应用部署中');
      return;
    }

    // 处理过期
    if (app.tag === '已过期') {
      errorTip(`访问“${app.displayName}”失败，该应用已过期！`);
      errorTrack('打开应用', app.appId, app.relationId, '已过期');
      return;
    }

    // 应用正在获取中，则退出
    if (systemStore.gettingUrlApps.includes(app.appId)) {
      infoTip(`“${app.appName}”正在打开中，请稍后！`);
      return;
    } else {
      systemStore.addGettingUrlApp(app.appId);
    }

    try {
      // 获取访问地址，如果失败则退出
      const { data } = await api.third.getWebAppAccessUrl({
        relationId: app.relationId,
        appType: app.appType,
      });
      systemStore.removeGettingUrlApp(app.appId);
      successTrack('打开应用', app.appId, app.relationId);
      if (!data.url) {
        return;
      }
    } catch (e) {
      systemStore.removeGettingUrlApp(app.appId);
      errorTrack('打开应用', app.appId, app.relationId, String(e));
      return;
    }

    // 隐藏弹层
    systemStore.hideMenuLayer();
    if (mainApp) {
      appWindowStore.openApp(
        new AppInfoStore({ ...mainApp } as IAppTaskbar),
        new AppInfoStore({ ...app } as IAppTaskbar),
      );
    }
  };

  const renderMultipleAppPage = () => {
    const pages = [];
    for (let i = 0; i < pageInfo.pageCounts; i++) {
      const pageId = `multiplePage${i}`;
      const startIndex = i * pageInfo.pageSize;
      const pageApps = apps.slice(startIndex, startIndex + pageInfo.pageSize);

      pages.push(
        <div
          className={style.list}
          key={pageId}
        >
          <div
            className={style.panel}
            style={{
              left: `${pageInfo.padding}px`,
              width: `calc(100% - ${pageInfo.padding * 2}px)`,
              height: `${appBlock.height * pageInfo.rows}px`,
            }}
          ></div>
          <AppList padding={pageInfo.padding}>
            {pageApps.map((app) => (
              <li
                key={`${app.appUnionId}${app.relationId}`}
                className={style.appItem}
              >
                {/* 多开应用固定展示别名 */}
                <AppInfo
                  app={app}
                  onClick={handleClick}
                  showAlias={1}
                ></AppInfo>
              </li>
            ))}
          </AppList>
        </div>,
      );
    }

    return pages;
  };

  return (
    <div
      id="multipleAppList"
      style={props.style}
      className={style.multipleAppList}
      onWheel={run}
    >
      <Carousel
        ref={listRef}
        dots={{ className: style.appPointer }}
      >
        {pageInfo.pageCounts ? (
          renderMultipleAppPage()
        ) : (
          <div className={style.noContent}>暂无数据</div>
        )}
      </Carousel>
    </div>
  );
};

export default observer(MultipleAppList);
