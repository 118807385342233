import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores';

interface ISortableLiProps {
  uid: string | number;
  className?: string;
  animateDelay?: number;
}

const SortableLi: React.FC<PropsWithChildren<ISortableLiProps>> = ({
  uid,
  children,
  className = '',
  animateDelay = 0,
}) => {
  const { dragStore } = useStore();
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: uid,
    // 动画是否触发布局更新
    animateLayoutChanges: () => false,
  });
  const styles = {
    // 拖动过程中才使用transform和transition
    transform: dragStore.draggingId ? CSS.Translate.toString(transform) : '',
    // 组内拖动时不使用animateDelay都正常，开始菜单内拖动时会闪动，需要把非拖动元素都添加延时动画
    transition: dragStore.draggingId
      ? transition
      : dragStore.lastDragId === uid
      ? `opacity 0ms ease  ${animateDelay}ms`
      : `transform 0ms ease ${animateDelay}ms`,
    zIndex: isDragging ? 9999 : 0,
    cursor: isDragging ? 'grabbing' : 'pointer',
    opacity: isDragging ? 0 : 1,
  };

  return (
    <li
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={classNames(className)}
      style={styles}
      aria-hidden={true}
      id={`app${uid}`}
    >
      {children}
    </li>
  );
};

export default observer(SortableLi);
