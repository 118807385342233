/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-04 14:40:52
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-11-09 15:59:55
 * @Description: 描述
 */
import DaqIcon from '@components/daq-icon';
import { IOpenListRes } from '@interfaces/i-api-response';
import { ErrorImg } from '@utils';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React from 'react';
import './apps.less';

interface IProps extends IOpenListRes {
  types: string;
  onClick?: () => void;
}
const Apps: React.FC<Partial<IProps>> = ({
  appLogo,
  appName,
  displayName,
  state,
  dueDay,
  tag,
  dueTime,
  types,
  onClick,
}): JSX.Element => {
  return (
    <div
      className="apps"
      onClick={(e) => {
        if (state === 0) {
          return;
        }
        e.stopPropagation();
        onClick?.();
      }}
    >
      <div className="apps-logo">
        <img
          src={appLogo}
          onError={ErrorImg}
          alt="name"
        />
      </div>
      <div className="apps-info">
        <div className="apps-info-appName">{appName}</div>
        <div className="apps-info-displayName">
          <Tooltip
            title={displayName}
            mouseEnterDelay={1.5}
          >
            {displayName}
          </Tooltip>
        </div>
        <div
          className={classNames('apps-info-sub', {
            expired: state === 0,
            expire: (dueDay ?? 0) <= 30,
            add: types === 'add',
          })}
        >
          <DaqIcon icon="&#xe601;" />
          {types != 'add' && (
            <span className="info">{state === 0 ? '已过期' : tag ? tag : '长期有效'}</span>
          )}
          <span className="time">{tag === '建设中' ? '建设中' : dueTime}</span>
        </div>
      </div>
      <div className="apps-action">
        {/* {(dueDay ?? 0) <= 30 && <div className="apps-action-btn renewal">续费</div>} */}

        {/* <div className="apps-action-btn upgrade">升级</div> */}
      </div>
      {state !== 0 && (
        <div className="apps-show-info">
          <DaqIcon icon="&#xe87e;" />
        </div>
      )}
    </div>
  );
};
export default Apps;
