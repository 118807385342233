/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-22 13:59:45
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-14 10:54:21
 * @Description: 集成自定义滚动条和滚动加载功能
 */
import EndMessage from '@components/end-message';
import PageLoading from '@components/page-loading';
import { useDebounceFn, useMemoizedFn, useUpdateEffect } from 'ahooks';
import Scrollbars, { ScrollValues } from 'rc-scrollbars';
import React, { useState } from 'react';

interface IProps {
  className?: string;
  pageNo: number;
  pageTotal: number;
  recordCount: number;
  children: React.ReactNode;
  onNext: (pageNo: number) => void;
}
const InfiniteScroll: React.FC<IProps> = ({
  className,
  pageNo,
  pageTotal,
  recordCount,
  children,
  onNext,
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  useUpdateEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [loading]);
  const { run } = useDebounceFn(
    () => {
      if (pageNo === pageTotal) {
        return;
      }
      onNext?.(++pageNo);
    },
    { wait: 500 },
  );
  const handleScrollFrame = useMemoizedFn((values: ScrollValues) => {
    const { top } = values;
    if (top === 1) {
      if (pageTotal === 0 || pageNo === pageTotal || pageNo > pageTotal) {
        return;
      }
      setLoading(true);
      run();
    }
  });
  return (
    <Scrollbars
      className={className}
      autoHide
      style={{ height: 'none' }}
      onScrollFrame={handleScrollFrame}
    >
      {children}
      <>
        {loading && (
          <PageLoading
            pageNo={pageNo}
            recordCount={recordCount}
            pageTotal={pageTotal}
          />
        )}
        {!loading && pageNo > 1 && pageNo === pageTotal && <EndMessage />}
      </>
    </Scrollbars>
  );
};
export default InfiniteScroll;
