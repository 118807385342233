/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-26 10:13:04
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-09 09:47:21
 * @Description: mobx hooks出口文件
 */
import { useLocalObservable } from 'mobx-react-lite';
import * as React from 'react';
import { createStore, TStore } from './config';

const storeContext = React.createContext<TStore | null>(null);

export const StoreProvider = ({ children }: { children: React.ReactElement }) => {
  const store = useLocalObservable(createStore);
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useStore = (): TStore => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error('You have forgot to use StoreProvider.');
  }
  return store;
};
