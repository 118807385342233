/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-14 14:47:12
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-06-14 15:03:59
 * @Description: 防抖值useUpdateEffect
 */
import { useUpdateEffect, useDebounceFn } from 'ahooks';
import { useState } from 'react';
import type { DependencyList, EffectCallback } from 'react';

/**
 * 用来处理防抖值的 Hook。 根据ahooks useUpdateEffect(一个只在依赖更新时执行的 useEffect hook。)实现
 * @param {Function}  fn     需要触发的数据请求方法数组
 * @param {any[]}     args   依赖数组
 * @param  {number}   ms     防抖时长
 */
function useDebounceUpdateEffect(effect: EffectCallback, deps?: DependencyList, ms = 500) {
  const [flag, setFlag] = useState({});

  const { run } = useDebounceFn(
    () => {
      setFlag({});
    },
    { wait: ms },
  );

  useUpdateEffect(() => {
    return run();
  }, deps);

  useUpdateEffect(effect, [flag]);
}

export default useDebounceUpdateEffect;
