/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-17 14:47:30
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-11-08 09:19:50
 * @Description:  统一入口文件，配置额外根组件
 */

import React from 'react';
import '@styles/common.less';

import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, myErrorHandler } from '@components/error-boundary/error-boundary';

import { StoreProvider } from '@stores/index';
// 路由 暂时不需要路由
// import Routers from '@router/index';
// 桌面主文件
import Index from '@views/index';

// antd
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

// 日期组件，antd依赖
import moment from 'moment';
import 'moment/dist/locale/zh-cn';

moment.locale('zh-cn');

import '@utils/on-window-open';
import '@utils/on-close-window';
import '@utils/on-app-box-fullScreen';
import packageJson from '../package.json';
import DaqSoftTrack from '@utils/daqsoft-track';
import { useMemoizedFn, useMount, useUnmount } from 'ahooks';

DaqSoftTrack.setProductInfo('DaqOS', packageJson.version);

const App: React.FC = (): JSX.Element => {
  /**
   * windowBlur 主要是处理全屏时，开始菜单中两个单独的应用拖动组合为一个组的过程中，
   * 当触发了分组效果时，用户使用快捷键截屏，导致鼠标事件丢失，分组样式无法恢复的问题
   */
  const windowBlur = useMemoizedFn(() => {
    // 创建一个mouseup事件
    const event = new MouseEvent('mouseup');
    // 触发mouseup事件
    window.dispatchEvent(event);
  });

  useMount(() => {
    window.addEventListener('blur', windowBlur);
  });

  useUnmount(() => {
    window.removeEventListener('blur', windowBlur);
  });

  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={myErrorHandler}
      >
        <ConfigProvider locale={zhCN}>
          <StoreProvider>
            {/* <Routers /> */}
            <Index />
          </StoreProvider>
        </ConfigProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
