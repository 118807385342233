import React from 'react';
import { Button, Input, message /* Checkbox*/ } from 'antd';
import LoginFormItem from './login-form-item';
import { VerifyStatus } from '@interfaces/enum';

import './login-form.less';
import DaqIcon from '@components/daq-icon';
import { useImmer } from 'use-immer';
// import {CheckboxChangeEvent} from "antd/es/checkbox";
import api from '@api/index';
import { useUpdateEffect } from 'ahooks';
import { useStore } from '@stores';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { ILoginRes } from '@interfaces/i-api-response';
import { encrypt } from '@utils/encrypy';

interface ILoginFormProps {
  className?: string;
  verify: number;
  prevLogin(): void;
  loginFailed(account: string, message?: string): void;
  loginSuccess(account: string, res: ILoginRes): void;
}

const LoginForm = (props: ILoginFormProps) => {
  type LoginKey = 'account' | 'password';
  const ACCOUNT: LoginKey = 'account';
  const PASSWORD: LoginKey = 'password';

  // 判断值是否是login key
  function isLoginKey(key: string): boolean {
    return ['account', 'password'].includes(key);
  }

  const { userStore } = useStore();
  const [state, setState] = useImmer({
    logging: false,
    account: userStore.account,
    password: '',
    accountLabelTop: !!userStore.account,
    passwordLabelTop: false,
    rememberPwd: false,
    accountError: '',
    passwordError: '',
  });

  useUpdateEffect(() => {
    if (!state.accountError) {
      document.getElementById(ACCOUNT)?.focus();
    }
  }, [state.accountError]);

  useUpdateEffect(() => {
    if (!state.passwordError) {
      document.getElementById(PASSWORD)?.focus();
    }
  }, [state.passwordError]);

  useUpdateEffect(() => {
    if (props.verify === VerifyStatus.unverified) {
      setState((state) => {
        state.logging = false;
      });
    } else if (props.verify === VerifyStatus.verified) {
      handleLogin();
    }
  }, [props.verify]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue, id } = e.target;

    if (isLoginKey(id)) {
      const key = id as LoginKey;
      setState((state) => {
        state[key] = inputValue;
        // 优先判断当前输入框是否还是聚焦状态，是的保持label top，否则根据value是否为空来判断
        state[`${key}LabelTop`] =
          document.activeElement === document.getElementById(id) || !!inputValue;
      });
    }
  };

  // const handleCheck = (e: CheckboxChangeEvent) => {
  //   setState((state) => {
  //     state.rememberPwd = e.target.checked;
  //   });
  // };

  const handleFocus = (e: React.FocusEvent<HTMLElement>) => {
    const { id } = e.target;
    if (isLoginKey(id)) {
      setState((state) => {
        state[`${id as LoginKey}LabelTop`] = true;
      });
    }
  };
  const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
    const { id } = e.target;
    if (!isLoginKey(id)) {
      return;
    }

    const key = id as LoginKey;
    setState((state) => {
      state[`${key}LabelTop`] = !!state[key] || false;
    });
  };
  const handleErrorClick = (key: LoginKey) => {
    document.getElementById(key)?.focus();
    setState((state) => {
      state[`${key}Error`] = '';
    });
  };

  const prevLogin = () => {
    if (!state.account || !state.password) {
      return;
    }

    if (!state.logging) {
      setState((state) => {
        state.logging = true;
      });

      props.prevLogin();
    }
  };

  const handleLogin = async () => {
    const username = await encrypt(state.account);
    const password = await encrypt(state.password);

    // 登录接口获取所有预备信息
    api
      .login({
        username,
        password,
      })
      .then((result) => {
        props.loginSuccess(state.account, result.data);
      })
      .catch((error) => {
        // 处理错误
        if (error.message.includes('账号或密码错误')) {
          setState((state) => {
            state.passwordError = error.message;
          });
        } else if (error.message.includes('timeout')) {
          // message.error('登录超时');
          setState((state) => {
            state.passwordError = '登录超时';
          });
        } else {
          message.error(error.message);
        }

        props.loginFailed(state.account, error.message);
      })
      .finally(() => {
        setState((state) => {
          state.logging = false;
        });
      });
  };

  return (
    <div
      id="loginForm"
      className={classNames('login-form', props.className || '')}
    >
      <LoginFormItem
        name={ACCOUNT}
        label="账号"
        topLabel={state.accountLabelTop}
        error={Boolean(state.accountError)}
      >
        {state.accountError ? (
          <Input
            className="animate-shakeX"
            value={state.accountError}
            id={ACCOUNT}
            onClick={() => {
              handleErrorClick(ACCOUNT);
            }}
            prefix={
              <>
                <DaqIcon icon="&#xe870;" />
                <i className="line" />
              </>
            }
          />
        ) : (
          <Input
            aria-hidden={true}
            id={ACCOUNT}
            autoComplete="off"
            // autoFocus={!state.account}
            value={state.account}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            onPressEnter={prevLogin}
            maxLength={30}
            allowClear={true}
            prefix={
              <>
                <DaqIcon icon="&#xe870;" />
                <i className="line" />
              </>
            }
          />
        )}
      </LoginFormItem>
      <LoginFormItem
        name={PASSWORD}
        label="密码"
        topLabel={state.passwordLabelTop}
        error={Boolean(state.passwordError)}
      >
        {state.passwordError ? (
          <Input
            className="animate-shakeX"
            id={PASSWORD}
            value={state.passwordError}
            onClick={() => {
              handleErrorClick('password');
            }}
            prefix={
              <>
                <DaqIcon icon="&#xe871;" />
                <i className="line" />
              </>
            }
          />
        ) : (
          <Input.Password
            id={PASSWORD}
            autoComplete="new-password"
            // autoFocus={!!state.account}
            value={state.password}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            onPressEnter={prevLogin}
            maxLength={50}
            allowClear={true}
            visibilityToggle={false}
            prefix={
              <>
                <DaqIcon icon="&#xe871;" />
                <i className="line" />
              </>
            }
          />
        )}
      </LoginFormItem>
      <LoginFormItem showLabel={false}>
        {/*<Checkbox checked={state.rememberPwd} onChange={handleCheck}>*/}
        {/*  记住密码*/}
        {/*</Checkbox>*/}
      </LoginFormItem>
      <LoginFormItem showLabel={false}>
        <Button
          type="primary"
          onClick={prevLogin}
          loading={state.logging}
          disabled={
            !(state.account && !state.accountError && state.password && !state.passwordError)
          }
        >
          登录
        </Button>
      </LoginFormItem>
    </div>
  );
};

export default observer(LoginForm);
