/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-07 10:33:40
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-12 10:38:37
 * @Description: 描述
 */
import { IExternalLogListPagingParams } from '@interfaces/i-api-request';
import axios from '@utils/axiosInterceptor';
import { AxiosPromise } from 'axios';

// 公共变量
const config = 'admin/externalAppInfoOperateLog';

// 获取外部应用修改记录
export const getListPagingApi = (params: Partial<IExternalLogListPagingParams>): AxiosPromise => {
  return axios({
    url: `${config}/listPaging`,
    method: 'get',
    params,
  });
};
