import { Button, Form, Input, Popconfirm } from 'antd';
import { useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import { ISecurityForm, ISecurityProps, ISteps } from '@interfaces/i-user-center';
import Steps from './steps';
import { useImmer } from 'use-immer';
import api from '@api';
import regular from '@config/regular';

enum SmsType {
  verify,
  bind,
}

type SetPhoneProps = 'userInfo' | 'onCancel' | 'onOk' | 'submitting' | 'onMessage';

// 修改手机
const Phone = ({
  userInfo,
  onCancel,
  onOk,
  onMessage,
  submitting,
}: Required<Pick<ISecurityProps, SetPhoneProps>>) => {
  let countDownTimer = 0;
  const [verifyForm] = Form.useForm();
  const [bindForm] = Form.useForm();
  const newPhone = Form.useWatch('phone', bindForm);
  const newPassword = Form.useWatch('password', bindForm);
  const password = Form.useWatch('password', verifyForm);
  const [stepIndex, setStepIndex] = useState(0);
  const [state, setState] = useImmer({
    // 是否已经验证
    verifyPhone: false,
    // loading 验证码
    loadingCode: false,
    counter: 0,
  });

  useUpdateEffect(() => {
    if (state.counter) {
      countDownTimer = window.setInterval(() => {
        setState((state) => {
          if (state.counter <= 0) {
            clearInterval(countDownTimer);
            countDownTimer = 0;
          } else {
            state.counter -= 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(countDownTimer);
      countDownTimer = 0;
    };
  }, [state.counter]);

  const handleVerify = (data: ISecurityForm) => {
    if (!data.code || !data.password) {
      return;
    }

    api.userCenter
      .verifyCode(data.code, data.password)
      .then(() => {
        setState((state) => {
          state.counter = 0;
        });
        setStepIndex(1);
        // 清除错误
        onMessage('');
      })
      .catch((error) => {
        onMessage(error.message);
      });
  };
  const handleFinish = (data: ISecurityForm) => {
    onOk({
      password: data.password,
      phone: data.phone,
      code: data.code,
    });
  };

  const getVerifyCode = async (type: number, phone = '', password = '') => {
    setState((state) => {
      state.loadingCode = true;
    });
    try {
      if (type !== 1) {
        await api.userCenter.checkPwdApi(1, phone, password);
      }
      await api.userCenter.getSmsCode(type, phone);

      setState((state) => {
        state.counter = 60;
      });
      if (onMessage) {
        onMessage('短信验证码发送成功', 'success');
      }
    } catch (error) {
      let errorMessage = (error as Error).message;
      // 获取错误信息中的倒计时时间
      if (/操作太频繁，请[\d]+秒钟后再试/.test(errorMessage)) {
        const counter = Number(/\d+/.exec(errorMessage));
        if (!isNaN(counter) && counter > 0) {
          // 倒计时则不提示
          setState((state) => {
            state.counter = counter;
          });
          errorMessage = '操作太频繁，请稍后再试';
        }
      }
      onMessage(errorMessage);
    }
    setState((state) => {
      state.loadingCode = false;
    });
  };

  const steps: ISteps[] = [
    {
      label: '安全验证',
      id: 'securityVerify',
    },
    {
      label: '新绑定手机',
      id: 'bindPhone',
    },
  ];

  const labelWidth = 7;
  const inputWidth = 17;

  return (
    <>
      <Steps
        activeIndex={stepIndex}
        steps={steps}
      ></Steps>
      <Form
        style={{
          display: stepIndex === 0 ? 'block' : 'none',
          marginTop: '20px',
        }}
        key="verifyForm"
        form={verifyForm}
        initialValues={{
          phone: userInfo.phone,
          userName: userInfo.username,
          password: '',
          code: '',
        }}
        colon={false}
        requiredMark={false}
        labelCol={{ span: labelWidth }}
        wrapperCol={{ span: inputWidth }}
        onFinish={handleVerify}
        autoComplete="off"
      >
        <Form.Item
          label="解绑手机号"
          name="phone"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="平台账号"
          name="userName"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="登录密码"
          name="password"
          rules={[
            {
              required: true,
              message: '请输入密码',
            },
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder="请输入密码"
            allowClear
            maxLength={20}
          />
        </Form.Item>
        <Form.Item label="安全验证">
          <Input.Group style={{ display: 'flex' }}>
            <Form.Item
              name="code"
              noStyle
              validateFirst
              rules={[
                {
                  required: true,
                  message: '请输入短信验证码',
                },
                {
                  pattern: regular.messageAuthCode.pattern,
                  message: regular.messageAuthCode.message,
                },
              ]}
            >
              <Input
                style={{ width: `${state.counter ? 300 - 53 : '198'}px` }}
                allowClear
                maxLength={6}
                placeholder="请输入短信验证码"
              />
            </Form.Item>
            <Button
              type="primary"
              disabled={Boolean(state.counter) || password === ''}
              onClick={() => getVerifyCode(SmsType.verify, userInfo.phone, password)}
              loading={state.loadingCode}
            >
              {state.counter ? `${state.counter}s` : '获取验证码'}
            </Button>
          </Input.Group>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: labelWidth,
            span: inputWidth,
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
          >
            下一步
          </Button>
          {onCancel ? <Button onClick={() => onCancel('phone')}>取消</Button> : null}
        </Form.Item>
      </Form>
      <Form
        style={{ display: stepIndex === 0 ? 'none' : 'block' }}
        key="bindForm"
        form={bindForm}
        initialValues={{
          userName: userInfo.username,
          phone: '',
          password: '',
          code: '',
        }}
        colon={false}
        requiredMark={false}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          label="平台账号"
          name="userName"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="绑定新手机号"
          name="phone"
          validateFirst
          rules={[
            {
              required: true,
              message: '请输入新手机号码',
            },
            {
              pattern: regular.phone.pattern,
              message: regular.phone.message,
            },
            {
              validator: (rule, value) => {
                return value !== userInfo.phone
                  ? Promise.resolve()
                  : Promise.reject(new Error('新手机号不能和原手机号一样'));
              },
            },
          ]}
        >
          <Input
            autoComplete="off"
            maxLength={11}
            placeholder="请输入新手机号"
          />
        </Form.Item>
        <Form.Item
          label="登录密码"
          name="password"
          validateFirst
          rules={[
            {
              required: true,
              message: '请输入密码',
            },
            {
              validator: (rule, value) => {
                return value !== password
                  ? Promise.resolve()
                  : Promise.reject(new Error('新密码不能和原密码一样'));
              },
            },
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder="请输入密码"
            allowClear
            maxLength={20}
          />
        </Form.Item>
        <Form.Item label="安全验证">
          <Input.Group>
            <Form.Item
              name="code"
              noStyle
              validateFirst
              rules={[
                {
                  required: true,
                  message: '请输入短信验证码',
                },
                {
                  pattern: regular.messageAuthCode.pattern,
                  message: regular.messageAuthCode.message,
                },
              ]}
            >
              <Input
                maxLength={6}
                style={{ width: `${state.counter ? 300 - 53 : '198'}px` }}
              />
            </Form.Item>
            <Button
              type="primary"
              disabled={
                Boolean(state.counter) ||
                !/^1[3-9]\d{9}$/.test(newPhone) ||
                newPhone === userInfo.phone ||
                password === newPassword ||
                !password
              }
              onClick={() => getVerifyCode(SmsType.bind, newPhone)}
              loading={state.loadingCode}
            >
              {state.counter ? `${state.counter}s` : '获取验证码'}
            </Button>
          </Input.Group>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: labelWidth,
            span: inputWidth,
          }}
        >
          <Button
            type="primary"
            loading={submitting}
            htmlType="submit"
          >
            保存
          </Button>
          <Popconfirm
            title="确认取消「解绑手机号」操作吗?"
            onConfirm={() =>
              setTimeout(() => {
                onCancel('phone');
              }, 0)
            }
          >
            <Button>取消</Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </>
  );
};

export default Phone;
