/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-08-16 10:42:30
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-07 17:03:17
 * @Description: 描述
 */
import DaqIcon from '@components/daq-icon';
import React from 'react';
import './index.less';
interface IProps {
  onBack?: () => void;
  onForward?: () => void;
  canGoBack: boolean;
  canGoForward: boolean;
}
const NavigationBtn: React.FC<IProps> = ({
  onBack,
  onForward,
  canGoBack,
  canGoForward,
}): JSX.Element => {
  return (
    <div className="nav">
      <div
        className={`nav-back ${!canGoBack ? 'nav-disabled' : ''}`}
        onClick={onBack}
      >
        <DaqIcon icon="&#xf07d;" />
      </div>

      <div
        aria-hidden
        className={`nav-forward ${!canGoForward ? 'nav-disabled' : ''}`}
        onClick={onForward}
      >
        <DaqIcon icon="&#xe624;" />
      </div>
    </div>
  );
};

export default NavigationBtn;
