import { useThrottleFn } from 'ahooks';
import React from 'react';
import { DaqCarouselRef } from '@interfaces/i-carousel';
import { CarouselRef } from 'antd/lib/carousel';

export default function useWheelTurnPageThrottle({
  listRef,
  interval = 500,
}: {
  listRef: React.RefObject<DaqCarouselRef | CarouselRef>;
  interval?: number;
}) {
  return useThrottleFn(
    (e: React.WheelEvent<HTMLDivElement>) => {
      e.stopPropagation();

      // 只处理纯滚动
      if (!e.ctrlKey && !e.altKey && !e.shiftKey && !e.metaKey) {
        if (e.deltaY > 0) {
          // 向下滚动，左翻页
          listRef?.current?.next();
        } else if (e.deltaY < 0) {
          // 向下滚动，右翻页
          listRef?.current?.prev();
        }
      }
    },
    { wait: interval },
  );
}
