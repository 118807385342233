/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-05-17 14:47:30
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-25 16:49:08
 * @Description: 主文件 入口
 */
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
// import { browserTabLife } from '@utils/browser-monitor';

import App from './App';
import './index.css';
import desktop from '@assets/images/desktop/desktop.jpg';
import { preloadImg } from '@utils';
import { getSystemInitStatus } from '@api/api-system-init';
import { initSettingApi } from '@api/api-common';
import systemSettingStore from '@stores/system-setting/system-setting-store';
import universalSettingStore from '@stores/universal-setting/universal-setting-store';
import { message } from 'antd';
import config from '@config';

const container = document.getElementById('root') as HTMLDivElement;

const initSystem = async () => {
  // 先确定系统是否已经初始化
  try {
    const imgList: string[] = [desktop];
    const { data: systemInit } = await getSystemInitStatus();
    window.systemInitialized = !systemInit;
    const userInfo: { unionCode: string } = JSON.parse(
      window.localStorage.getItem(config.storageKey.account) as string,
    );

    // 在获取系统设置信息
    const { data: settingInit } = await initSettingApi(userInfo.unionCode);
    if (settingInit?.loginLogo) {
      imgList.push(settingInit.loginLogo);
    }
    if (settingInit?.startMenuButton) {
      imgList.push(settingInit.startMenuButton);
    }
    let isMaster = 0;
    if (settingInit?.wallPaper) {
      imgList.push(settingInit.wallPaper);
      isMaster = 1;
    }
    universalSettingStore.setIsMasterSetupWallPager(isMaster);

    systemSettingStore.setSystemSetting(settingInit);
    // 在预加载系统图片
    await preloadImg(imgList);
    // 在组件挂载到root前处理进度条进度为100%
    if (window.systemProgressBar) {
      window.systemProgressBar.style.width = '100%';
      clearInterval(window.systemProgressBarTimer);
    }

    const root = createRoot(container);
    root.render(
      <StrictMode>
        <App />
      </StrictMode>,
    );
  } catch (error) {
    console.error(error);

    message.error('网络异常，请稍后再试');
  }
};

if (container) {
  // 监听浏览器标签生命 TODO 内联到index.html
  // browserTabLife();

  // 使用vite的define全局变量
  // console.log(MODE);
  // 使用vite的环境变量
  // const dev = import.meta.env;
  // console.log(dev);

  initSystem();
}
