/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-07 19:33:28
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-22 11:32:03
 * @Description: 任务栏组件
 */
import DaqIcon from '@components/daq-icon';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Avatar, Button, Modal, Tooltip } from 'antd';
import { useStore } from '@stores';
import { TooltipPlacement } from 'antd/lib/tooltip';
import avatar from '@assets/images/avatar.png';
import DaqSoftTrack from '@utils/daqsoft-track';
import DockWeather from './dock-weather';

interface IProps {
  placement: TooltipPlacement;
  showDate: boolean;
}
const DockClock: React.FC<IProps> = ({ placement, showDate }): JSX.Element => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userStore } = useStore();
  const [clock, setClock] = useState({
    time: '00:00',
    year: '00/00',
  });

  useEffect(() => {
    const interval = setInterval(
      () =>
        setClock({
          time: moment().format('HH:mm'),
          year: moment().format('MM/DD'),
        }),
      1000,
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <DockWeather placement={placement} />
      <div id="taskbar-right-tool">
        {(showDate || placement === 'top') && (
          <div className="date">
            <div className="date-time">{clock.time}</div>
            <div className="date-year">{clock.year}</div>
          </div>
        )}
        <Tooltip
          getPopupContainer={() => document.getElementById('taskbar') as HTMLElement}
          placement={placement}
          title="退出"
        >
          <div
            className="off"
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            <DaqIcon icon="&#xe645;" />
          </div>
        </Tooltip>
        {/* <div className="message">
          <DaqIcon icon="&#xe65b;" />
        </div> */}
      </div>

      <Modal
        transitionName="ant-fade"
        title="退出管理"
        width={280}
        className="dock-modal"
        destroyOnClose
        closable={false}
        open={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <Avatar
          src={userStore.avatar || avatar}
          size={74}
          icon={<DaqIcon icon="&#xe870;" />}
        />
        <div className="account">{userStore.maskPhone}</div>
        {/* <div className="dock-modal-message">确认退出当前账号登录</div> */}
        <div className="dock-modal-message">&nbsp;</div>
        <Button
          type="primary"
          style={{ marginBottom: '20px' }}
          block
          onClick={() => {
            DaqSoftTrack.track({
              firstEventType: '退出登录',
              secondEventType: '修改密码退出',
              status: 1,
            });
            userStore.logout();
          }}
        >
          退出登录
        </Button>
        <Button
          block
          onClick={() => {
            DaqSoftTrack.track({
              firstEventType: '退出登录',
              secondEventType: '修改密码退出',
              status: 1,
            });
            userStore.logout();
            // 埋点放在on-close-window.ts  兼容mac与windows
            window?.electronAPI?.windowClosed();
          }}
        >
          关闭系统
        </Button>
      </Modal>
    </>
  );
};

export default React.memo(DockClock);
