/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-08 10:43:01
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-20 09:41:58
 * @Description: 描述
 */
import CheckButton from '@components/check-button';
import CheckGroup from '@components/check-group';
import ContainerCard from '@components/container-card';
import DaqIcon from '@components/daq-icon';
import { IDStaffProps } from '@interfaces/i-staff-manage';
import { Button, Form, Tabs } from 'antd';
import Scrollbars from 'rc-scrollbars';
import React from 'react';
import './list-modify.less';

const RoleListDetail: React.FC<IDStaffProps> = ({
  detail,
  className,
  onBack,
  // onRemove,
  onRevise,
}): JSX.Element => {
  const layout = { wrapperCol: { span: 6 } };
  const items = [
    {
      label: '云服务',
      key: 'cloudAppList',
      children: (
        <div className="staff-list-detail-app">
          {detail && detail?.cloudAppList?.length > 0 ? (
            <CheckGroup
              options={detail?.cloudAppList ?? []}
              value="appId"
              label="displayName"
              disabled
            />
          ) : (
            '暂无数据'
          )}
        </div>
      ),
    },
    {
      label: '本地服务',
      key: 'localAppList',
      children: (
        <div className="staff-list-detail-app">
          {detail && detail?.localAppList?.length > 0 ? (
            <CheckGroup
              options={detail?.localAppList ?? []}
              value="appId"
              label="displayName"
              disabled
            />
          ) : (
            '暂无数据'
          )}
        </div>
      ),
    },
  ];

  const isEmpty = (() => {
    const AppList = detail?.externalAppList ?? [];
    let isEmpty = true;
    for (let i = 0; i < AppList?.length; i++) {
      if (AppList[i]?.selected) {
        isEmpty = false;
      }
    }
    return isEmpty;
  })();

  const externalList = {
    label: '添加应用',
    key: 'externalAppList',
    children: (
      <div className="staff-list-detail-app">
        {!isEmpty
          ? detail?.externalAppList?.map((item) => {
              if (item?.selected) {
                return (
                  <CheckButton
                    key={item.appId}
                    active={item?.selected}
                    value={item?.appId ?? ''}
                    label={item.displayName}
                    disabled
                  />
                );
              }
            })
          : '暂无数据'}
      </div>
    ),
  };

  return (
    <div className={`list-modify staff-list-modify ${className} list-detail`}>
      <div
        className="list-modify-back"
        onClick={() => {
          onBack?.({ type: 'close' });
        }}
      >
        <DaqIcon icon="&#xe660;" />
        查看详情
      </div>
      <div className="list-modify-content">
        <Scrollbars autoHide>
          <ContainerCard
            title="基本信息"
            className="staff-list-modify-content-card"
          >
            <Form {...layout}>
              <Form.Item label="平台账号">
                <span className="ant-form-text">{detail?.userName || '-'}</span>
              </Form.Item>
              <Form.Item label="员工姓名">
                <span className="ant-form-text">{detail?.realName || '-'}</span>
              </Form.Item>
              <Form.Item label="手机号码">
                <span className="ant-form-text">{detail?.phone || '-'}</span>
              </Form.Item>
              <Form.Item label="邮箱地址">
                <span className="ant-form-text-long">{detail?.email || '-'}</span>
              </Form.Item>
              {/* <Form.Item
                name="deptName"
                label="所属部门"
              >
                <span className="ant-form-text">{detail?.deptName || '-'}</span>
              </Form.Item> */}
            </Form>
          </ContainerCard>
          <div className="staff-list-modify-content-line"></div>
          <ContainerCard
            title="管理应用"
            className="staff-list-modify-content-card"
          >
            <Form>
              <Form.Item label="">
                <Tabs
                  items={
                    detail && detail?.externalAppList?.length > 0 ? [...items, externalList] : items
                  }
                />
              </Form.Item>
              <Form.Item label="角色权限">
                <span className="ant-form-text">{detail?.roleName || '-'}</span>
              </Form.Item>
              <Form.Item label="备注描述">
                <span className="ant-form-text-long">{detail?.remark || '-'}</span>
              </Form.Item>
              <Form.Item
                name="des"
                label="操作时间"
              >
                <span className="ant-form-text">{detail?.gmtModified || '-'}</span>
                <span className="ant-form-text modifierName">
                  操作员：{detail?.modifierName || '-'}
                </span>
              </Form.Item>
            </Form>
          </ContainerCard>
        </Scrollbars>
      </div>

      <div className="list-modify-btn">
        <Button
          type="primary"
          onClick={() => {
            onRevise?.(detail?.userId ?? '');
          }}
        >
          修改
        </Button>
        {/* <Popconfirm
          title="您确定要删除此员工吗？"
          okText="确定"
          cancelText="取消"
          onConfirm={() => {
            onRemove?.(detail?.userId ?? '');
          }}
        >
          <Button className="btn-white">删除</Button>
        </Popconfirm> */}
        <Button
          htmlType="button"
          onClick={() => {
            onBack?.({ type: 'close' });
          }}
        >
          取消
        </Button>
      </div>
    </div>
  );
};

export default RoleListDetail;
