/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-10 10:30:45
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-22 15:41:01
 * @Description: 应用程序窗口-Screen组件
 */
import React from 'react';
import AppInfoStore from '@stores/app-window/app-store';
import classNames from 'classnames';
import { AppType } from '@interfaces/enum';
import AppBox from './app-box';
import { IAppInfoStore } from '@interfaces/i-app-window';
import { WEATHER_APP_CODE } from '@utils/constant';
import { IServiceKey } from '@interfaces/i-common';

interface IToolTab {
  windowFullscreen: boolean;
  activeApp: AppInfoStore;
  screenList: IAppInfoStore[];
  serviceKey: { [key: string]: IServiceKey };
}

const WindowScreen: React.FC<IToolTab> = ({
  activeApp,
  screenList,
  windowFullscreen,
  serviceKey,
}): JSX.Element => {
  // 扩展后期存在即是内置又是外部应用
  const serviceApp = [WEATHER_APP_CODE];
  const classFn = () => {
    if (serviceApp.includes(activeApp?.code)) {
      return !serviceKey?.[activeApp?.code]?.key;
    } else {
      return activeApp?.appType === AppType.builtIn;
    }
  };
  return (
    <div
      className={classNames('window-screen', {
        // 内置应用
        'build-in-app': classFn(),
      })}
      data-fullscreen={windowFullscreen}
    >
      {screenList.map((item) => {
        if (!item.open) {
          return;
        }
        return (
          <div
            className={`app-box ${item?.appUnionId === activeApp?.appUnionId ? 'show' : 'hide'}`}
            key={item?.appUnionId}
          >
            <AppBox
              AppInfo={item}
              activeApp={activeApp}
              windowFullscreen={windowFullscreen}
              serviceKey={serviceKey}
            />
          </div>
        );
      })}
    </div>
  );
};

export default WindowScreen;
