/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-07 19:33:28
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-06 16:57:44
 * @Description: 任务栏app
 */
import ContextMenu from '@components/context-menu';
import MouseMenu from '@components/mouse-menu';
import { CSSProperties } from '@interfaces/i-css';
import { AlignType } from '@interfaces/i-universal-setting';
import AppInfoStore from '@stores/app-window/app-store';
import { ErrorImg, showNameFN } from '@utils';
import { useDebounceFn, useMount, useUpdateEffect } from 'ahooks';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';
import { getPalette } from 'react-img-contrast';
import { useStore } from '@stores';
import DaqIcon from '@components/daq-icon';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface IDockApp {
  showMenu: string;
  position: AlignType;
  appInfo: AppInfoStore;
  activeAppId: string;
  itemMargin: number;
  windowHide: boolean;
  dragOverlay: boolean;
  showName: number;
  contextMenuClick: (appInfo: AppInfoStore, type: string) => void;
  onContextMenu: (event: React.SyntheticEvent<HTMLDivElement>, info: AppInfoStore) => void;
  appItemClick: (appInfo: AppInfoStore, index?: number) => void;
}
const DockApp: React.FC<IDockApp> = ({
  showMenu,
  position,
  appInfo,
  activeAppId,
  itemMargin,
  windowHide,
  showName,
  dragOverlay,
  contextMenuClick,
  onContextMenu,
  appItemClick,
}): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, isSorting } =
    useSortable({
      id: `dockApp-${appInfo.appUnionId}`,
    });

  const itemStyles = useMemo(() => {
    if (dragOverlay) {
      return {};
    }
    return position === 'top' || position === 'bottom'
      ? {
          marginLeft: itemMargin * 1,
          marginRight: itemMargin * 1,
        }
      : {
          marginTop: itemMargin * 1,
          marginBottom: itemMargin * 1,
        };
  }, [position, itemMargin, dragOverlay]);

  const [showMouse, setShowMouse] = useState(false);
  const [appColor, setAppColor] = useState('');
  const { systemStore } = useStore();

  const { run } = useDebounceFn(
    (value: boolean) => {
      setShowMouse(value);
    },
    { wait: 100 },
  );

  const getAppColor = async (url: string) => {
    let res;
    try {
      const colors = await getPalette({ imgSrc: url, quality: 3 });
      res = colors[2];
    } catch {
      res = [172, 172, 172];
    }
    setAppColor(`rgba(${res.toString()},0.7)`);
  };

  useMount(() => {
    getAppColor(appInfo.appLogo);
  });

  const styles = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 10 : undefined,
    opacity: isDragging && !dragOverlay ? 0 : undefined,
  };

  //  解决放开拖拽，mouse还在bug
  useUpdateEffect(() => {
    if (!isSorting) {
      setShowMouse(false);
    }
  }, [isSorting]);

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      data-open={appInfo.open && !appInfo.bounce}
      // 当前激活的app在窗口显示且开始菜单不显示时，显示高亮
      data-active={
        !windowHide &&
        appInfo.appUnionId === activeAppId &&
        !systemStore.menuShow &&
        !appInfo.bounce
      }
      className={classNames(`pointer dock-item`, position, {
        bounce: appInfo.open && appInfo.bounce,
      })}
      style={
        {
          // 新增默认值，解决初始化图片变大问题
          width: '0px',
          height: '0px',
          '--background-color': appColor,
          ...itemStyles,
          ...styles,
        } as CSSProperties
      }
      key={`dockapp-${appInfo.appUnionId}`}
      onClick={(e) => {
        e.stopPropagation();
        appItemClick(appInfo);
      }}
      onContextMenu={(event) => {
        onContextMenu(event, appInfo);
        setShowMouse(false);
      }}
      onMouseEnter={() => {
        if (showMenu !== '') {
          return;
        }
        run(true);
      }}
      onMouseLeave={() => {
        if (showMenu !== '') {
          return;
        }
        run(false);
      }}
    >
      <div className="dock-logo">
        <img
          className="dock-logo-img"
          src={appInfo.appLogo}
          alt="LOGO"
          onError={ErrorImg}
        />
        {appInfo.openAppNum > 1 ? (
          <DaqIcon
            className="daq-icon multiple"
            icon="&#xec1c;"
          ></DaqIcon>
        ) : null}
      </div>
      <ContextMenu
        position={position}
        showMenu={showMenu}
        appInfo={appInfo}
        MenuClick={contextMenuClick}
      />
      {showMenu === '' && !isDragging && (
        <MouseMenu
          position={position}
          showMouse={showMouse}
          appName={showNameFN(appInfo, showName)}
        />
      )}
      {dragOverlay && (
        <MouseMenu
          position={position}
          showMouse={false}
          appName={'取消底部显示'}
        />
      )}
    </div>
  );
};

export default observer(DockApp);
