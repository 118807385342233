import React, { useState, useRef, useEffect, CSSProperties } from 'react';
import './index.less';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores';
import classNames from 'classnames';
import DockClock from './components/dock-clock';
import DockApp from './components/dock-app';
import AppInfoStore from '@stores/app-window/app-store';
import { Tooltip } from 'antd';
import { useDebounceFn, useEventListener, useMemoizedFn, useSize, useUpdateEffect } from 'ahooks';
import api from '@api';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { reaction } from 'mobx';
import HideTaskbar from './components/hide-taskbar';
import { AppType } from '@interfaces/enum';
import { IAppStartMenu, IAppTaskbar } from '@interfaces/i-app-window';
import { errorTip } from '@utils/error';
import startLogo from '@assets/images/taskbar/start-logo.png';
import { TRASH_ID, DOCK_APP_CONTAINER_ID } from '@utils/constant';

import { DragOverlay, useDndMonitor, useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import DockTrash from './components/dock-trash';
import { createPortal } from 'react-dom';
import { isDockApp } from '@utils';
import mergeRefs from '@utils/mergeRefs';
import DaqSoftTrack from '@utils/daqsoft-track';

const defaultWidth = 0; // 设置为：0 解决图标初始化图标太大

const placement = {
  bottom: 'top',
  left: 'right',
  right: 'left',
  top: 'bottom',
};

const screenPositionKey: { [key: string]: string } = {
  top: 'marginBottom',
  bottom: 'marginBottom',
  left: 'marginBottom',
  right: 'marginBottom',
};

type Size = {
  width: number;
  height: number;
};

interface TaskBarProps {
  handleStartMenu(): void;
}

const TaskBar: React.FC<TaskBarProps> = ({ handleStartMenu }): JSX.Element => {
  // dock也需要进行碰撞
  const { setNodeRef } = useDroppable({ id: DOCK_APP_CONTAINER_ID });

  const { universalSettingStore, appWindowStore, systemStore, systemSettingStore, dragStore } =
    useStore();
  // 布局相关
  const { screenPosition, autoHide, moveShow, isDockBig, showName, setMoveShow } =
    universalSettingStore;
  const { dragInfo } = dragStore;
  // 应用程序窗口相关
  const {
    dockList,
    dockLength,
    dockListJs,
    dockIdList,
    windowHide,
    activeAppId,
    closeApp,
    cancelFixedApp,
    // dragAddDockApp,
    // removeDockApp,
    // dragDockApp,
    showMenu,
    setShowMenu,
  } = appWindowStore;

  // 初始化
  const [dockData, setDockData] = useState({
    size: defaultWidth, // 图标默认大小
    scaleNum: defaultWidth * 1.7, // 图标放大系数
    itemMargin: 10, // 元素之间距离
    distance: 0, //底部距离
  });

  const [dockStyle, setDockStyle] = useState({});

  // let showTimer = 0;
  const [showTimer, setShowTimer] = useState(0);
  const dockRef = useRef<HTMLDivElement>(null);
  const taskbarRef = useRef<HTMLDivElement>(null);
  // 获取dock宽高，动态计算app大小
  const dockSize = useSize(taskbarRef);

  const successTrack = (
    secondEventType: string,
    thirdEventType: string,
    appId: string,
    relationId: string,
  ) => {
    DaqSoftTrack.track({
      firstEventType: '底部任务栏',
      thirdEventType,
      secondEventType,
      appId,
      relationId,
      status: 1,
    });
  };
  const errorTrack = (
    secondEventType: string,
    thirdEventType: string,
    appId: string,
    relationId: string,
    failReason: string,
  ) => {
    DaqSoftTrack.track({
      firstEventType: '底部任务栏',
      thirdEventType,
      secondEventType,
      appId,
      relationId,
      failReason,
      status: 0,
    });
  };

  const getOffset = useMemoizedFn((el: HTMLElement, offset: 'top' | 'left'): number => {
    const elOffset = offset === 'top' ? el.offsetTop : el.offsetLeft;
    if (el.offsetParent == null) {
      return elOffset;
    }
    return elOffset + getOffset(el.offsetParent as HTMLElement, offset);
  });

  // 根据动效原理，监听 mousemove 事件
  const mousemove = useMemoizedFn(({ clientX, clientY }: { clientX: number; clientY: number }) => {
    if (!dockRef.current || showMenu !== '') {
      return;
    }
    const imgList = dockRef.current.childNodes;
    for (let i = 0; i < imgList.length; i++) {
      const img = imgList[i] as HTMLDivElement;
      // x:点击处距离图标中心的横向距离
      // y:点击处距离图标中心的纵向距离
      let x, y;
      if (screenPosition === 'bottom') {
        x = img.offsetLeft + dockData.size / 2 - clientX;
        y = img.offsetTop + getOffset(dockRef.current, 'top') + img.offsetHeight / 2 - clientY;
      } else if (screenPosition === 'right') {
        x = img.offsetTop + dockData.size / 2 - clientY;
        y = img.offsetLeft + getOffset(dockRef.current, 'left') + img.offsetWidth / 2 - clientX;
      } else {
        x = img.offsetTop + dockData.size / 2 - clientY;
        y = img.offsetLeft + dockData.size / 2 - clientX;
      }
      let imgScale = 1 - Math.sqrt(x * x + y * y) / (imgList.length * dockData.size);
      if (imgScale < dockData.size / dockData.scaleNum) {
        imgScale = dockData.size / dockData.scaleNum;
      }
      const multiplier = dockData.scaleNum / dockData.size;
      if (dockData.scaleNum / dockData.size) {
        // dock 栏图标动态宽度 = dock 栏图标原本宽度 * 放大系数 * （ 1 - 鼠标到图标中心的距离 / dock 栏长度）
        img.style.height = img.style.width = dockData.size * multiplier * imgScale + 'px';
      }
    }
  });

  const mouseleave = useMemoizedFn(() => {
    if (!dockRef.current) {
      return;
    }
    const imgList = dockRef.current.childNodes;
    const key: string = screenPositionKey[screenPosition] as string;
    // 根据应用图标多少自动改变大小
    if (['top', 'bottom'].includes(screenPosition)) {
      setDockStyle({
        height: dockData.size * 1 + 12,
        [key]: dockData.distance * 1,
      });
    } else if (['left', 'right'].includes(screenPosition)) {
      setDockStyle({
        width: dockData.size * 1 + 12,
        [key]: dockData.distance * 1,
      });
    }

    for (let i = 0; i < imgList.length; i++) {
      const img = imgList[i] as HTMLDivElement;
      img.style.width = img.style.height = dockData.size + 'px';
    }
    if (universalSettingStore.loading) {
      setTimeout(() => {
        universalSettingStore.loadingDock(false);
      }, 0);
    }
  });

  const DynamicDockApp = useMemoizedFn(async (dockSize: Size | undefined, appLength: number) => {
    // app-margin与app-width 比例为4:1
    const dockWidth: number = (dockSize?.width ?? 1920) - 420;
    const dockHeight: number = (dockSize?.height ?? 976) - 320;
    let margin = 0;
    let itemMargin = 0;

    if (['top', 'bottom'].includes(screenPosition)) {
      margin = dockWidth / appLength / 6;
    }
    if (['left', 'right'].includes(screenPosition)) {
      margin = dockHeight / appLength / 6;
    }
    itemMargin = margin > 10 ? 10 : margin;

    document.documentElement.style.setProperty('--dock-width', 4 * itemMargin + 'px');
    document.documentElement.style.setProperty('--dock-scale', (4 * itemMargin).toString());
    // return {
    //   size: 4 * itemMargin,
    //   itemMargin,
    // };
    setDockData({
      size: 4 * itemMargin,
      scaleNum: 4 * itemMargin * 1.7, // 图标放大系数
      itemMargin, // 元素之间距离
      distance: 0, //底部距离
    });
  });

  // 初始化图标
  useEffect(() => {
    if (!dockSize) {
      return;
    }
    DynamicDockApp(dockSize, dockLength);
  }, [DynamicDockApp, dockSize, screenPosition, dockLength]);

  useUpdateEffect(() => {
    mouseleave();
  }, [dockData.size, screenPosition, dockListJs]);

  useEffect(() => {
    if (!dockRef.current || !isDockBig) {
      return;
    }
    const dockBackground: HTMLDivElement = dockRef.current;
    dockBackground.addEventListener('mouseenter', mousemove);
    dockBackground.addEventListener('mousemove', mousemove);
    dockBackground.addEventListener('mouseleave', mouseleave);
    return () => {
      dockBackground.removeEventListener('mouseenter', mousemove);
      dockBackground.removeEventListener('mousemove', mousemove);
      dockBackground.removeEventListener('mouseleave', mouseleave);
    };
  }, [mousemove, mouseleave, isDockBig]);

  // 打开app进行节流,避免频繁点击  TODO 不用防抖，用节流，但是useThrottleFn有问题
  const { run } = useDebounceFn(
    (item: AppInfoStore) => {
      dockItemClick(item);
    },
    { wait: 300 },
  );

  /**
   * 处理任务栏点击打开app流程
   * 对于多开应用列表：
   * 如果开始菜单、多开应用列表都处于显示状态，点击任务栏的未打开的多开应用时保持多开应用列表显示状态，隐藏开始菜单；
   * 如果开始菜单未显示，多开应用列表处于显示状态，点击任务栏未打开的多开应用时，同一个多开应用则切换显示隐藏，不是同一个则切换多开应用列表
   */
  const appChange = useMemoizedFn(async (item: AppInfoStore) => {
    const startMenuShow = systemStore.startMenuShow;
    // 当窗口和菜单（开始菜单或者多开应用列表）都处于显示状态时
    if (systemStore.menuShow && !windowHide) {
      if (systemStore.startMenuShow) {
        systemStore.hideStartMenu();
      }
      // 打开应用时，隐藏分组
      if (systemStore.appGroupShow) {
        systemStore.hideAppGroup();
      }

      if (appWindowStore.tabExist(item.appUnionId) !== -1) {
        // 如果app已经打开，只需要隐藏开始菜单和多开应用列表，然后展示app即可
        if (systemStore.multipleAppMenuShow) {
          systemStore.hideMultipleAppMenu();
        }

        appWindowStore.showOpenedApp(item.appUnionId);
        return;
      }
    }

    // TODO 处理过期
    if (item.tag === '已过期') {
      const errorText = `访问应用“${item.appName}”失败，该应用已过期！`;
      errorTip(errorText);
      errorTrack('点击', '打开应用', item.appId, item.relationId, errorText);
      return;
    }

    // 打开应用时，隐藏分组
    if (systemStore.appGroupShow) {
      systemStore.hideAppGroup();
    }

    if (item.appUnionId === appWindowStore.activeAppId) {
      // 如果点击的是当前展示的应用，则切换窗口显示隐藏状态
      appWindowStore.toggleWindow();
    } else if (appWindowStore.tabExist(item.appUnionId) !== -1) {
      // 如果点击的是已打开的应用，则直接展示应用
      appWindowStore.showOpenedApp(item.appUnionId);
    } else {
      // 处理新打开应用的情况
      if (item.isMultipleApp) {
        // 打开多开应用列表（web应用且开通个数大于1时）
        if (!startMenuShow && systemStore.multipleAppSelected(item.appUnionId)) {
          // 当开始菜单没有显示时，如果当前点击的多开应用已经是选中展示的多开应用了，则切换显示隐藏
          systemStore.toggleMultipleAppMenu();
        } else {
          openMultipleAppMenu(item as IAppStartMenu);
        }
      } else {
        // 内置应用 或者 单开的web应用 外部应用
        if (systemStore.multipleAppMenuShow) {
          systemStore.hideMultipleAppMenu();
        }

        // 单开的web应用和外部应用特殊处理
        if ([AppType.externalIn, AppType.web].includes(item?.appType)) {
          try {
            // 获取访问地址，如果失败则退出
            const { data } = await api.third.getWebAppAccessUrl({
              relationId: item?.appType === AppType.externalIn ? item.appId : item.relationId,
              appType: item.appType,
            });
            if (!data.url) {
              successTrack('点击', '打开应用', item.appId, item.relationId);
              return;
            }
          } catch (e) {
            errorTrack('点击', '打开应用', item.appId, item.relationId, String(e));
            return;
          }
        }
        successTrack('点击', '打开应用', item.appId, item.relationId);
        // 打开App
        appWindowStore.openApp(new AppInfoStore({ ...item } as IAppTaskbar));
      }
    }
  });

  const dockItemClick = useMemoizedFn((item: AppInfoStore) => {
    if (!dockRef.current) {
      return;
    }
    appChange(item);
  });

  const contextMenuClick = useMemoizedFn(async (appInfo: AppInfoStore, type: string) => {
    if (showMenu !== '') {
      setShowMenu('');
    }

    // 应用开关
    if (type === 'isOpen') {
      if (appInfo.open) {
        // 已打开app，进行关闭
        closeApp(appInfo);
        successTrack('右键菜单', '关闭应用', appInfo.appId, appInfo.relationId);
        return;
      } else {
        // 未打开app则打开
        dockItemClick(appInfo);
        successTrack('右键菜单', '打开应用', appInfo.appId, appInfo.relationId);
      }
    }

    // 应用固定
    if (type === 'isFixed') {
      if (!appInfo.fixed) {
        setFixedAppDock(appInfo);
      } else {
        removeFixedAppDock(appInfo);
      }
    }
  });

  // app固定dock
  const setFixedAppDock = async (appInfo: AppInfoStore) => {
    try {
      const { data } = await api.taskbar.setAddApi(appInfo);
      successTrack('右键菜单', '新增置底应用', appInfo.appId, appInfo.relationId);
      appInfo.setId(data.id);
      appInfo.setFixedDock(true);
    } catch (error) {
      errorTrack('右键菜单', '新增置底应用', appInfo.appId, appInfo.relationId, String(error));
    }
  };

  // 删除app固定dock
  const removeFixedAppDock = (appInfo: AppInfoStore) => {
    try {
      if (!appInfo.id) {
        return;
      }
      cancelFixedApp(appInfo);
      api.taskbar.setDelApi({ id: appInfo.id });
      successTrack('右键菜单', '取消置底应用', appInfo.appId, appInfo.relationId);
    } catch (error) {
      errorTrack('右键菜单', '取消置底应用', appInfo.appId, appInfo.relationId, String(error));
    }
  };

  const handleContextMenu = (
    event: React.SyntheticEvent<HTMLDivElement>,
    appInfo: AppInfoStore,
  ) => {
    event.preventDefault();
    setShowMenu(appInfo.appUnionId);
  };

  // 右击菜单事件，判断是否在菜单进行关闭
  const handleClick = useMemoizedFn((event: MouseEvent) => {
    if (showMenu === '') {
      return;
    }
    const target = event.target as HTMLElement;
    const className = target?.className;
    const id = target?.id;
    const parentNode = target?.parentNode as HTMLElement;

    // 点击目标不在右键菜单里则关闭菜单
    if (
      (className && className?.startsWith('dock-item')) ||
      parentNode?.id?.startsWith('taskbar-docker')
    ) {
      return;
    }
    if (!id && !id?.startsWith('taskbar')) {
      setMoveShow(false);
    }

    setShowMenu('');
  });

  const handleScroll = useMemoizedFn(() => {
    if (showMenu !== '') {
      return;
    }
    setShowMenu('');
  });

  reaction(
    () => appWindowStore.windowHide,
    (windowHide) => {
      // app窗口显示时隐藏弹层
      if (!windowHide) {
        systemStore.hideMenuLayer();
      }
    },
  );

  // 绑定自动隐藏 离开taskbar 隐藏taskbar 关闭开始菜单隐藏
  const setTimer = useMemoizedFn(() => {
    const timer = window.setTimeout(() => {
      setMoveShow(false);
    }, 1500);
    setShowTimer(timer);
  });

  const clearDelayTimer = useMemoizedFn(() => {
    if (showTimer !== 0) {
      clearTimeout(showTimer);
      setShowTimer(0);
    }
  });

  useEventListener(
    'mouseleave',
    () => {
      if (!autoHide || showMenu !== '' || !moveShow || systemStore.startMenuShow) {
        return;
      }
      setTimer();
    },
    { target: taskbarRef },
  );
  useEventListener(
    'mouseenter',
    () => {
      clearDelayTimer();
    },
    { target: taskbarRef },
  );

  // 绑定右击菜单是否关闭事件
  useEventListener('click', handleClick, { target: document });
  useEventListener('scroll', handleScroll, { target: document });

  const openMultipleAppMenu = (app: IAppStartMenu) => {
    systemStore.setMultipleApp(app);
    systemStore.showMultipleAppMenu();

    // 任务栏打开多开应用列表时需要隐藏开始菜单
    systemStore.hideStartMenu();
    systemStore.hideAppGroup();
  };

  // 开始菜单拖动固定到任务栏app标记
  // const [dragFixedApp, setDragFixedApp] = useState(false);

  /**
   * 拖动的app是否在任务栏中（开始菜单拖动到新app到任务栏中且没有结束拖动时，是临时添加到任务栏，不算属于任务栏）
   * @param draggingId 正在拖动的app的拖动id
   */
  // const inTaskBar = (draggingId: string) => {
  //   // 只用任务栏app前缀的id来判断是否是任务栏中的app
  //   if (draggingId.includes(DOCK_APP_PREV_ID)) {
  //     return appWindowStore.getDockApp(draggingId);
  //   }
  //
  //   return false;
  // };

  // app是否在开始菜单中(包括所有应用)
  const inStartMenu = (id: string) => {
    return systemStore.getAppByUnionId(id);
  };

  useDndMonitor({
    onDragStart({ active }) {
      const activeId = active?.id as string;
      // 正在拖动app，则先关闭任务栏app右击菜单，同时解决tip框拖拽时出现
      setShowMenu('onBeforeDragStart');
      // 拖动的不是任务栏app则忽略
      if (!isDockApp(activeId)) {
        return;
      }

      // 拖动时，移除任务栏鼠标移动监听
      if (dockRef.current && isDockBig) {
        const dockBackground: HTMLDivElement = dockRef.current;
        dockBackground.removeEventListener('mouseenter', mousemove);
        dockBackground.removeEventListener('mousemove', mousemove);
        dockBackground.removeEventListener('mouseleave', mouseleave);
      }

      // 重置dock图标大小
      mouseleave();
    },
    onDragOver() {
      // TODO 占时屏蔽开始菜单拖入任务栏
      // const activeId = active?.id as string;
      // // 拖动的app是任务栏中的，在end中处理拖动结果
      //
      // // 只处理拖动的app是开始菜单中的时候
      // if (inStartMenu(activeId)) {
      //   // 如果over对象不存在，则返回
      //   if (!over) {
      //     // 检查是否有开始菜单拖动到任务栏标记，有则表示取消固定，删除任务栏的临时数据
      //     if (dragFixedApp) {
      //       const activeIndex = appWindowStore.getDockAppIndex(activeId);
      //       appWindowStore.deleteDockApp(activeIndex);
      //       setDragFixedApp(false);
      //     }
      //     return;
      //   }
      //
      //   const overId = over.id as string;
      //   // 开始菜单app拖动到任务栏
      //   if (overId === DOCK_APP_CONTAINER_ID) {
      //     // 判断当前拖动的开始菜单的app，是否已经在任务栏中了，如果不存在则添加，否则忽略该事件
      //     if (!appWindowStore.getDockApp(activeId)) {
      //       const app = systemStore.getAppByUnionId(activeId);
      //       if (app) {
      //         appWindowStore.addDockApp(new AppInfoStore(app));
      //         // 设置添加app到任务栏中的标记
      //         setDragFixedApp(true);
      //       }
      //     }
      //
      //     return;
      //   } else if (inTaskBar(overId)) {
      //     // 如果碰撞到了任务栏的app，则判断拖动app是否在app中，如果存在则排序，否则插入碰撞app所在位置
      //     const activeIndex = appWindowStore.getDockAppIndex(activeId);
      //     const overIndex = appWindowStore.getDockAppIndex(overId);
      //
      //     if (activeIndex < 0) {
      //       // 任务栏中不存在则添加到任务栏中over的index位置(处理开始菜单拖动到任务栏时，偶尔没有触发任务栏容器碰撞的情况)
      //       const app = systemStore.getAppByUnionId(activeId);
      //       if (app) {
      //         appWindowStore.addDockApp(new AppInfoStore(app), overIndex);
      //         // 设置添加app到任务栏中的标记
      //         setDragFixedApp(true);
      //       }
      //     } else {
      //       // 任务栏中已存在则排序
      //       if (activeIndex !== overIndex && activeIndex >= 0 && overIndex >= 0) {
      //         appWindowStore.sortDockApp(activeIndex, overIndex);
      //       }
      //     }
      //
      //     return;
      //   } else {
      //     // 其它情况：没有碰撞到，或者碰撞到了非任务栏中的app
      //     // 检查是否有开始菜单拖动到任务栏标记，有则取消固定
      //     if (dragFixedApp) {
      //       const activeIndex = appWindowStore.getDockAppIndex(activeId);
      //       appWindowStore.deleteDockApp(activeIndex);
      //       setDragFixedApp(false);
      //     }
      //   }
      // }
    },
    onDragEnd({ active, over }) {
      // 删除拖拽取消置顶框
      _dragElement?.classList.remove('dragOver');
      setShowMenu('');
      // 解决自动隐藏任务栏，拖拽后不自动隐藏
      setTimer();

      const activeId = active.id as string;
      const overId = (over?.id as string) || '';
      if (isDockApp(activeId)) {
        // 任务栏的拖动结束
        const startIndex = dragInfo.index;
        const endIndex = appWindowStore.getDockAppIndex(overId);
        if (endIndex >= 0 && startIndex >= 0 && startIndex !== endIndex) {
          // 拖动结束保存排序结果  TODO 处理异常
          appWindowStore.sortDockApp(startIndex, endIndex);
          api.taskbarSort.setSortAddApi(dockList).then(() => {
            successTrack('拖拽', '排序', activeId, '');
          });
        } else if (overId === TRASH_ID) {
          // 任务栏删除应用拖动结束
          const app = dockList[startIndex];
          if (app) {
            successTrack('拖拽', '取消置底', activeId, '');
            removeFixedAppDock(app);
          }
        }

        // 清除拖动信息
        dragStore.clearDragInfo();
      } else if (inStartMenu(activeId)) {
        // 开始菜单拖动到任务栏结束 TODO 占时屏蔽
        // 获取开始菜单app放入到任务栏中的索引位置
        // const activeIndex = appWindowStore.getDockAppIndex(activeId);
        // if (activeIndex < 0) {
        //   return;
        // }
        // if (isDockApp(overId)) {
        //   // end时over的id是任务栏的app或者是容器，则处理固定
        //   const appInfo = dockList[activeIndex];
        //   if (!appInfo.fixed) {
        //     // 如果不是固定状态，则先处理固定 TODO 处理异常
        //     await setFixedAppDock(appInfo);
        //   }
        //
        //   // 只保存结果数据（over事件中已经处理了数据） TODO 处理异常
        //   api.taskbarSort.setSortAddApi(dockList);
        //
        //   setDragFixedApp(false);
        //   dragStore.clearDragInfo();
        //   console.log('taskbar end');
        // }
      }

      // 拖动结束时，还原任务栏鼠标移动监听
      if (dockRef.current && isDockBig) {
        const dockBackground: HTMLDivElement = dockRef.current;
        dockBackground.addEventListener('mouseenter', mousemove);
        dockBackground.addEventListener('mousemove', mousemove);
        dockBackground.addEventListener('mouseleave', mouseleave);
      }
    },
  });

  const _dragElement: HTMLElement | null = document.getElementById('useDraggableInPortal');

  const renderSortableItemDragOverlay = (appInfo: AppInfoStore | null, dragOverlay = false) => {
    if (!appInfo) {
      return null;
    }

    return (
      <DockApp
        showMenu={showMenu}
        position={screenPosition}
        itemMargin={dockData.itemMargin}
        appInfo={appInfo}
        activeAppId={activeAppId}
        windowHide={windowHide}
        showName={showName}
        appItemClick={(info: AppInfoStore) => {
          // 关闭右键菜单
          if (showMenu !== '') {
            setShowMenu('');
          }
          run(info);
        }}
        onContextMenu={handleContextMenu}
        contextMenuClick={(info, type) => {
          contextMenuClick(info, type);
        }}
        key={`${dragOverlay ? 'dragOverlay-' : 'dock-'}${appInfo.appUnionId}`}
        dragOverlay={dragOverlay}
      />
    );
  };
  // 解决开始菜单过长，tooltip箭头偏移
  const TooltipPlacement = useMemoizedFn((): TooltipPlacement => {
    if (screenPosition === 'bottom') {
      if (systemSettingStore.startMenuName && systemSettingStore.startMenuName.length > 7) {
        return 'topLeft' as TooltipPlacement;
      }
    }
    return placement[screenPosition] as TooltipPlacement;
  });
  return (
    <>
      <div
        id="taskbar"
        className={classNames(screenPosition || 'bottom', {
          autoHide: autoHide,
          show: moveShow,
        })}
        data-loading={universalSettingStore.loading}
        ref={taskbarRef}
      >
        <Tooltip
          getPopupContainer={() => document.getElementById('taskbar') as HTMLElement}
          placement={TooltipPlacement()}
          arrowPointAtCenter
          title={!systemSettingStore.startMenuName ? '开始菜单' : systemSettingStore.startMenuName}
        >
          <div
            id="taskbar-left"
            aria-hidden
            data-show={systemStore.startMenuShow}
            className={classNames('start-menu-button', screenPosition, {})}
            style={{
              backgroundImage: `url(${
                !systemSettingStore.startMenuButton ? startLogo : systemSettingStore.startMenuButton
              })`,
            }}
            data-track-info={JSON.stringify({
              firstEventType: '底部任务栏',
              secondEventType: '开始菜单',
              status: 1,
            })}
            onClick={handleStartMenu}
          ></div>
        </Tooltip>

        <SortableContext
          items={dockIdList}
          strategy={
            ['top', 'bottom'].includes(screenPosition)
              ? horizontalListSortingStrategy
              : verticalListSortingStrategy
          }
        >
          <div
            id="taskbar-docker"
            ref={mergeRefs([dockRef, setNodeRef])}
            className={screenPosition}
            style={dockStyle as CSSProperties}
          >
            {dockList.map((appInfo: AppInfoStore) => renderSortableItemDragOverlay(appInfo))}
          </div>
        </SortableContext>
        {_dragElement &&
          createPortal(
            <DragOverlay
              adjustScale={false}
              dropAnimation={null}
            >
              {isDockApp(dragInfo.id)
                ? renderSortableItemDragOverlay(dockList[dragInfo.index], true)
                : null}
            </DragOverlay>,
            _dragElement,
          )}
        {isDockApp(dragInfo.id) ? (
          <DockTrash
            id={TRASH_ID}
            position={screenPosition}
            _dragElement={_dragElement}
          />
        ) : null}

        <div
          id="taskbar-right"
          className={screenPosition}
          style={dockStyle as CSSProperties}
        >
          <DockClock
            placement={placement[screenPosition] as TooltipPlacement}
            showDate={dockList.length < 20}
          />
        </div>
      </div>
      {autoHide && (
        <HideTaskbar
          id="hide-taskbar"
          className={screenPosition}
        />
      )}
    </>
  );
};
export default observer(TaskBar);
