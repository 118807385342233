/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-20 11:14:57
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-20 13:45:48
 * @Description: 绑定手机
 */
import { ISecurityForm, ISecurityProps } from '@interfaces/i-user-center';
import { Button, Form, Input } from 'antd';
import React from 'react';
import api from '@api';
import { useImmer } from 'use-immer';
import { useUpdateEffect } from 'ahooks';
import regular from '@config/regular';

enum SmsType {
  verify,
  bind,
}

type SetPhoneProps = 'phone' | 'onCancel' | 'onOk' | 'submitting' | 'onMessage';
const labelWidth = 7;
const inputWidth = 17;

const Phone: React.FC<Pick<ISecurityProps, SetPhoneProps>> = ({
  onOk,
  onMessage,
  submitting,
}): JSX.Element => {
  let countDownTimer = 0;
  const [bindForm] = Form.useForm();
  const newPhone = Form.useWatch('phone', bindForm);
  const [state, setState] = useImmer({
    // loading 验证码
    loadingCode: false,
    counter: 0,
  });

  useUpdateEffect(() => {
    if (state.counter) {
      countDownTimer = window.setInterval(() => {
        setState((state) => {
          if (state.counter <= 0) {
            clearInterval(countDownTimer);
            countDownTimer = 0;
          } else {
            state.counter -= 1;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(countDownTimer);
      countDownTimer = 0;
    };
  }, [state.counter]);

  const getVerifyCode = async (type: number, phone = '') => {
    setState((state) => {
      state.loadingCode = true;
    });
    api.userCenter
      .getSmsCode(type, phone)
      .then(() => {
        setState((state) => {
          state.counter = 60;
        });
        if (onMessage) {
          onMessage('短信验证码发送成功', 'success');
        }
      })
      .catch((error) => {
        let errorMessage = error.message;
        // 获取错误信息中的倒计时时间
        if (/操作太频繁，请[\d]+秒钟后再试/.test(errorMessage)) {
          const counter = Number(/\d+/.exec(errorMessage));
          if (!isNaN(counter) && counter > 0) {
            // 倒计时则不提示
            setState((state) => {
              state.counter = counter;
            });
            errorMessage = '操作太频繁，请稍后再试';
          }
        }

        onMessage?.(errorMessage);
      })
      .finally(() => {
        setState((state) => {
          state.loadingCode = false;
        });
      });
  };

  const handleFinish = (data: ISecurityForm) => {
    onOk({
      password: '',
      phone: data.phone,
      code: data.code,
    });
  };
  return (
    <Form
      key="bindForm"
      form={bindForm}
      initialValues={{
        phone: '',
        password: '',
        code: '',
      }}
      colon={false}
      requiredMark={false}
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17 }}
      onFinish={handleFinish}
      autoComplete="off"
    >
      <Form.Item
        label="手机号"
        name="phone"
        validateFirst
        rules={[
          {
            required: true,
            message: '请输入手机号码',
          },
          {
            pattern: regular.phone.pattern,
            message: regular.phone.message,
          },
        ]}
      >
        <Input
          autoComplete="off"
          maxLength={11}
          placeholder="请输入手机号"
        />
      </Form.Item>

      <Form.Item label="验证码">
        <Input.Group>
          <Form.Item
            name="code"
            noStyle
            validateFirst
            rules={[
              {
                required: true,
                message: '请输入短信验证码',
              },
              {
                pattern: regular.messageAuthCode.pattern,
                message: regular.messageAuthCode.message,
              },
            ]}
          >
            <Input
              maxLength={6}
              style={{ width: `${state.counter ? 300 - 53 : '198'}px` }}
            />
          </Form.Item>
          <Button
            type="primary"
            disabled={Boolean(state.counter) || !/^1[3-9]\d{9}$/.test(newPhone)}
            onClick={() => getVerifyCode(SmsType.bind, newPhone)}
            loading={state.loadingCode}
          >
            {state.counter ? `${state.counter}s` : '获取验证码'}
          </Button>
        </Input.Group>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: labelWidth,
          span: inputWidth,
        }}
      >
        <Button
          type="primary"
          loading={submitting}
          htmlType="submit"
        >
          保存
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Phone;
