import style from './local-app.module.less';
import { ILocalApp } from '@interfaces/i-system-init';

interface ILocalAppProps {
  apps: ILocalApp[];
}

const LocalApp = ({ apps }: ILocalAppProps) => {
  return (
    <ul className={style.localApp}>
      {apps.map((app) => {
        return (
          <li
            className={style.app}
            key={app.appId}
            title={app.appName || ''}
          >
            <div className={style.icon}>
              <img
                src={app.appLogo}
                alt=""
              />
            </div>
            <p className={style.name}>{app.appName || ''}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default LocalApp;
