import classNames from 'classnames';
import style from './index.module.less';
import React, { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { DaqCarouselRef } from '@interfaces/i-carousel';
import { useMemoizedFn, useSize, useUpdateEffect } from 'ahooks';
import { TurnPageDirection } from '@interfaces/enum';

interface ICarousel {
  // 页码，从1开始
  initPageNo?: number;
  // 总页数
  pageCounts: number;
  className?: string;
  style?: React.CSSProperties;
  // 滚动翻页后的回调
  afterTurnPage?(pageNo: number, direction: TurnPageDirection | undefined): void;
}

type ICarouselProps = PropsWithChildren<ICarousel>;

const DaqCarousel = React.forwardRef<DaqCarouselRef, ICarouselProps>(
  ({ pageCounts, ...props }, ref): JSX.Element => {
    // 当前页码
    const [pageNo, setPageNo] = useState(props.initPageNo ?? 1);
    // 轮播dom
    const contentRef = useRef<HTMLDivElement>(null);
    // 轮播器id
    const carouselId = useMemo(() => {
      return `carousel${Math.floor(Math.random() * 1000000)}`;
    }, []);
    // 获取轮播区域大小
    const containerSize = useSize(window.document.getElementById(carouselId));

    // 处理翻页
    const turnPage = (targetPageNo: number, animate = true) => {
      if (targetPageNo < 1) {
        targetPageNo = 1;
      } else if (targetPageNo > pageCounts) {
        targetPageNo = pageCounts;
      }

      // 处理滚动
      if (contentRef && contentRef.current) {
        const scrollLeft = (containerSize?.width || 0) * (targetPageNo - 1);
        // 动画
        if (animate) {
          contentRef.current.scroll({
            left: scrollLeft,
            behavior: 'smooth',
          });
        } else {
          contentRef.current.scrollLeft = scrollLeft;
        }
      }

      // 翻页方向
      const direction =
        targetPageNo > pageNo
          ? TurnPageDirection.next
          : targetPageNo < pageNo
          ? TurnPageDirection.prev
          : undefined;

      // 记录当前页码
      setPageNo(targetPageNo);

      props.afterTurnPage && props.afterTurnPage(targetPageNo, direction);
    };

    // 上一页
    const prev = () => {
      const prevPageNo = pageNo - 1;
      if (prevPageNo < 1) {
        return;
      }
      turnPage(prevPageNo);
    };

    // 下一页
    const next = () => {
      const nextPageNo = pageNo + 1;
      if (nextPageNo > pageCounts) {
        return;
      }
      turnPage(nextPageNo);
    };

    // ref暴露接口
    React.useImperativeHandle(ref, () => {
      return {
        getCurrentPageNo: () => {
          return pageNo;
        },
        goTo: turnPage,
        prev,
        next,
      };
    });

    // 监听容器大小变化，更新scroll left;
    // 监听分页数量变化，当前页码大于总页码时，选择最后一页
    useUpdateEffect(() => {
      turnPage(pageNo, false);
    }, [containerSize, pageCounts]);

    // 点击翻页
    const handleClick = useMemoizedFn((pageNo: number) => {
      turnPage(pageNo);
    });

    // 处理点击点
    const dots = new Array(pageCounts).fill(1);
    return (
      <div
        id={carouselId}
        style={props.style}
        aria-hidden={true}
        className={classNames(style.daqCarousel, props.className)}
      >
        <div
          ref={contentRef}
          className={style.daqCarouselContent}
          style={{
            width: `${window.innerWidth}px`,
          }}
        >
          {props.children}
        </div>
        {pageCounts > 1 ? (
          <ul className={style.slickDots}>
            {dots.map((item: number, dotIndex: number) => {
              return (
                <li
                  className={classNames(
                    style.slickDot,
                    pageNo === dotIndex + 1 ? style.active : '',
                  )}
                  key={dotIndex}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick(dotIndex + 1);
                  }}
                ></li>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  },
);

DaqCarousel.displayName = 'DaqCarousel';
export default DaqCarousel;
