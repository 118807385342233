/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-09-19 14:51:27
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-22 10:14:12
 * @Description: 系统设置
 */
import { setSaveApi } from '@api/api-general-setting';
import ContainerCard from '@components/container-card';
import DaqIcon from '@components/daq-icon';
import DaqImgUpload from '@components/daq-img-upload';
import { ISystemSetting } from '@interfaces/i-api-request';
import { useStore } from '@stores';
import { getValueFromEvent } from '@utils';
import DaqSoftTrack from '@utils/daqsoft-track';
import { useMemoizedFn } from 'ahooks';
import { Form, Input, InputRef, message, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useImmer } from 'use-immer';
import './index.less';

const SystemSettings: React.FC = (): JSX.Element => {
  const sysRef = useRef<InputRef>(null);
  const startRef = useRef<InputRef>(null);
  const { systemSettingStore, universalSettingStore } = useStore();
  const { setSystemSetting, ...systemParams } = systemSettingStore;
  const { setIsMasterSetupWallPager } = universalSettingStore;
  const initState = {
    editSystemName: false,
    editStartMenuName: false,
    systemName: systemParams.systemName,
    startMenuName: systemParams.startMenuName,
  };
  const [state, setState] = useImmer(initState);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const systemName = e.target.value;
    if (systemName === state.systemName) {
      setState(initState);
      return;
    }
    const messages = `系统名称`;
    let messagesType = '设置';
    if (systemName === '') {
      messagesType = '删除';
    }
    onChangeSetting({ systemName }, messages, messagesType);
  };
  const onChangeStartMenuName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const startMenuName = e.target.value;
    if (startMenuName === state.startMenuName) {
      setState(initState);
      return;
    }
    const messages = '开始菜单名称';
    let messagesType = '设置';
    if (startMenuName === '') {
      messagesType = '删除';
    }
    onChangeSetting({ startMenuName }, messages, messagesType);
  };
  const onLoginLogoChange = (loginLogo: string) => {
    const messages = '初始登录LOGO';
    let messagesType = '设置';
    if (loginLogo === '') {
      messagesType = '删除';
    }
    onChangeSetting({ loginLogo }, messages, messagesType);
  };
  const onSecondLoginLogoChange = (secondLoginLogo: string) => {
    const messages = '二次登录LOGO';
    let messagesType = '设置';
    if (secondLoginLogo === '') {
      messagesType = '删除';
    }
    onChangeSetting({ secondLoginLogo }, messages, messagesType);
  };
  const onWallPaperChange = (wallPaper: string) => {
    let isMaster = 1;
    const messages = '桌面背景图';
    let messagesType = '设置';
    if (wallPaper === '') {
      messagesType = '删除';
      isMaster = 0;
    }
    setIsMasterSetupWallPager(isMaster);
    onChangeSetting({ wallPaper }, messages, messagesType);
  };
  const onStartMenuButtonChange = (startMenuButton: string) => {
    const messages = '开始菜单图标';
    let messagesType = '设置';
    if (startMenuButton === '') {
      messagesType = '删除';
    }
    onChangeSetting({ startMenuButton }, messages, messagesType);
  };

  const setSave = useMemoizedFn(
    async (params: ISystemSetting, messages: string, messagesType: string) => {
      try {
        await setSaveApi(params);
        message.success(`${messagesType}${messages}成功`);
        setState(() => ({
          editSystemName: false,
          editStartMenuName: false,
          startMenuName: params.startMenuName,
          systemName: params.systemName,
        }));
        DaqSoftTrack.track({
          firstEventType: '系统设置',
          secondEventType: messages,
          thirdEventType: messagesType,
          status: 1,
        });
      } catch (error) {
        setState(() => state);
        DaqSoftTrack.track({
          firstEventType: '系统设置',
          secondEventType: messages,
          thirdEventType: messagesType,
          status: 0,
        });
      }
    },
  );

  const onChangeSetting = (obj: Partial<ISystemSetting>, message: string, messagesType: string) => {
    setSystemSetting({ ...systemParams, ...obj });
    setSave({ ...systemParams, ...obj }, message, messagesType);
  };

  const TextInput = ({ value, type }: { value: string; type: string }) => {
    return (
      <div
        className="system-settings-input"
        onClick={() => {
          if (type === 'systemName') {
            setState((_) => {
              _.editSystemName = true;
            });
            window.setTimeout(() => {
              sysRef.current?.focus({
                cursor: 'all',
              });
            }, 0);
          } else {
            setState((_) => {
              _.editStartMenuName = true;
            });
            window.setTimeout(() => {
              startRef.current?.focus({
                cursor: 'all',
              });
            }, 0);
          }
        }}
      >
        <span> {value}</span>
        <span>
          <DaqIcon icon="&#xe60a;" />
        </span>
      </div>
    );
  };
  return (
    <div className="system-settings">
      <div className="system-settings-body">
        <ContainerCard
          title="系统名称"
          text="登录页左上角，开始菜单顶部显示名称"
        >
          {state.editSystemName || !systemParams.systemName ? (
            <Form>
              <Form.Item
                name="systemName"
                validateFirst
                getValueFromEvent={getValueFromEvent}
              >
                <Input
                  ref={sysRef}
                  defaultValue={systemParams.systemName}
                  showCount
                  maxLength={20}
                  placeholder="请输入"
                  allowClear
                  onBlur={onChangeName}
                  style={{ width: '410px' }}
                  autoComplete="off"
                />
              </Form.Item>
            </Form>
          ) : (
            <TextInput
              value={systemParams.systemName}
              type="systemName"
            />
          )}
        </ContainerCard>
        <ContainerCard title="登录LOGO">
          <div className="system-settings-logo">
            <div className="system-settings-logo-box">
              <div className="system-settings-logo-box-header">
                <Tooltip
                  title="图片不大于1MB，尺寸260*74px，建议png格式"
                  placement="right"
                >
                  初始登录 <DaqIcon icon="&#xe642;" />
                </Tooltip>
              </div>
              <DaqImgUpload
                isRemove
                imgStyle={{ width: '170px', height: 'fit-content', maxHeight: '100%' }}
                description="登录LOGO"
                initialValues={systemParams.loginLogo}
                className="ant-upload-common ant-upload-firstLogo"
                onChange={onLoginLogoChange}
              />
            </div>
            <div className="system-settings-logo-box-header">
              <div className="system-settings-logo-box-header">
                <Tooltip
                  title="图片不大于1MB，尺寸125*125px，建议png格式"
                  placement="right"
                >
                  二次登录 <DaqIcon icon="&#xe642;" />
                </Tooltip>
              </div>
              <DaqImgUpload
                isRemove
                description="登录LOGO"
                initialValues={systemParams.secondLoginLogo}
                className="ant-upload-common ant-upload-twoLogo"
                onChange={onSecondLoginLogoChange}
              />
            </div>
          </div>
        </ContainerCard>
      </div>
      <div className="system-settings-body">
        <ContainerCard
          title="桌面背景"
          text="图片不大于5MB，尺寸1920*1080px，建议png格式"
        >
          <DaqImgUpload
            isRemove
            description="桌面背景"
            initialValues={systemParams.wallPaper}
            className="ant-upload-common ant-upload-wallPaper"
            onChange={onWallPaperChange}
            sizeLimit={1024 * 1024 * 5}
          />
        </ContainerCard>
        <ContainerCard
          title="开始菜单"
          text="图片不大于1MB，尺寸60*60px，建议png格式"
        >
          <DaqImgUpload
            isRemove
            description="开始菜单"
            imgStyle={{ width: '60px', height: '60px' }}
            initialValues={systemParams.startMenuButton}
            className="ant-upload-common ant-upload-startLogo"
            onChange={onStartMenuButtonChange}
          />
          {state.editStartMenuName || !systemParams.startMenuName ? (
            <Form>
              <Form.Item
                name="startMenuName"
                validateFirst
                getValueFromEvent={getValueFromEvent}
              >
                <Input
                  ref={startRef}
                  showCount
                  maxLength={20}
                  defaultValue={systemParams.startMenuName}
                  placeholder="请输入 “开始菜单” 名称"
                  allowClear
                  autoComplete="off"
                  onBlur={onChangeStartMenuName}
                  style={{ width: '410px' }}
                />
              </Form.Item>
            </Form>
          ) : (
            <TextInput
              value={systemParams.startMenuName}
              type="startMenuName"
            />
          )}
        </ContainerCard>
      </div>
    </div>
  );
};

export default observer(SystemSettings);
