/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-08-01 18:31:41
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-22 17:23:33
 * @Description: 描述
 */
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/es/upload';
import config from '@config';
import { message, Upload } from 'antd';
import React, { CSSProperties, useState } from 'react';
import './index.less';
import ImgCrop from 'antd-img-crop';
import DaqIcon from '@components/daq-icon';

interface IProps {
  // 是否裁剪
  className?: string;
  crop?: boolean;
  isRemove?: boolean; //是否可以删除
  description?: string;
  initialValues?: string;
  accept?: string[];
  sizeLimit?: number;
  maxCount?: number;
  widthLimit?: number;
  heightLimit?: number;
  imgStyle?: CSSProperties;
  onChange?: (url: string) => void;
}

const DaqImgUpload: React.FC<IProps> = ({
  isRemove = false,
  className,
  crop = false,
  description = '',
  initialValues = '',
  accept = ['image/jpeg', 'image/png'],
  sizeLimit = 1024 * 1024,
  widthLimit,
  heightLimit,
  maxCount = 1,
  imgStyle,
  onChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>(initialValues);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>点击上传</div>
    </div>
  );

  const getFileWidthAndHeight = (
    file: RcFile,
    initWidth?: number,
    initHeight?: number,
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const width = initWidth;
      const height = initHeight;
      const _URL = window.URL || window.webkitURL;
      const img = new Image();
      img.src = _URL.createObjectURL(file);
      img.onload = () => {
        const validWid = img.width === width;
        const validHei = img.height === height;
        // 需要验证高度，并且高度不正确
        if (width && !validHei) {
          message.error(`图片高度限制为${heightLimit}px，请重新上传！`);
          reject(false);
        } else if (height && !validWid) {
          message.error(`图片宽度限制为${widthLimit}px，请重新上传！`);
          reject(false);
        }
        resolve(true);
      };
    });
  };

  const beforeUpload = (file: RcFile) => {
    const validFormat = accept.includes(file.type);
    if (!validFormat) {
      const imageTypes = accept
        .map((item) => {
          let imgType = item.split('/')[1] || '';
          if (imgType === 'x-icon') {
            imgType = 'icon';
          } else if (imgType === 'jpeg') {
            imgType = 'jpg';
          }
          return imgType;
        })
        .join('、');
      message.error(`图片只支持 ${imageTypes} 格式!`);
      return false;
    }

    const validSize = file.size <= sizeLimit;
    if (!validSize) {
      let unit = 'KB';
      let limit = sizeLimit / 1024;
      if (limit >= 1024) {
        unit = 'MB';
        limit /= 1024;
      }

      message.error(`图片大小不能超过${limit}${unit}`);
      return false;
    }

    // 判断图片尺寸 => 只有传入了宽高才有去验证
    if (widthLimit || heightLimit) {
      return getFileWidthAndHeight(file, widthLimit, heightLimit);
    }
    return true;
  };

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      setImageUrl(info.file.response.safeUrl);
      onChange?.(info.file.response.safeUrl);
    } else if (info.file.status === 'error') {
      setLoading(false);
      message.error(`${description}上传失败`);
    }
  };

  const uploader = (
    <Upload
      className={className}
      listType="picture-card"
      maxCount={maxCount}
      multiple={maxCount > 1}
      name={config.upload.name}
      data={{
        key: config.upload.key,
        path: config.upload.path,
      }}
      showUploadList={false}
      action={config.upload.url}
      accept={accept.join(',')}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <div className="daq-upload">
          <img
            src={imageUrl}
            alt="img"
            style={imgStyle ? imgStyle : { width: '100%' }}
          />
          {loading ? (
            <div className="daq-upload-loading">
              <LoadingOutlined />
            </div>
          ) : null}
          {!loading && isRemove ? (
            <div
              className="daq-upload-remove"
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                setImageUrl('');
                onChange?.('');
              }}
            >
              <DaqIcon icon="&#xe74b;"></DaqIcon>
            </div>
          ) : null}
        </div>
      ) : (
        uploadButton
      )}
    </Upload>
  );

  // TODO 裁剪参数，有需求在外放（默认是头像裁剪的）
  const cropper = (
    <ImgCrop
      rotate
      minZoom={0.5}
      modalWidth={400}
      quality={0.2}
      cropperProps={{ restrictPosition: false }}
      beforeCrop={(file) => {
        return beforeUpload(file as RcFile);
      }}
      grid
      modalTitle={`${description}裁剪`}
      shape="round"
    >
      {uploader}
    </ImgCrop>
  );

  return crop ? cropper : uploader;
};
export default DaqImgUpload;
