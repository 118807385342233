/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-20 09:40:47
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2023-02-23 14:59:57
 * @Description: app应用盒子 window
 */
import React, { useState, useRef } from 'react';
import api from '@api';
import { useDebounceFn, useDeepCompareEffect, useEventListener } from 'ahooks';
import { IAppInfoStore } from '@interfaces/i-app-window';
import { AppType } from '@interfaces/enum';
import { IServiceKey, TAny } from '@interfaces/i-common';

import UserCenter from '@views/user-center';
import AppManage from '@views/app-manage';
import StallManage from '@views/staff-manage';
import SettingManage from '@views/setting-manage';
import LoadingProcess from '@components/loading-process';
import NavigationBtn from '@components/navigation-btn';
import WeatherView from '@views/weather';
import { WEATHER_APP_CODE } from '@utils/constant';

interface IAppBox {
  activeApp: IAppInfoStore;
  AppInfo: IAppInfoStore;
  windowFullscreen: boolean;
  serviceKey: { [key: string]: IServiceKey };
}
const AppBox: React.FC<Partial<IAppBox>> = ({
  AppInfo,
  activeApp,
  windowFullscreen,
  serviceKey,
}): JSX.Element => {
  const trueAsStr = 'true' as TAny;
  const appList: { [key: string]: JSX.Element } = {
    settingManage: <SettingManage />,
    userCenter: <UserCenter />,
    appManage: <AppManage />,
    staffManage: <StallManage />,
    weatherService: <WeatherView />,
  };
  // 扩展后期存在即是内置又是外部应用
  const serviceApp = [WEATHER_APP_CODE];
  const [loading, setLoading] = useState(false);
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);

  const [accessUrl, setAccessUrl] = useState('');
  const iframeRef = useRef<Electron.WebviewTag>(null);

  const serviceInfo = serviceKey?.[AppInfo?.code ?? ''] as IServiceKey;

  const { run: getAccessUrl } = useDebounceFn(
    async (app: IAppInfoStore) => {
      // 开始加载
      setLoading(true);
      const { data } = await api.third.getWebAppAccessUrl({
        relationId: app?.appType === AppType.web ? app.relationId : app.appId,
        appType: app.appType,
      });
      let url = data.url ?? '';
      const token = data.token ?? '';
      if (url && serviceApp.includes(app.code) && serviceInfo.key !== '') {
        const AccessUrl = url.split('#');
        url = `${AccessUrl[0]}#/${serviceInfo?.url}?token=${token}`;
      }
      setAccessUrl(url);
    },
    {
      wait: 300,
    },
  );

  useDeepCompareEffect(() => {
    if (!AppInfo) {
      return;
    }
    if (
      [AppType.externalIn, AppType.web].includes(AppInfo?.appType) ||
      (serviceApp.includes(AppInfo.code) && serviceInfo?.key !== '')
    ) {
      getAccessUrl(AppInfo as IAppInfoStore);
    } else if ([AppType.windowOpenIn].includes(AppInfo?.appType)) {
      setAccessUrl(AppInfo?.url);
    }
  }, [serviceInfo]);
  // 监听加载完成
  useEventListener(
    'did-stop-loading',
    () => {
      if (loading) {
        setLoading(false);
      }
      changeCanGo();
    },
    { target: iframeRef },
  );
  // 前进后退判断是否能继续
  const changeCanGo = () => {
    const CanBack = iframeRef.current?.canGoBack() ?? false;
    const CanForward = iframeRef.current?.canGoForward() ?? false;
    setCanGoBack(CanBack);
    setCanGoForward(CanForward);
  };
  if (
    AppInfo?.appType !== AppType.builtIn ||
    (serviceApp.includes(AppInfo.code) && serviceInfo.key !== '')
  ) {
    return (
      <>
        <LoadingProcess loading={loading} />
        {!windowFullscreen && activeApp?.appUnionId === AppInfo?.appUnionId ? (
          <NavigationBtn
            canGoBack={canGoBack}
            canGoForward={canGoForward}
            onBack={() => {
              iframeRef.current?.goBack();
            }}
            onForward={() => {
              iframeRef.current?.goForward();
            }}
          />
        ) : null}
        {accessUrl && (
          <webview
            ref={iframeRef}
            src={accessUrl}
            style={{ width: '100%', height: '100%' }}
            allowpopups={trueAsStr}
          ></webview>
        )}
      </>
    );
  }
  return appList[AppInfo?.code ?? ''];
};

export default AppBox;
