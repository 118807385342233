import './index.less';
import React from 'react';
import { IComponentProps } from '@interfaces/i-common';
import classNames from 'classnames';

interface IDaqIconProps extends IComponentProps {
  icon: string;
  color?: string;
  size?: string;
}

const DaqIcon: React.FC<IDaqIconProps> = ({
  icon,
  color,
  size,
  className = 'daq-icon',
}: IDaqIconProps) => {
  const content = { __html: icon };
  return (
    <i
      className={classNames(className)}
      style={{
        color: color || '',
        fontSize: size || '',
      }}
      dangerouslySetInnerHTML={content}
    ></i>
  );
};

export default DaqIcon;
