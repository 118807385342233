import { Button, Form, Input } from 'antd';
import { ISecurityForm, ISecurityProps } from '@interfaces/i-user-center';
import regular from '@config/regular';

type SetPasswordProps = 'onCancel' | 'onOk' | 'submitting';
// 修改密码
const Password = ({ onCancel, onOk, submitting }: Pick<ISecurityProps, SetPasswordProps>) => {
  const [form] = Form.useForm();
  const confirmPwd = Form.useWatch('confirmPassword', form);
  const oldPassword = Form.useWatch('oldPassword', form);

  const handleFinish = (data: ISecurityForm) => {
    onOk({
      password: data.password,
      oldPassword: data.oldPassword,
    });
  };

  // 新密码变化时验证确认密码
  const handleChangePwd = async () => {
    // 如果确认密码存在，则验证一下是否和新密码一致
    if (confirmPwd) {
      try {
        await form.validateFields(['confirmPassword']);
      } catch (e) {
        return;
      }
    }
  };

  return (
    <>
      <Form
        form={form}
        colon={false}
        requiredMark={false}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item
          label="原始密码"
          name="oldPassword"
          validateFirst
          rules={[
            {
              required: true,
              message: '请输入原始密码',
            },
          ]}
        >
          <Input.Password
            autoComplete="off"
            placeholder="请输入原始密码"
            allowClear
            maxLength={30}
          />
        </Form.Item>
        <Form.Item
          label="新密码"
          validateFirst
          validateTrigger={['onChange', 'onBlur']}
          name="password"
          rules={[
            {
              required: true,
              message: '请输入新密码',
            },
            {
              pattern: regular.password.pattern,
              message: regular.password.message,
            },
            {
              message: '新密码和原密码不能一致',
              validator(rule, value) {
                return value !== oldPassword
                  ? Promise.resolve()
                  : Promise.reject(new Error('确认密码和新密码不一致！'));
              },
            },
          ]}
        >
          <Input.Password
            autoComplete="off"
            placeholder="请输入新密码"
            allowClear
            maxLength={20}
            onChange={() => handleChangePwd()}
          />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="confirmPassword"
          validateFirst
          validateTrigger={['onChange', 'onBlur']}
          rules={[
            {
              required: true,
              message: '请输入确认密码',
            },
            {
              pattern: regular.password.pattern,
              message: regular.password.message,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                return value === getFieldValue('password')
                  ? Promise.resolve()
                  : Promise.reject(new Error('确认密码和新密码不一致'));
              },
            }),
          ]}
        >
          <Input.Password
            autoComplete="off"
            placeholder="请输入确认密码"
            allowClear
            maxLength={20}
          />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 7,
            span: 17,
          }}
        >
          <Button
            type="primary"
            loading={submitting}
            htmlType="submit"
          >
            保存
          </Button>
          {onCancel ? <Button onClick={() => onCancel('password')}>取消</Button> : null}
        </Form.Item>
      </Form>
    </>
  );
};

export default Password;
