import { Avatar, Button, Modal } from 'antd';
import avatar from '@assets/images/avatar.png';
import DaqIcon from '@components/daq-icon';
import { observer } from 'mobx-react-lite';

interface ISystemTipProps {
  title: string;
  show: boolean;
  message: string;
  user: {
    avatar: string;
    phone: string;
  };
  buttonText: string;
  width?: number;
  onClose: (show: boolean) => void;
}

const SystemTip = ({
  title = '退出登录',
  width = 280,
  show = false,
  user = { avatar: '', phone: '' },
  message,
  buttonText,
  onClose,
}: ISystemTipProps) => {
  return (
    <Modal
      transitionName="ant-fade"
      title={title}
      width={width}
      className="dock-modal tip-dialog"
      destroyOnClose
      closable={false}
      open={show}
      maskClosable={false}
      footer={null}
      onCancel={() => onClose(false)}
    >
      <Avatar
        src={user.avatar || avatar}
        size={74}
        icon={<DaqIcon icon="&#xe870;" />}
      />
      <div className="account">{user.phone}</div>
      <div className="dock-modal-message">{message}</div>
      <Button
        type="primary"
        style={{ marginBottom: '20px' }}
        block
        onClick={() => {
          onClose(false);
        }}
      >
        {buttonText}
      </Button>
    </Modal>
  );
};

export default observer(SystemTip);
