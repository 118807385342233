/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-20 11:18:07
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-13 10:03:26
 * @Description: 描述
 */
import { message, Modal } from 'antd';
import OperateMessage from '@components/operate-message';
import { useState } from 'react';
import { useDebounceFn } from 'ahooks';
import { ISecurityForm } from '@interfaces/i-user-center';
import api from '@api';
import { useImmer } from 'use-immer';
import { useStore } from '@stores';
import InfoGroup from './info-group';
import './update-password.less';
import { observer } from 'mobx-react-lite';
import Phone from '@components/phone';
import { IBaseUserInfo } from '@interfaces/i-user-info';

// 修改密码
const BindPhone = () => {
  const [resultInfo, setResultInfo] = useImmer({
    message: '',
    type: 'info',
  });
  const [submitting, setSubmitting] = useState(false);
  const { userStore } = useStore();
  const { run: setPhone } = useDebounceFn(
    ({ phone, code }: ISecurityForm) => {
      if (phone && code && !submitting) {
        setSubmitting(true);
        api.userCenter
          .setPhone({
            phone,
            code,
            password: '',
          })
          .then(() => {
            userStore.setUser({ phone } as IBaseUserInfo);
            message.success('绑定手机号成功！');

            userStore.logout();
          })
          .catch((error) => {
            setResultInfo((state) => {
              state.message = error.message;
              state.type = 'error';
            });
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
    { wait: 300 },
  );

  return (
    <>
      <Modal
        className="os-dialog info-form"
        title="绑定手机号码"
        destroyOnClose
        closable={false}
        maskClosable={false}
        keyboard={false}
        open={!userStore.first && userStore.phone === ''}
        footer={null}
      >
        {resultInfo.message ? (
          <OperateMessage
            message={resultInfo.message}
            type={resultInfo.type}
          />
        ) : null}
        <InfoGroup
          className="info-group"
          phone
        />
        <Phone
          submitting={submitting}
          onOk={setPhone}
        />
      </Modal>
    </>
  );
};

export default observer(BindPhone);
