/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-08 10:43:01
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-19 17:17:55
 * @Description: 描述
 */
import { addStaffApi, modifyManageAppApi } from '@api/api-staff';
import ContainerCard from '@components/container-card';
import CheckGroup from '@components/check-group';
// import DaqEmpty from '@components/daq-empty';
import DaqIcon from '@components/daq-icon';
// import RadioGroup from '@components/radio-group';
import {
  IStaffAddParams,
  // IStaffModifyParams,
  IStaffModifyParams2,
} from '@interfaces/i-api-request';
import { IMProps, IMStaffProps } from '@interfaces/i-staff-manage';
import { useMount } from 'ahooks';
import { Button, Form, Input, message, Tabs } from 'antd';
import Scrollbars from 'rc-scrollbars';
import React, { useState } from 'react';
import './list-modify.less';
// import regular from '@config/regular';
import DaqSoftTrack from '@utils/daqsoft-track';
import { IAppStartMenuRes } from '@interfaces/i-api-response';

const RoleListModify: React.FC<IMStaffProps> = ({
  detail,
  className,
  onBack,
  // goAddRole,
}): JSX.Element => {
  const layout = { wrapperCol: { span: 6 } };
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [externalAppList, setExternalAppList] = useState<IAppStartMenuRes[]>(
    detail?.externalAppList ?? [],
  );

  // const [roleVoList, setRoleVoList] = useState<IRoleListPagingRes[]>([]);
  // const [StartAppRelationVoList, setStartAppRelationVoList] = useState<IAppStartMenuRes[]>([]);

  const onReset = (info: Partial<IMProps>) => {
    form.resetFields();
    onBack?.(info);
  };

  const successTrack = (secondEventType: string, thirdEventType: string) => {
    DaqSoftTrack.track({
      firstEventType: '员工管理',
      secondEventType,
      thirdEventType,
      status: 1,
    });
  };

  const errorTrack = (secondEventType: string, thirdEventType: string, failReason: string) => {
    DaqSoftTrack.track({
      firstEventType: '员工管理',
      secondEventType,
      thirdEventType,
      failReason,
      status: 1,
    });
  };

  // 获取角色列表，应用列表
  // const getBeforeAddInfo = async () => {
  //   try {
  //     const { data } = await getBeforeAddInfoApi();
  //     setRoleVoList(data?.roleVoList ?? []);
  //     setStartAppRelationVoList(data?.startAppRelationVoList ?? []);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const addRole = async (params: IStaffAddParams) => {
    setIsLoading(true);
    try {
      const { data } = await addStaffApi(params);
      await message.success('添加成功');
      successTrack('员工管理', '添加');
      await setIsLoading(false);
      await onReset({
        phone: params.phone,
        password: data,
        type: 'add',
      });
    } catch (error) {
      setIsLoading(false);
      errorTrack('员工管理', '添加', String(error));
    }
  };
  // 修改
  // const modifyRole = async (params: IStaffModifyParams) => {
  //   setIsLoading(true);
  //   try {
  //     await modifyApi(params);
  //     await message.success('修改成功');
  //     successTrack('员工管理', '修改');
  //     await onReset({ type: 'modify' });
  //     await setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     errorTrack('员工管理', '修改', String(error));
  //   }
  // };
  const modifyRole = async (params: IStaffModifyParams2) => {
    setIsLoading(true);
    try {
      await modifyManageAppApi(params);
      await message.success('修改成功');
      successTrack('员工管理', '修改');
      await onReset({ type: 'modify' });
      await setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorTrack('员工管理', '修改', String(error));
    }
  };

  const onFinish = (params: IStaffAddParams) => {
    if (detail === null) {
      addRole(params);
      return;
    }
    const modifyData = {
      // ...detail,
      userId: detail?.userId ?? '',
      staffManageApps: externalAppList,
      ...params,
    } as IStaffModifyParams2;
    modifyRole(modifyData);
  };

  useMount(() => {
    // getBeforeAddInfo();
    if (detail === null) {
      return;
    }
    form.setFieldsValue({
      deptName: detail?.deptName,
      email: detail?.email,
      phone: detail?.phone,
      realName: detail?.realName,
      relationIds: detail?.relationIds,
      roleId: detail?.roleId,
      remark: detail?.remark ?? '',
    });
  });

  const items = [
    {
      label: '云服务',
      key: 'cloudAppList',
      children: (
        <div className="staff-list-detail-app">
          {detail && detail?.cloudAppList?.length > 0 ? (
            <CheckGroup
              options={detail?.cloudAppList ?? []}
              value="appId"
              label="displayName"
              disabled
            />
          ) : (
            '暂无数据'
          )}
        </div>
      ),
    },
    {
      label: '本地服务',
      key: 'localAppList',
      children: (
        <div className="staff-list-detail-app">
          {detail && detail?.localAppList?.length > 0 ? (
            <CheckGroup
              options={detail?.localAppList ?? []}
              value="appId"
              label="displayName"
              disabled
            />
          ) : (
            '暂无数据'
          )}
        </div>
      ),
    },
  ];

  const externalList = {
    label: '添加应用',
    key: 'externalAppList',
    children: (
      <div className="staff-list-detail-app">
        <span className="ant-form-tip">勾选应用后，员工即可在开始菜单查看及访问该应用</span>
        {detail && detail?.externalAppList?.length > 0 ? (
          <CheckGroup
            options={detail?.externalAppList ?? []}
            value="appId"
            label="displayName"
            onClick={(value: IAppStartMenuRes[]) => {
              setExternalAppList(value);
            }}
          />
        ) : (
          '暂无数据'
        )}
      </div>
    ),
  };

  return (
    <div className={`list-modify staff-list-modify ${className}`}>
      <div
        className="list-modify-back"
        onClick={() => {
          onReset({ type: 'close' });
        }}
      >
        <DaqIcon icon="&#xe660;" />
        {detail !== null ? '修改' : '添加'}员工
      </div>
      <div className="list-modify-content">
        <Scrollbars autoHide>
          <ContainerCard
            title="基本信息"
            className="staff-list-modify-content-card"
          >
            {/* <Form
              labelAlign="right"
              labelCol={{ style: { width: 110 } }}
              wrapperCol={{
                style: {
                  width: 'calc(100% - 120px)',
                  maxWidth: 450,
                },
              }}
              form={form}
              onFinish={onFinish}
              colon={false}
              name="form1"
            >
              {detail?.userId ? <Form.Item label="平台账号">{detail?.userName}</Form.Item> : null}
              <Form.Item
                name="realName"
                label="员工姓名"
                rules={[
                  { required: true },
                  {
                    pattern: regular.commonName.pattern,
                    message: regular.commonName.message,
                  },
                ]}
              >
                <Input
                  showCount
                  allowClear
                  maxLength={10}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name="phone"
                label="手机号码"
                rules={[
                  { required: true },
                  {
                    pattern: regular.phone.pattern,
                    message: regular.phone.message,
                  },
                ]}
              >
                <Input
                  maxLength={11}
                  allowClear
                  autoComplete="off"
                />
              </Form.Item>

              <Form.Item
                name="email"
                label="邮箱地址"
                rules={[
                  { required: true },
                  {
                    pattern: regular.email.pattern,
                    message: regular.email.message,
                  },
                ]}
              >
                <Input
                  maxLength={255}
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name="deptName"
                label="所属部门"
                rules={[
                  { required: true },
                  {
                    pattern: regular.commonName.pattern,
                    message: regular.commonName.message,
                  },
                ]}
              >
                <Input
                  showCount
                  allowClear
                  maxLength={10}
                  autoComplete="off"
                />
              </Form.Item>
            </Form> */}
            <Form
              {...layout}
              name="form1"
            >
              <Form.Item label="平台账号">
                <span className="ant-form-text">{detail?.userName || '-'}</span>
              </Form.Item>
              <Form.Item label="员工姓名">
                <span className="ant-form-text">{detail?.realName || '-'}</span>
              </Form.Item>
              <Form.Item label="手机号码">
                <span className="ant-form-text">{detail?.phone || '-'}</span>
              </Form.Item>
              <Form.Item label="邮箱地址">
                <span className="ant-form-text-long">{detail?.email || '-'}</span>
              </Form.Item>
            </Form>
          </ContainerCard>
          <div className="staff-list-modify-content-line"></div>
          <ContainerCard
            title="管理应用"
            className="staff-list-modify-content-card"
          >
            <Form
              className="staff-list-modify-role"
              form={form}
              onFinish={onFinish}
              name="form2"
            >
              <Form.Item label="">
                <Tabs
                  items={
                    detail && detail?.externalAppList?.length > 0 ? [...items, externalList] : items
                  }
                />
              </Form.Item>
              <Form.Item label="角色权限">
                <span className="ant-form-text">{detail?.roleName || '-'}</span>
              </Form.Item>
              {/* <Form.Item
                name="relationIds"
                label="访问应用"
                rules={[{ required: true, message: '请选择访问应用' }]}
              >
                <CheckGroup
                  options={StartAppRelationVoList}
                  value="relationId"
                  label="displayName"
                  defaultValue={detail?.relationIds?.split(',') ?? []}
                  onClick={(value: string[]) => {
                    form.setFieldsValue({
                      relationIds: value.toString(),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="roleId"
                label="角色配置"
                rules={[{ required: true, message: '请选择角色配置' }]}
              >
                {roleVoList.length > 0 ? (
                  <RadioGroup
                    options={roleVoList}
                    defaultValue={detail?.roleId ?? ''}
                    value="id"
                    label="name"
                    onClick={(value) => {
                      form.setFieldsValue({
                        roleId: value,
                      });
                    }}
                  />
                ) : (
                  <div className="role-daq-empty">
                    <DaqEmpty
                      message="您还没添加角色权限"
                      onClick={goAddRole}
                      btnLabel="添加角色"
                    />
                  </div>
                )}
              </Form.Item> */}
              <Form.Item name="remark">
                <Input.TextArea
                  placeholder="备注描述"
                  showCount
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item
                name="des"
                label="操作时间"
              >
                <span className="ant-form-text">{detail?.gmtModified || '-'}</span>
                <span className="ant-form-text modifierName">
                  操作员：{detail?.modifierName || '-'}
                </span>
              </Form.Item>
            </Form>
          </ContainerCard>
        </Scrollbars>
      </div>
      <div className="list-modify-btn">
        <Form
          form={form}
          name="form3"
          onFinish={onFinish}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            保存
          </Button>
          <Button
            htmlType="button"
            onClick={() => {
              onReset({ type: 'close' });
            }}
          >
            取消
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default RoleListModify;
