import style from './index.module.less';
import React from 'react';
import { Image } from 'antd';
import defaultImage from '@assets/images/app-logo.png';

interface IDaqImageProps {
  alt: string;
  src: string;
}

const DaqImage: React.FC<IDaqImageProps> = (props: IDaqImageProps) => {
  return (
    <Image
      // wrapperClassName={style.daqImage}
      rootClassName={style.daqImage}
      alt={props.alt}
      src={props.src}
      preview={false}
      fallback={defaultImage}
      placeholder={
        <img
          alt={props.alt}
          className={style.defaultImage}
          src={defaultImage}
        />
      }
    ></Image>
  );
};

export default DaqImage;
