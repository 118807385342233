/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-07 16:46:57
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-07 17:10:32
 * @Description: 分页 loading组件
 */
import { IPage } from '@interfaces/i-common';
import React from 'react';
import './index.less';

const PageLoading: React.FC<Partial<IPage>> = ({ pageNo, pageTotal, recordCount }): JSX.Element => {
  return (
    <div className="page-loading">
      <div className="page-loading-total">{recordCount}条</div>
      <div className="page-loading-no">
        {pageNo}/{pageTotal}
      </div>
    </div>
  );
};

export default PageLoading;
