import { useImmer } from 'use-immer';
import { useUpdateEffect } from 'ahooks';
import { Button, Modal } from 'antd';
import Project from './components/project';
import Environment from './components/environment';
import UserInfo from './components/user-info';
import LocalApp from './components/local-app';
import Sidebar from './components/sidebar';
import style from './index.module.less';
import { IInitEnv, IInitUser, ILocalApp, IProjectInfo, IRegion } from '@interfaces/i-system-init';
import Description from './components/description';
import OperateMessage from '@components/operate-message';
import React, { RefObject, useRef, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { TAny } from '@interfaces/i-common';
import axios from '@utils/axiosInterceptor';
import { checkNetwork, initSystem } from '@api/api-system-init';
import classNames from 'classnames';
import { NetworkType } from '@interfaces/enum';
import { successTip } from '@utils/error';
import { IApiResult } from '@interfaces/i-api-response';

interface ISystemInitProps {
  show: boolean;

  finishInit(): void;
}

interface IRefs {
  projectFormRef: React.Ref<FormInstance<TAny>>;
  envFormRef: React.Ref<FormInstance<TAny>>;
  userFormRef: React.Ref<FormInstance<TAny>>;

  [key: string]: React.Ref<FormInstance<TAny>>;
}

const SystemInit = (props: ISystemInitProps) => {
  const refs: IRefs = {
    projectFormRef: useRef<FormInstance<TAny>>(null),
    envFormRef: useRef<FormInstance<TAny>>(null),
    userFormRef: useRef<FormInstance<TAny>>(null),
  };
  const [submitting, setSubmitting] = useState(false);
  const [operateInfo, setOperateInfo] = useImmer({
    message: '',
    type: 'error',
  });
  const [state, setState] = useImmer<{
    activeIndex: number;
    environment: IInitEnv;
    user: IInitUser;
    regions: IRegion[];
    apps: ILocalApp[];
    project: {
      projectNo: string;
      projectName: string;
      phone: string;
      userName: string;
    };
  }>({
    activeIndex: 0,
    regions: [],
    apps: [],
    environment: {
      // 访问地址
      baseUrl: '',
      // 部署环境
      deployEnvironment: null,
      // 网络环境
      networkEnvironment: null,
      // 地区接口地址
      regionUrl: '',
    },
    user: {
      phone: '',
      username: '',
      // 客户名称
      customerName: '',
      // 所属地区
      region: '',
      unionCode: '',
      // 用户类型
      userType: null,
    },
    project: {
      projectNo: '',
      projectName: '',
      phone: '',
      userName: '',
    },
  });

  const initProject = (project: IProjectInfo, user: IInitUser, apps: ILocalApp[]) => {
    setState((state) => {
      state.project = project;
      state.user = user;
      state.apps = apps;
      state.activeIndex += 1;
    });
  };

  // 初始化环境信息
  const initEnv = async (env: IInitEnv) => {
    setSubmitting(true);
    setState((state) => {
      state.environment = env;
    });

    // TODO 两个接口优化为同时调用   Promise.all遇到报错不知道是哪个接口错了

    // 外网，验证
    if (env.networkEnvironment === NetworkType.outer) {
      try {
        const result = await checkNetwork();
        if (result.data !== 'SUCCESS') {
          setSubmitting(false);
          setOperateInfo((state) => {
            state.message = '当前网络无法访问外网';
            state.type = 'error';
          });
        }
      } catch (e) {
        setSubmitting(false);
        setOperateInfo((state) => {
          state.message = '网络环境检查失败';
          state.type = 'error';
        });
        return;
      }
    }

    try {
      const result = await axios.request({
        errorTip: false,
        url: env.regionUrl,
        method: 'get',
      });
      setState((state) => {
        state.activeIndex += 1;
        state.regions = result.data;
      });
    } catch (e) {
      setOperateInfo((state) => {
        state.message = '获取地区数据失败，请检查地区接口是否正确';
        state.type = 'error';
      });
    }

    setSubmitting(false);
  };

  // 设置用户信息
  const initUser = (user: IInitUser) => {
    setState((state) => {
      state.user.customerName = user.customerName;
      state.user.region = user.region?.at(-1) as string;
      state.user.userType = user.userType;
    });

    setState((state) => {
      state.activeIndex += 1;
    });
  };

  const handleMessage = (message: string, type: string) => {
    setOperateInfo((state) => {
      state.message = message;
      state.type = type;
    });
  };

  // 提交系统初始化信息
  const finishConfig = () => {
    setSubmitting(true);
    initSystem({
      environment: state.environment,
      user: state.user,
      project: state.project,
    })
      .then((result) => {
        const res = result as unknown as IApiResult<string>;
        if (res.data === 'SUCCESS') {
          successTip('初始化系统配置成功');
          props.finishInit();
        } else {
          setOperateInfo((state) => {
            state.message = res.msg || '初始化系统配置失败';
            state.type = 'error';
          });
        }
      })
      .catch((e) => {
        setSubmitting(false);
        setOperateInfo((state) => {
          state.message = e.message || '初始化系统配置失败';
          state.type = 'error';
        });
      });
  };

  const steps = [
    {
      name: '实施项目',
      id: 'project',
      icon: '&#xe64c;',
      text: '核准本地部署服务对象',
      comp: (
        <Project
          onFinish={initProject}
          ref={refs.projectFormRef}
          onMessage={handleMessage}
        />
      ),
    },
    {
      name: '环境配置',
      id: 'env',
      icon: '&#xe607;',
      text: '完成环境初始化配置，便于系统运行',
      comp: (
        <Environment
          onFinish={initEnv}
          ref={refs.envFormRef}
        />
      ),
    },
    {
      name: '用户信息',
      id: 'user',
      icon: '&#xe621;',
      text: '完成用户信息设置，便于后期用户个性化管理',
      comp: (
        <UserInfo
          onFinish={initUser}
          ref={refs.userFormRef}
          onMessage={handleMessage}
          user={state.user}
          regions={state.regions}
        />
      ),
    },
    {
      name: '开通应用',
      id: 'app',
      icon: '&#xe69d;',
      text: '当前用户已开通的应用',
      comp: <LocalApp apps={state.apps} />,
    },
  ];

  const handleNextStep = () => {
    const id = steps[state.activeIndex].id;
    const ref = refs[`${id}FormRef`] as RefObject<FormInstance>;
    // TODO 这里ref不断言一下，current回报不存在，有点怪，暂时没搞懂
    ref?.current?.submit();
  };
  const handlePrevStep = () => {
    setState((state) => {
      state.activeIndex -= 1;
    });
  };

  useUpdateEffect(() => {
    setOperateInfo((state) => {
      state.message = '';
      state.type = '';
    });
  }, [state.activeIndex]);

  return (
    <Modal
      title="系统初始化配置"
      destroyOnClose
      width={800}
      maskClosable={false}
      mask={false}
      closable={false}
      className={style.systemInit}
      wrapClassName="modal-wrap"
      footer={null}
      open={props.show}
    >
      <div className={style.body}>
        <Sidebar
          activeIndex={state.activeIndex}
          menus={steps}
        ></Sidebar>
        <div className={style.content}>
          {operateInfo.message ? (
            <OperateMessage
              message={operateInfo.message}
              type={operateInfo.type}
            />
          ) : null}
          <Description
            icon={steps[state.activeIndex].icon}
            text={steps[state.activeIndex].text}
          />
          {steps.map((step, index) => {
            return (
              <div
                key={step.id}
                className={classNames(style.dataBox, index === state.activeIndex ? style.show : '')}
              >
                {step.comp}
              </div>
            );
          })}
          <div className={style.buttonGroup}>
            {state.activeIndex > 0 ? (
              <Button
                type="primary"
                onClick={handlePrevStep}
              >
                上一步
              </Button>
            ) : null}
            {state.activeIndex < steps.length - 1 ? (
              <Button
                type="primary"
                loading={submitting}
                onClick={handleNextStep}
              >
                下一步
              </Button>
            ) : (
              <Button
                type="primary"
                loading={submitting}
                onClick={finishConfig}
              >
                完成
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SystemInit;
