// import style from './index.module.less';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { TAny } from '@interfaces/i-common';
import React, { useCallback, useState } from 'react';

import {
  // GridSimpleComponent,
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  // LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import WeatherBox from '@views/weather/components/weather-box';
import { IHourWeather } from '@interfaces/i-weather';
import { useMount, useUpdateEffect } from 'ahooks';
import DaqEmpty from '@components/daq-empty';
import { EChartsInstance } from 'echarts-for-react';

const Forecast24h = ({ infos }: { infos: IHourWeather[] }) => {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DatasetComponent,
    BarChart,
    CanvasRenderer,
  ]);

  const [hours, setHours] = useState<string[]>([]);
  const echartRef = React.useRef<EChartsInstance>(null);

  useMount(() => {
    const echartInstance = echartRef?.current?.getEchartsInstance();
    if (echartInstance) {
      echartInstance.clear();
    }
    setHours(
      infos.map((info) => {
        return String(parseInt(info.fxTime.split(' ')[1]));
      }),
    );

    // 处理echarts渲染图片时可能存在渲染不完整的情况
    setTimeout(() => {
      echartInstance.resize();
    }, 200);
  });

  useUpdateEffect(() => {
    const echartInstance = echartRef?.current?.getEchartsInstance();
    if (echartInstance) {
      echartInstance.clear();
    }
    setHours(
      infos.map((info) => {
        return String(parseInt(info.fxTime.split(' ')[1]));
      }),
    );

    // 处理echarts渲染图片时可能存在渲染不完整的情况
    setTimeout(() => {
      echartInstance.resize();
    }, 200);
  }, [infos]);

  const getOptions = useCallback(() => {
    const rich = {
      value: {
        lineHeight: 20,
        align: 'center',
        color: '#fff',
      },
    } as TAny;

    const source: [string, number][] = [];
    // 获取最低温度，当包含零下温度时，0刻度为负数开始
    let minTemp: number = Math.min(...infos.map((item) => Number(item.temp)));
    // 只处理最低温度为负数的时候
    if (minTemp > 0) {
      minTemp = 0;
    }
    hours.forEach(
      (item, index) => {
        if (!rich[item]) {
          rich[item] = {
            height: 18,
            align: 'center',
            backgroundColor: {
              image: infos[index].iconPic,
            },
          };
        }

        source.push([item, Number(infos[index].temp) + Math.abs(minTemp) * 2]);
      },
      [infos],
    );

    return {
      tooltip: {
        show: false,
      },
      dataset: {
        // 提供一份数据。
        source: source,
      },
      grid: {
        top: 20,
        left: 0,
        bottom: 45,
        right: 0,
      },
      dataZoom: [
        {
          type: 'inside',
          minValueSpan: 7,
          maxValueSpan: 7,
        },
      ],
      xAxis: {
        type: 'category',
        data: hours,
        axisLabel: {
          interval: 0,
          formatter(value: string) {
            return '{' + value + '| }\n{value|' + value + '}时';
          },
          color: 'rgb(255, 255, 255)',
          align: 'center',
          rich: rich,
        },
        axisLine: {
          show: false,
          onZero: false,
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.2)',
          },
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        axisLabel: {
          show: false,
          color: 'rgb(255, 255, 255)',
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.2)',
          },
        },
      },
      series: [
        {
          type: 'bar',
          symbol: 'none',
          barWidth: 6,
          barCategoryGap: 0,
          itemStyle: {
            borderRadius: 3,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#00c0ff' },
              { offset: 1, color: '#008aff' },
            ]),
          },
          label: {
            color: '#fff',
            show: true,
            position: 'top',
            formatter(params: TAny) {
              return params.value[1] - Math.abs(minTemp) * 2 + '℃';
            },
          },
        },
      ],
    };
  }, [infos, hours]);
  return (
    <WeatherBox title="未来24小时天气预报">
      {infos.length ? (
        <ReactEChartsCore
          ref={echartRef}
          echarts={echarts}
          style={{
            width: '290px',
            height: '180px',
          }}
          option={getOptions()}
        ></ReactEChartsCore>
      ) : (
        <DaqEmpty></DaqEmpty>
      )}
    </WeatherBox>
  );
};

export default Forecast24h;
