import style from './remember-login.module.less';

import LoginLoading from './login-loading';
import classNames from 'classnames';
import { ILoginRes } from '@interfaces/i-api-response';
import { Input, Tooltip, Button } from 'antd';
import React from 'react';
import { useImmer } from 'use-immer';
import { useStore } from '@stores';
import DaqIcon from '@components/daq-icon';
import { useUpdateEffect } from 'ahooks';
import { VerifyStatus } from '@interfaces/enum';
import api from '@api';
import { errorTip } from '@utils/error';
import { encrypt } from '@utils/encrypy';

interface INormalLoginProps {
  loading: boolean;
  loadingPercent: number;
  account: string;
  verify: number;
  prevLogin(): void;
  loginFailed(account: string, message?: string): void;
  loginSuccess(account: string, res: ILoginRes): void;
  loginLogo: string;
}

const RememberLogin = (props: INormalLoginProps) => {
  const { userStore } = useStore();
  const [state, setState] = useImmer({
    logging: false,
    editAccount: false,
    account: userStore.account,
    password: '',
    error: false,
  });

  const editAccount = () => {
    setState((state) => {
      state.editAccount = true;
    });
  };

  const changeAccount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setState((state) => {
      state.account = inputValue;
    });
  };

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    setState((state) => {
      state.password = inputValue;
    });
  };

  const handleLogin = async () => {
    const username = await encrypt(state.account);
    const password = await encrypt(state.password);

    // 登录接口获取所有预备信息
    api
      .login({
        username,
        password,
      })
      .then((result) => {
        props.loginSuccess(state.account, result.data);
      })
      .catch((e) => {
        errorTip(e.message);
        setState((state) => {
          state.error = true;
        });
        props.loginFailed(state.account, e.message);
      })
      .finally(() => {
        setState((state) => {
          state.logging = false;
        });
      });
  };

  useUpdateEffect(() => {
    if (props.verify === VerifyStatus.unverified) {
      setState((state) => {
        state.logging = false;
      });
    } else if (props.verify === VerifyStatus.verified) {
      handleLogin();
    }
  }, [props.verify]);

  const prevLogin = () => {
    if (!state.account || !state.password) {
      return;
    }

    if (!state.logging) {
      setState((state) => {
        state.logging = true;
        state.error = false;
      });

      props.prevLogin();
    }
  };

  return (
    <div className={style.loginBox}>
      <div className={style.logoWrapper}>
        <img
          className={style.logo}
          src={props.loginLogo}
          alt="大旗操作系统"
        />
      </div>
      <div className={classNames(style.loginForm, props.loading ? style.hide : '')}>
        <div className={style.loginFormItem}>
          {state.editAccount ? (
            <Input
              className={classNames(style.inputAccount, state.editAccount ? style.edit : '')}
              value={state.account}
              placeholder="账号"
              onPressEnter={prevLogin}
              onChange={changeAccount}
              maxLength={50}
            />
          ) : (
            <div className={style.account}>
              <span>{state.account.replace(/^(\d{3})\d*(\d{4})$/, '$1****$2')}</span>
              <Tooltip
                placement="top"
                title="切换账号"
              >
                <span
                  className={style.accountButton}
                  onClick={editAccount}
                >
                  <DaqIcon icon="&#xe876;"></DaqIcon>
                </span>
              </Tooltip>
            </div>
          )}
        </div>
        <div className={style.loginFormItem}>
          <Input.Password
            className={classNames(state.error ? 'animate-shakeX' : '')}
            autoComplete="new-password"
            placeholder="密码"
            value={state.password}
            visibilityToggle={false}
            onPressEnter={prevLogin}
            onChange={changePassword}
            style={{ width: state.password ? '204px' : '250px' }}
            maxLength={50}
          />
          <Button
            tabIndex={-1}
            className={style.accessButton}
            onClick={prevLogin}
            loading={state.logging}
            shape="circle"
            icon={<DaqIcon icon="&#xe632;"></DaqIcon>}
          />
        </div>
      </div>
      <LoginLoading
        className={classNames(!props.loading ? style.hide : '')}
        account={props.account}
        percent={props.loadingPercent}
      />
    </div>
  );
};

export default RememberLogin;
