import classNames from 'classnames';
import { Input } from 'antd';
import style from './index.module.less';
import DaqIcon from '@components/daq-icon';
import EnableApps from './components/enable-apps';
import { useImmer } from 'use-immer';
import { useStore } from '@stores';
import React from 'react';
import { useDebounceFn, useMount, useUpdateEffect } from 'ahooks';
import { IAppStartMenu } from '@interfaces/i-app-window';
import { observer } from 'mobx-react-lite';
import BackgroundLayer from '@components/background-layer';
import DaqSoftTrack from '@utils/daqsoft-track';
interface IStartMenuState {
  searchInputBlur: boolean;
  // 关键字
  keyword: string;
  // 搜索框激活class，聚焦或者输入框有数据时表示激活
  activeClassName: string;
  // 自定义label是为了处理动画
  // 切换显示label（placeholder和label切换，因为label为自定义的placeholder元素，在input中输入数据时不会自动隐藏文字，替换成input的placeholder会自动隐藏）
  switchLabel: boolean;
  apps: IAppStartMenu[];
}

function StartMenu({ show = false }: { show: boolean }) {
  const { systemStore, systemSettingStore, universalSettingStore } = useStore();
  const { isMasterSetupWallPager, wallPaper } = universalSettingStore;
  const { wallPaper: systemWallPaper } = systemSettingStore;
  // state
  const [state, setState] = useImmer<IStartMenuState>({
    searchInputBlur: false,
    keyword: '',
    activeClassName: '',
    switchLabel: false,
    apps: [],
  });

  // 关键字变化，查询app
  useUpdateEffect(() => {
    run(state.keyword);
  }, [state.keyword]);

  // 隐藏开始菜单时，清空筛选条件
  useUpdateEffect(() => {
    if (!systemStore.startMenuShow) {
      setState((state) => {
        state.keyword = '';
      });
    }
  }, [systemStore.startMenuShow]);

  useMount(() => {
    setState((state) => {
      state.apps = systemStore.filterStartMenu(
        state.keyword,
        Boolean(universalSettingStore.showName),
      );
    });
  });

  useUpdateEffect(() => {
    setState((state) => {
      state.apps = systemStore.filterStartMenu(
        state.keyword,
        Boolean(universalSettingStore.showName),
      );
    });
  }, [systemStore.startMenuObj]);

  // 处理防抖，输入关键字筛选app
  const { run } = useDebounceFn(
    (value: string) => {
      const apps = systemStore.filterStartMenu(value, Boolean(universalSettingStore.showName));
      setState((state) => {
        state.apps = apps;
      });
      if (value !== '') {
        DaqSoftTrack.track({
          firstEventType: '开始菜单',
          secondEventType: '搜索应用',
          thirdEventType: value,
          status: 1,
        });
      }
    },
    { wait: 500 },
  );

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setState((state) => {
      state.keyword = value;
    });
  };

  // 失焦定时器
  let blurTimer = 0;
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setState((state) => {
      state.keyword = value;
      if (!value) {
        state.activeClassName = '';
        // 失焦且没有输入值，则取消label交换
        state.switchLabel = false;
      } else {
        // 失焦且有输入值，则保持label交换
        state.switchLabel = true;
      }

      state.searchInputBlur = true;

      /**
       * 处理点击非开始菜单导致的失焦时如果不清除searchInputBlur标记，
       * 会导致点击两次空白处才能关闭开始菜单
       **/
      clearTimeout(blurTimer);
      blurTimer = window.setTimeout(() => {
        setState((state) => {
          state.searchInputBlur = false;
        });
      }, 300);
    });
  };
  const handleFocus = () => {
    setState((state) => {
      state.activeClassName = style.active;
    });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      setState((state) => {
        // 输入框有值时，切换label
        state.switchLabel = true;
      });
    } else {
      setState((state) => {
        // 输入框没值时，如果处于激活状态则切换label，否则不切换
        state.switchLabel = !!state.activeClassName;
      });
    }

    setState((state) => {
      state.keyword = value;
    });
  };

  // 处理点击空白处隐藏开始菜单
  const handleClose = (e: React.MouseEvent) => {
    // 如果搜索框失焦时，直接调用了关闭开始菜单，则清除定时器
    clearTimeout(blurTimer);

    // 如果点击空白处导致搜索框失焦则不处理关闭流程，否则则关闭开始菜单
    if (state.searchInputBlur) {
      setState((state) => {
        state.searchInputBlur = false;
      });
    } else if (
      e.target !== window.document.getElementById('searchInput') &&
      !['path', 'button', 'span'].includes((e.target as HTMLElement).tagName.toLowerCase())
    ) {
      // 如果点击的对象不是搜索框、不是搜索框的清除按钮、不是翻页按钮，则隐藏开始菜单
      systemStore.hideStartMenu();
      // 隐藏任务栏
      if (universalSettingStore.autoHide && universalSettingStore.moveShow) {
        universalSettingStore.setMoveShow(false);
      }
    }
  };

  return (
    <BackgroundLayer
      className={style.startMenu}
      show={show}
      onClick={handleClose}
      wallPaper={isMasterSetupWallPager === 0 ? wallPaper : systemWallPaper}
    >
      <div className={style.header}>
        <h2 className={style.title}>
          {!systemSettingStore.systemName
            ? '中科大旗文旅产业生态操作系统'
            : systemSettingStore.systemName}
        </h2>
        <div
          aria-hidden={true}
          className={classNames(style.searchBox, state.activeClassName)}
          onClick={(e) => {
            // 防止点击删除按钮时，关闭开始菜单
            e.stopPropagation();
          }}
        >
          <label
            className={style.label}
            htmlFor="searchInput"
          >
            <DaqIcon icon="&#xe875;"></DaqIcon>
            {state.switchLabel ? '' : '搜索'}
          </label>
          <Input
            id="searchInput"
            allowClear
            className={style.searchInput}
            placeholder={state.switchLabel ? '搜索' : ''}
            maxLength={15}
            autoComplete="off"
            value={state.keyword}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onPressEnter={handleEnter}
          ></Input>
        </div>
      </div>
      <EnableApps
        apps={state.apps}
        filtered={Boolean(state.keyword)}
      ></EnableApps>
    </BackgroundLayer>
  );
}

export default observer(StartMenu);
