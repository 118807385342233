/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-10-13 10:07:10
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-28 19:29:43
 * @Description: 描述
 */
import './image-captcha.less';
import { useImmer } from 'use-immer';
import classNames from 'classnames';
import { useMemoizedFn } from 'ahooks';
import { Modal } from 'antd';
import SliderCaptcha from 'rc-slider-captcha';
import api from '@api';

interface IImageCaptchaProps {
  show: boolean;
  onSuccess(): void;
  onClose(): void;
}

const ImageCaptcha = function ({ onSuccess, onClose, show }: IImageCaptchaProps) {
  const [state, setState] = useImmer({
    backgroundImage: '',
    sliderImage: '',
    randomCode: '',
  });

  const verifyCaptcha = useMemoizedFn(async (datas: { x: number }) => {
    try {
      const { data } = await api.verifyImageCaptcha(state.randomCode, datas.x / 370);
      if (data) {
        onSuccess();
        return Promise.resolve();
      }
    } catch (e) {
      console.error(e);
    }
    return Promise.reject();
  });

  return (
    <Modal
      title="请完成安全验证"
      destroyOnClose
      maskClosable={false}
      className="image-captcha-layer"
      footer={null}
      width={430}
      open={show}
      onCancel={onClose}
    >
      <div className={classNames('image-captcha-wrapper')}>
        <SliderCaptcha
          bgSize={{ width: 370, height: 226 }}
          puzzleSize={{ width: 65 }}
          limitErrorCount={3}
          request={async () => {
            const { data } = await api.getImageCaptcha();
            setState((_) => {
              _.randomCode = data.randomCode;
            });
            return {
              bgUrl: data.backgroundImage,
              puzzleUrl: data.sliderImage,
            };
          }}
          onVerify={verifyCaptcha}
        />
      </div>
    </Modal>
  );
};

export default ImageCaptcha;
