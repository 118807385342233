// import style from './index.module.less';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';

import {
  // GridSimpleComponent,
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  // LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from 'echarts/renderers';
import WeatherBox from '@views/weather/components/weather-box';
import { IRain } from '@interfaces/i-weather';
import React, { useState } from 'react';
import { useMount, useUpdateEffect } from 'ahooks';
import { TAny } from '@interfaces/i-common';
import DaqEmpty from '@components/daq-empty';
import { EChartsInstance } from 'echarts-for-react';

const Rainfall2h = ({ description, infos }: IRain) => {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    LineChart,
    CanvasRenderer,
  ]);

  const [source, setSource] = useState<[string, number][]>([]);
  // 未来2小时是否有降水量
  const [haveRain, setHaveRain] = useState<boolean>(true);
  // 展示横坐标的索引
  const specialIndexes = [0, 11, 23];

  const echartRef = React.useRef<EChartsInstance>(null);

  useMount(() => {
    const echartInstance = echartRef?.current?.getEchartsInstance();
    if (echartInstance) {
      echartInstance.clear();
    }

    setSource(
      infos.map((item, index) => {
        return [
          index === 0 ? '现在' : index === 11 ? '1小时' : index === 23 ? '2小时' : item.time,
          item.value,
        ];
      }),
    );
    setHaveRain(infos.some((item) => item.value));
  });

  useUpdateEffect(() => {
    const echartInstance = echartRef?.current?.getEchartsInstance();
    if (echartInstance) {
      echartInstance.clear();
    }

    setSource(
      infos.map((item, index) => {
        return [
          index === 0 ? '现在' : index === 11 ? '1小时' : index === 23 ? '2小时' : item.time,
          item.value,
        ];
      }),
    );
    setHaveRain(infos.some((item) => item.value));
  }, [infos]);

  return (
    <WeatherBox
      title="2小时降水量"
      info={infos.length ? (haveRain ? description : '未来两小时无降水') : ''}
    >
      {infos.length ? (
        <ReactEChartsCore
          ref={echartRef}
          echarts={echarts}
          style={{
            width: '290px',
            height: '150px',
          }}
          option={{
            tooltip: {
              show: true,
              trigger: 'item',
              position: function (
                point: Array<number>,
                prams: Array<TAny>,
                dom: HTMLDivElement,
                recct: { x: number; y: number; width: number; height: number },
                size: { contentSize: number[] },
              ) {
                // point鼠标位置，size.contentSize为tooltip的大小
                return [point[0] - size.contentSize[0] / 2, point[1] - size.contentSize[1] - 8];
              },
              backgroundColor: 'rgba(0,0,0, .4)',
              borderColor: 'rgb(255, 255, 255, .5)',
              padding: 4,
              textStyle: {
                color: '#fff',
                fontSize: 12,
              },
              formatter: function (params: TAny) {
                return `${params.value[1]}mm`;
              },
            },
            dataset: {
              // 提供一份数据。
              source: source,
            },
            grid: {
              top: 15,
              left: 40,
              bottom: 20,
              right: 15,
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              axisLabel: {
                show: true,
                interval: 0,
                color: 'rgba(255, 255, 255, 0.502)',
                align: 'center',
                hideOverlap: false,
                formatter: function (value: string, index: number) {
                  return specialIndexes.includes(index) ? value : '';
                },
              },
              axisLine: {
                lineStyle: {
                  color: 'rgba(255, 255, 255, 0.2)',
                },
              },
              axisTick: {
                show: false,
              },
            },
            yAxis: {
              axisLabel: {
                color: 'rgba(255, 255, 255, 0.502)',
              },
              splitLine: {
                lineStyle: {
                  color: 'rgba(255, 255, 255, 0.2)',
                },
              },
            },
            series: [
              {
                type: 'line',
                symbol: haveRain ? 'emptyCircle' : 'none',
                smooth: true,
                itemStyle: {
                  color: haveRain ? 'rgb(36, 170, 255)' : 'rgba(0,0,0,0)',
                },
                lineStyle: {
                  color: haveRain ? 'rgb(36, 170, 255)' : 'rgba(0,0,0,0)',
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: 'rgba(36, 170, 255, 0.3)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(36, 170, 255, 0.3)',
                    },
                  ]),
                },
              },
            ],
          }}
        ></ReactEChartsCore>
      ) : (
        <DaqEmpty></DaqEmpty>
      )}
    </WeatherBox>
  );
};

export default Rainfall2h;
