/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-06 15:43:43
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-09 14:02:39
 * @Description: 角色
 */
import React from 'react';
import './list-content.less';
import roleDark from '@assets/images/role-dark.png';
import roleLight from '@assets/images/role-light.png';
import { observer } from 'mobx-react-lite';
import { useStore } from '@stores';
const ListContent: React.FC = (): JSX.Element => {
  const { universalSettingStore } = useStore();
  return (
    <div className="list-content">
      <img
        src={universalSettingStore.model === 1 ? roleDark : roleLight}
        alt=""
      />
    </div>
  );
};

export default observer(ListContent);
