import DaqImgUpload from '@components/daq-img-upload';
import regular from '@config/regular';
import { addExternalApi, delExternalApi, modifyExternalApi } from '@api/api-externalAppInfo';
import { IExternalAddParams, IExternalModifyParams } from '@interfaces/i-api-request';
import { useMount } from 'ahooks';
import { Button, Form, Input, message, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { useStore } from '@stores';
import { IOpenListRes } from '@interfaces/i-api-response';
import api from '@api';
import DaqSoftTrack from '@utils/daqsoft-track';

interface IProps {
  detail: IOpenListRes | null;
  onClick?: () => void;
  onBack?: (id: string | undefined) => void;
  className?: string;
}

const ModifyModal: React.FC<IProps> = ({ className, onBack, detail }) => {
  const { systemStore, appWindowStore } = useStore();
  const [isModify, setIsModify] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values: IExternalAddParams) => {
    if (isLoading) {
      return;
    }
    if (values.loginUrl.indexOf('daqos') !== -1) {
      message.error('地址无效，请重新添加！');
      return;
    }
    if (detail === null) {
      addExternal(values);
      return;
    }
    modifyExternal(values);
  };

  const onReset = async (id = '') => {
    await onBack?.(id);
    await form.resetFields();
  };

  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };

  const successTrack = (secondEventType: string, appId: string, relationId: string) => {
    DaqSoftTrack.track({
      firstEventType: '应用管理',
      secondEventType,
      appId,
      relationId,
      status: 1,
    });
  };
  const errorTrack = (secondEventType: string, failReason: string) => {
    DaqSoftTrack.track({
      firstEventType: '应用管理',
      secondEventType,
      failReason,
      status: 0,
    });
  };
  const addExternal = async (params: IExternalAddParams) => {
    setIsLoading(true);
    try {
      const { data } = await addExternalApi(params);
      message.success('添加成功');
      systemStore.changeStartMenuApp(data);
      successTrack('新增', data.appId, data.relationId);
      onReset(data?.appUnionId);
    } catch (error) {
      console.error(error);
      errorTrack('新增', String(error));
    }
    await setIsLoading(false);
  };
  // 修改
  const modifyExternal = async (params: IExternalAddParams) => {
    setIsLoading(true);
    try {
      const modifyParam = {
        ...detail,
        ...params,
      } as IExternalModifyParams;
      const { data } = await modifyExternalApi(modifyParam);
      // 清空选择的多开app
      systemStore.setMultipleApp();
      systemStore.changeStartMenuApp(data);
      appWindowStore.changeDockAndTab(data);
      message.success('修改成功');
      successTrack('修改', data.appId, data.relationId);
      await onReset(detail?.id);
    } catch (error) {
      console.error(error);
      errorTrack('修改', String(error));
    }
    await setIsLoading(false);
  };

  // 删除
  const delExternal = async (params: string) => {
    setIsLoading(true);
    try {
      const { data } = await delExternalApi(params);
      await message.success('删除成功！');
      successTrack('删除', data.appId, data.relationId);
      systemStore.changeStartMenuApp(data, 'delete');
      // 删除应用需要调用一次开始菜单保存数据（因为可能存在处理组的情况）
      api.startMenu.saveStartMenuApps(systemStore.startMenuObj);

      appWindowStore.changeDockAndTab(data, 'delete');
      await onReset(data?.appUnionId);
    } catch (error) {
      console.error(error);
      errorTrack('删除', String(error));
    }
    await setIsLoading(false);
  };

  useMount(() => {
    if (detail === null) {
      return;
    }
    setIsModify(false);
    form.setFieldsValue({ ...detail });
  });

  return (
    <>
      <div className={`add-modal-body ${className}`}>
        <Form
          {...layout}
          form={form}
          name="form1"
          onFinish={onFinish}
          colon={false}
        >
          <Form.Item
            name="name"
            label="应用名称"
            rules={[{ required: isModify }]}
          >
            {isModify ? (
              <Input
                maxLength={15}
                allowClear
                showCount
              />
            ) : (
              <span className="ant-form-text">{detail?.name}</span>
            )}
          </Form.Item>
          <Form.Item
            name="aliasName"
            label="应用别称"
            rules={[{ required: isModify }]}
          >
            {isModify ? (
              <Input
                maxLength={30}
                allowClear
                showCount
              />
            ) : (
              <span className="ant-form-text text-long">{detail?.aliasName}</span>
            )}
          </Form.Item>
          <Form.Item
            name="logoUrl"
            label="应用LOGO"
            rules={[{ required: isModify, message: '请上传应用LOGO' }]}
          >
            {isModify ? (
              <>
                <DaqImgUpload
                  description="应用LOGO"
                  heightLimit={88}
                  widthLimit={88}
                  initialValues={detail?.logoUrl}
                  className="ant-upload-common ant-upload-logo"
                  onChange={(value) => {
                    form.setFieldsValue({
                      logoUrl: value,
                    });
                  }}
                />
                <span className="ant-form-tip">88*88/jpg/png/jpeg，限1MB内</span>
              </>
            ) : (
              <img
                className="ant-form-img"
                src={detail?.logoUrl || '暂无'}
                alt="logo"
              />
            )}
          </Form.Item>
          <Form.Item
            name="loginUrl"
            label="访问地址"
            rules={[
              { required: isModify },
              {
                pattern: regular.url.pattern,
                message: regular.url.message,
              },
            ]}
          >
            {isModify ? (
              <Input
                maxLength={255}
                allowClear
                showCount
              />
            ) : (
              <span className="ant-form-text text-long">{detail?.loginUrl}</span>
            )}
          </Form.Item>
          <Form.Item label=" ">
            <span className="ant-form-tip">
              注：添加应用后，需前往员工管理-&gt;员工修改-&gt;应用管理-&gt;添加应用中勾选后该应用，员工才能在开始菜单中查看、访问应用
            </span>
          </Form.Item>
        </Form>
      </div>
      <div className={`add-modal-footer ${className}`}>
        {isModify ? (
          <Form
            form={form}
            name="form2"
            onFinish={onFinish}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              保存
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => {
                onReset();
              }}
            >
              取消
            </Button>
          </Form>
        ) : (
          <>
            <Button
              type="primary"
              disabled={isLoading}
              onClick={() => {
                setIsModify(true);
              }}
            >
              修改
            </Button>
            <Popconfirm
              title="您确定要删除此应用吗？"
              okText="确定"
              cancelText="取消"
              onConfirm={() => {
                delExternal?.(detail?.id ?? '');
              }}
            >
              <Button
                htmlType="button"
                loading={isLoading}
              >
                删除
              </Button>
            </Popconfirm>
          </>
        )}
      </div>
    </>
  );
};

export default ModifyModal;
