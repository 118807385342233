import React, { useState } from 'react';
import { IDataItem } from '@interfaces/i-common';
import AppSideBar from '@components/app-side-bar';
import AppMenu from '@components/app-menu';
import UserInfo from './components/user-info';
import Security from './components/security';
import UnDevelop from '@components/undevelop';
import style from './index.module.less';

const UserCenter: React.FC = (): JSX.Element => {
  const [activeId, setActiveId] = useState('security');
  const menus: IDataItem[] = [
    // {
    //   id: 'order',
    //   label: '订单',
    //   icon: '&#xe6c2;',
    //   color: '#11c566',
    //   permission: '6',
    // },
    // {
    //   id: 'work-order',
    //   label: '工单',
    //   icon: '&#xeb95;',
    //   color: '#2d8bff',
    //   permission: '7',
    // },
    {
      id: 'security',
      label: '安全',
      icon: '&#xeb94;',
      color: '#ff5151',
    },
  ];

  const handleClick = (menu: IDataItem) => {
    setActiveId(menu.id);
  };

  return (
    <>
      <AppSideBar>
        <UserInfo></UserInfo>
        <AppMenu
          menus={menus}
          activeId={activeId}
          onClick={handleClick}
        ></AppMenu>
      </AppSideBar>
      <div className={style.userCenterContent}>
        {activeId === 'security' ? <Security /> : <UnDevelop />}
      </div>
    </>
  );
};

export default UserCenter;
