/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-06 14:05:19
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-12-07 17:54:43
 * @Description: 角色列表
 */
// import { delRoleApi, enableOrDisableApi, getDetailApi, getListPagingApi } from '@api/api-role';
// import useDebounceUpdateEffect from '@hooks/use-debounce-update-effect';
// import { IRoleListPagingParams } from '@interfaces/i-api-request';
// import { IRoleListPagingRes } from '@interfaces/i-api-response';
// import { useDebounceFn, useMemoizedFn, useMount } from 'ahooks';
// import { message } from 'antd';
// import moment from 'moment';
import React from 'react';
// import { useImmer } from 'use-immer';
// import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import ListContent from './components/list-content';
// import ListDetail from './components/list-detail';
// import ListFilter from './components/list-filter';
// import ListModify from './components/list-modify';
import './index.less';

const RoleList: React.FC = (): JSX.Element => {
  // const RoleList: React.FC<IProps> = ({ event$ }): JSX.Element => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [add, setAdd] = useState(false);
  // const [isDetail, setIsDetail] = useState(false);
  // const [detail, setDetail] = useState<IRoleListPagingRes | null>(null);
  // const [dataList, setDataList] = useState<IRoleListPagingRes[]>([]);
  // const [state, setState] = useImmer<Partial<IRoleListPagingParams>>({
  //   startDateTime: '',
  //   endDateTime: '',
  //   name: '',
  //   state: '',
  //   pageNo: 1,
  //   pageSize: 10,
  //   pageTotal: 1,
  //   recordCount: 0,
  // });
  // event$.useSubscription((value: unknown) => {
  //   if (value !== 'role') {
  //     return;
  //   }
  //   setAdd(false);
  //   setIsDetail(false);
  // });
  // 获取角色列表数据
  // const getListPaging = useMemoizedFn(async (params: Partial<IRoleListPagingParams>) => {
  //   try {
  //     const { data, page } = await getListPagingApi({
  //       ...params,
  //       pageSize: state.pageSize,
  //     });
  //     const arr = data ?? [];
  //     let List: IRoleListPagingRes[] = [];
  //     if (params.pageNo !== 1) {
  //       List = [...dataList];
  //     }

  //     setDataList([...List, ...arr]);
  //     setState((_) => {
  //       _.pageNo = page?.pageNo;
  //       _.pageTotal = page?.pageTotal;
  //       _.recordCount = page?.recordCount;
  //     });
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // });

  // // 获取角色详情
  // const getDetail = async (params: string, type: string) => {
  //   try {
  //     const { data } = await getDetailApi(params);
  //     setDetail(data);
  //     if (type === 'revise') {
  //       setAdd(true);
  //       setIsDetail(false);
  //     } else {
  //       setAdd(false);
  //       setIsDetail(true);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  // // 删除
  // const delRole = async (params: string, type = 'content') => {
  //   try {
  //     await delRoleApi(params);
  //     await message.success('删除成功！');
  //     setDataList([]);
  //     await getListPaging({
  //       ...state,
  //       pageNo: 1,
  //     });
  //     if (type === 'detail') {
  //       setIsDetail(false);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const enableOrDisable = async (id: string, state: number) => {
  //   try {
  //     await enableOrDisableApi({
  //       id,
  //       state,
  //     });
  //     message.success(`${state === 0 ? '启用' : '停用'}成功！`);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // const { run } = useDebounceFn((value) => {
  //   const startDateTime = value?.time ? moment(value?.time?.[0]).format('YYYY-MM-DD') : '';
  //   const endDateTime = value?.time ? moment(value?.time?.[1]).format('YYYY-MM-DD') : '';
  //   setState((_) => {
  //     _.startDateTime = startDateTime;
  //     _.endDateTime = endDateTime;
  //     _.name = value.name;
  //     _.state = value.state;
  //   });
  //   setIsLoading(true);

  //   const params = { ...value };
  //   delete params.time;
  //   setDataList([]);
  //   getListPaging({
  //     ...params,
  //     startDateTime,
  //     endDateTime,
  //     pageNo: 1,
  //   });
  // });

  // useMount(() => {
  //   getListPaging({ pageNo: 1 });
  // });

  // useDebounceUpdateEffect(() => {
  //   if (add || isDetail) {
  //     return;
  //   }
  //   setDetail(null);
  // }, [isDetail, add]);

  return (
    <div className="role-list">
      <ListContent />
      {/* {add && (
        <ListModify
          className={`add-${add ? 'show' : 'hide'}`}
          detail={detail}
          onBack={async (info) => {
            setAdd(false);
            if (info.type === 'close') {
              await getListPaging({
                ...state,
                pageNo: 1,
              });
            }
          }}
        />
      )}
      {isDetail && (
        <ListDetail
          detail={detail}
          className={`add-${isDetail ? 'show' : 'hide'}`}
          onBack={() => {
            setIsDetail(false);
          }}
          onRemove={(id: string) => {
            delRole(id, 'detail');
          }}
          onRevise={(id: string) => {
            getDetail(id, 'revise');
          }}
        />
      )}
      <div className={`list-box box-${!(add || isDetail) ? 'show' : 'hide'}`}>
        <ListFilter
          isLoading={isLoading}
          onFinish={run}
          onAdd={() => {
            setAdd(true);
          }}
        />
         <ListContent
          isLoading={isLoading}
          dataList={dataList}
          pageNo={state?.pageNo ?? 1}
          pageTotal={state?.pageTotal ?? 1}
          recordCount={state?.recordCount ?? 1}
          onNext={(pageNo) => {
            getListPaging({
              ...state,
              pageNo,
            });
          }}
          onRevise={(id: string) => {
            getDetail(id, 'revise');
          }}
          onDetail={(id: string) => {
            getDetail(id, 'detail');
          }}
          onRemove={(id: string) => {
            delRole(id);
          }}
          onState={enableOrDisable}
        />
      </div> */}
    </div>
  );
};

export default RoleList;
