/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-09-23 09:12:20
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-09-25 17:12:19
 * @Description: 拖拽到组件删除
 */
import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { AlignType } from '@interfaces/i-universal-setting';
import { useUpdateEffect } from 'ahooks';
import React from 'react';

interface IProps {
  id: UniqueIdentifier;
  position: AlignType;
  _dragElement: HTMLElement | null;
}
const DockTrash: React.FC<IProps> = ({ id, position, _dragElement }): JSX.Element => {
  const { setNodeRef, isOver } = useDroppable({
    id,
  });

  useUpdateEffect(() => {
    if (isOver) {
      _dragElement?.classList.add('dragOver');
    } else {
      _dragElement?.classList.remove('dragOver');
    }
  }, [isOver]);

  return (
    <div
      ref={setNodeRef}
      className={`dockTrash ${position}`}
    ></div>
  );
};

export default DockTrash;
