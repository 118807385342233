/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-07-07 10:15:25
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-08-01 10:17:17
 * @Description: 描述
 */
import { IDataItem } from '@interfaces/i-common';

// 外网
export const EMenus: IDataItem[] = [
  {
    id: 'all',
    label: '全部',
    icon: '&#xeb85;',
    color: '#6cc4f6',
  },
  {
    id: 'serving',
    label: '服务中',
    icon: '&#xeb99;',
    color: '#00bac1',
  },
  {
    id: 'expired',
    label: '已过期',
    icon: '&#xeb86;',
    color: '#ffb200',
  },
];
// 内网
export const IMenus: IDataItem[] = [
  {
    id: 'all',
    label: '全部',
    icon: '&#xeb85;',
    color: '#6cc4f6',
  },
  {
    id: 'serving',
    label: '云服务',
    icon: '&#xe65e;',
    color: '#00d281',
  },
  {
    id: 'expired',
    label: '本地服务',
    icon: '&#xe8a3;',
    color: '#ffb200',
  },
  {
    id: 'add',
    label: '添加应用',
    icon: '&#xe87c;',
    color: '#b080ff',
  },
];
