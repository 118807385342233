/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-06 14:38:01
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-10-10 20:23:58
 * @Description: 内容通用卡片容器
 */
import DaqIcon from '@components/daq-icon';
import { Tooltip } from 'antd';
import * as React from 'react';
import './index.less';

interface CardProps {
  children?: React.ReactNode;
  title: string;
  text?: string;
  className?: string;
}
const ContainerCard = React.forwardRef((props: CardProps, ref?: React.Ref<HTMLDivElement>) => {
  return (
    <div
      className={`container-card ${props?.className}`}
      ref={ref ?? null}
    >
      <div className="container-card-header">
        {props.title}
        {props.text && (
          <Tooltip
            title={props?.text}
            placement="right"
          >
            <span className="container-card-header-help">
              <DaqIcon icon="&#xe642;" />
            </span>
          </Tooltip>
        )}
      </div>
      <div className="container-card-body">{props.children}</div>
    </div>
  );
});
ContainerCard.displayName = 'ContainerCard';
export default ContainerCard;
