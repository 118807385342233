/*
 * @Author: 大旗云业务部-黄龙
 * @Date: 2022-06-30 11:52:31
 * @LastEditors: 大旗云业务部-黄龙
 * @LastEditTime: 2022-07-01 17:52:01
 * @Description: 描述
 */

import { useStore } from '@stores';
import { useEventListener } from 'ahooks';
import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';

interface IProps {
  id: string;
  className: string;
}
const HideTaskbar: React.FC<IProps> = ({ className, id }): JSX.Element => {
  const ref = useRef(null);
  let delayTimer = 0;

  const { universalSettingStore, appWindowStore } = useStore();
  const { autoHide, moveShow, setMoveShow } = universalSettingStore;
  const { showMenu } = appWindowStore;

  useEventListener(
    'mouseenter',
    () => {
      if (!autoHide || showMenu !== '' || moveShow) {
        return;
      }
      delayTimer = window.setTimeout(() => {
        setMoveShow(true);
      }, 300);
    },
    { target: ref },
  );
  useEventListener(
    'mouseleave',
    () => {
      clearDelayTimer();
    },
    { target: ref },
  );

  const clearDelayTimer = () => {
    if (delayTimer) {
      clearTimeout(delayTimer);
      delayTimer = 0;
    }
  };

  return (
    <div
      id={id}
      className={className}
      ref={ref}
    ></div>
  );
};

export default observer(HideTaskbar);
