import '@styles/weather.less';
import style from './index.module.less';
import WeatherBox from '@views/weather/components/weather-box';
import DaqIcon from '@components/daq-icon';
import classNames from 'classnames';
import { IWeatherOverView } from '@interfaces/i-weather';

interface IProps {
  openLocationConfig: () => void;
}
const Overview = (props: IWeatherOverView & IProps) => {
  return (
    <div className={style.overview}>
      <div className={style.info}>
        <div
          className={style.header}
          onClick={props.openLocationConfig}
        >
          <DaqIcon
            icon="&#xe660;"
            size="12px"
          ></DaqIcon>
          <span className={style.title}>{props.name || '-'}</span>
        </div>
        <div className={style.date}>{props.date || '-'}</div>
        <div className={style.temperature}>
          {props.temperature === 0 ? 0 : props.temperature || '-'}℃
        </div>
        <div className={style.weather}>
          {props.weatherIcon ? (
            <DaqIcon
              className={classNames('daq-weather-icon', style.icon)}
              icon={props.weatherIcon}
            ></DaqIcon>
          ) : null}
          <span>{props.weatherText || '-'}</span>
        </div>
        <div className={classNames(style.aqi, `aqi-background-color-level${props.aqi.level}`)}>
          <span>AQI</span>&ensp;<span>{props.aqi.text || '-'}</span>
        </div>
      </div>
      {props.description || props.infos.length ? (
        <WeatherBox className={style.realtimeWeather}>
          {props.description ? (
            <>
              <div>{props.description}</div>
              <div className={style.line}></div>
            </>
          ) : null}
          <ul className={style.weatherInfos}>
            {props.infos.map((info, index) => {
              return (
                <li
                  key={index}
                  className={style.weatherInfo}
                >
                  <p>{info.value}</p>
                  <p className={style.weatherName}>{info.name}</p>
                </li>
              );
            })}
          </ul>
        </WeatherBox>
      ) : null}
    </div>
  );
};

export default Overview;
